// nvm use 17 // Use Node Version 17
import { useState, useEffect } from "react";

// Components

// Images & Icons
import Download from "../images/tools-gallery/download.svg";
import LoaderImg from "../images/twitter/image-loader.svg";

function IPStages() {
  const bucket = `pixel`;
  const bucket_I = `collection`;
  const bucket_II = `illustration`;

  const [firebase, setFirebase] = useState("");
  const [firebaseI, setFirebaseI] = useState("");
  const [firebaseII, setFirebaseII] = useState("");

  const [isLoaded, setIsLoaded] = useState(false);
  const [ramdom, setRamdom] = useState(0);

  var nft_number = [
    4633, 4875, 4903, 5872, 3218, 3618, 6651, 4789, 9264, 4575, 6202, 8365, 903,
    6513, 6161, 1596, 5574, 509, 5090, 2299, 9025, 2142, 8588, 1486, 3754, 8436,
    3417, 7186, 6460, 1369, 1695, 1391, 5343, 7075, 9668, 2338, 1245, 1683,
    1643, 4575, 3844,
  ];

  const min = 1;
  const max = 10000;

  const [NFTnumber, setNFTnumber] = useState(
    nft_number[Math.floor(Math.random() * nft_number.length)]
  );

  const onChangeHandler = (event) => {
    setIsLoaded(false);
    setTimeout(() => {
      setIsLoaded(true);
    }, 3000);
    const limit = 5;
    const value = Math.max(
      min,
      Math.min(max, Number(event.target.value.slice(0, limit)))
    );
    setNFTnumber(value);
  };

  const handleFocus = (event) => {
    event.target.select();
  };

  useEffect(() => {
    async function getImagefromFirebase() {
      if (NFTnumber !== 0) {
        try {
          const response = await fetch(
            `https://firebasestorage.googleapis.com/v0/b/yaypegsnft.appspot.com/o/${bucket}%2F${NFTnumber}.png`
          );
          const responseJson = await response.json();
          setFirebase(responseJson);
        } catch (error) {
          console.error(error);
        }
      }
    }
    getImagefromFirebase();
    return () => {
      getImagefromFirebase = null;
    };
  }, [NFTnumber, bucket]);

  useEffect(() => {
    async function getImagefromFirebaseI() {
      if (NFTnumber !== 0) {
        try {
          const response = await fetch(
            `https://firebasestorage.googleapis.com/v0/b/yaypegsnft.appspot.com/o/${bucket_I}%2F${NFTnumber}.gif`
          );
          const responseJson = await response.json();
          setFirebaseI(responseJson);
        } catch (error) {
          console.error(error);
        }
      }
    }
    getImagefromFirebaseI();
    return () => {
      getImagefromFirebaseI = null;
    };
  }, [NFTnumber, bucket_I]);

  useEffect(() => {
    async function getImagefromFirebaseII() {
      if (NFTnumber !== 0) {
        try {
          const response = await fetch(
            `https://firebasestorage.googleapis.com/v0/b/yaypegsnft.appspot.com/o/${bucket_II}%2F${NFTnumber}.png`
          );
          const responseJson = await response.json();
          setFirebaseII(responseJson);
        } catch (error) {
          console.error(error);
        }
      }
    }
    getImagefromFirebaseII();
    return () => {
      getImagefromFirebaseII = null;
    };
  }, [NFTnumber, bucket_II]);

  function downloadImages() {
    if (NFTnumber !== 0) {
      const xhr = new XMLHttpRequest();
      xhr.open(
        "GET",
        `https://firebasestorage.googleapis.com/v0/b/yaypegsnft.appspot.com/o/${bucket}%2F${NFTnumber}.png?alt=media&firebase=${firebase.downloadTokens}`,
        true
      );
      xhr.responseType = "blob";

      xhr.onload = () => {
        if (xhr.status === 200) {
          const blob = new Blob([xhr.response], { type: "image/png" });
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${NFTnumber}.png`);
          document.body.appendChild(link);
          link.click();
        } else {
          console.error("Error downloading PNG:", xhr.statusText);
        }
      };
      xhr.onerror = () => {
        console.error("Error downloading PNG:", xhr.statusText);
      };
      xhr.send();

      const xhrI = new XMLHttpRequest();
      xhrI.open(
        "GET",
        `https://firebasestorage.googleapis.com/v0/b/yaypegsnft.appspot.com/o/${bucket_I}%2F${NFTnumber}.gif?alt=media&firebase=${firebase.downloadTokens}`,
        true
      );
      xhrI.responseType = "blob";

      xhrI.onload = () => {
        if (xhrI.status === 200) {
          const blob = new Blob([xhrI.response], { type: "image/png" });
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${NFTnumber}(I).gif`);
          document.body.appendChild(link);
          link.click();
        } else {
          console.error("Error downloading PNG sI:", xhrI.statusText);
        }
      };
      xhrI.onerror = () => {
        console.error("Error downloading PNG sI:", xhrI.statusText);
      };
      xhrI.send();

      const xhrII = new XMLHttpRequest();
      xhrII.open(
        "GET",
        `https://firebasestorage.googleapis.com/v0/b/yaypegsnft.appspot.com/o/${bucket_II}%2F${NFTnumber}.png?alt=media&firebase=${firebase.downloadTokens}`,
        true
      );
      xhrII.responseType = "blob";

      xhrII.onload = () => {
        if (xhrII.status === 200) {
          const blob = new Blob([xhrII.response], { type: "image/png" });
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${NFTnumber}(II).png`);
          document.body.appendChild(link);
          link.click();
        } else {
          console.error("Error downloading PNG sII:", xhrII.statusText);
        }
      };
      xhrII.onerror = () => {
        console.error("Error downloading PNG sII:", xhrII.statusText);
      };
      xhrII.send();
    }
  }

  return (
    <div className="">
      <div className="ip-w-home">
        <div className="col-12 p-1">
          <div className="pill-ip py-4 px-4">
            <div className="row">
              <div className="col m-1 p-0">
                <div className="text-center pb-3">OG</div>
                {!isLoaded && (
                  <div className="pill-ip-img-loading">
                    <img src={LoaderImg} className="loader-img" alt="step 1" />
                  </div>
                )}
                <img
                  src={`https://firebasestorage.googleapis.com/v0/b/yaypegsnft.appspot.com/o/${bucket}%2F${NFTnumber}.png?alt=media&firebase=${firebase.downloadTokens}`}
                  className="pill-ip-img"
                  onLoad={() => setIsLoaded(true)}
                  style={{ display: isLoaded ? "block" : "none" }}
                  alt="img"
                />
              </div>
              <div className="col m-1 p-0">
                <div className="text-center pb-3">Stage I</div>
                {!isLoaded && (
                  <div className="pill-ip-img-loading">
                    <img src={LoaderImg} className="loader-img" alt="step 1" />
                  </div>
                )}
                <img
                  src={`https://firebasestorage.googleapis.com/v0/b/yaypegsnft.appspot.com/o/${bucket_I}%2F${NFTnumber}.gif?alt=media&firebase=${firebaseI.downloadTokens}`}
                  className="pill-ip-img"
                  style={{ display: isLoaded ? "block" : "none" }}
                  onLoad={() => setIsLoaded(true)}
                  alt="img"
                />
                {!isLoaded && <div />}
              </div>
              <div className="col m-1 p-0">
                <div className="text-center pb-3">Stage II</div>
                {!isLoaded && (
                  <div className="pill-ip-img-loading">
                    <img src={LoaderImg} className="loader-img" alt="step 1" />
                  </div>
                )}
                <img
                  src={`https://firebasestorage.googleapis.com/v0/b/yaypegsnft.appspot.com/o/${bucket_II}%2F${NFTnumber}.png?alt=media&firebase=${firebaseII.downloadTokens}`}
                  className="pill-ip-img"
                  style={{ display: isLoaded ? "block" : "none" }}
                  onLoad={() => setIsLoaded(true)}
                  alt="img"
                />
                {!isLoaded && <div />}
              </div>
            </div>
            <div className="pill-center-inline pt-3">
              <div className="ipcenter inline-block p-2 px-3">
                <div className="tweet-number-bg">#</div>
                <input
                  type="number"
                  name="number"
                  placeholder="0000"
                  maxLength={5}
                  onChange={onChangeHandler}
                  onFocus={handleFocus}
                  value={NFTnumber}
                  min="1"
                  max="10000"
                />
              </div>
              <img
                src={Download}
                onClick={() => downloadImages()}
                className="tweet-icon tweet-icon-sm pb-2"
                alt="download"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IPStages;
