import "bootstrap/dist/css/bootstrap.css";
import "./styles/styles.css";

import React from "react";
import Routes from "./Routes";

function App() {
  return (
    <div>
      <Routes />
    </div>
  );
}

export default App;
