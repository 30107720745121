import { useState } from "react";
import { NavLink } from "react-router-dom";


import Home from "../images/tabbar/home.svg";
import Stages from "../images/tabbar/stages.svg";
import IP from "../images/tabbar/IP.svg";
import Gallery from "../images/tabbar/gallery.svg";
import Library from "../images/tabbar/library.svg";

import Home_Active from "../images/tabbar/home-active.svg";
import Stages_Active from "../images/tabbar/stages-active.svg";
import IP_Active from "../images/tabbar/IP-active.svg";
import Gallery_Active from "../images/tabbar/gallery-active.svg";
import Library_Active from "../images/tabbar/library-active.svg";


export default function Menu() {

  const [iconHome, setIconHome] = useState(Home)
  const [iconStages, setIconStages] = useState(Stages)
  const [iconIP, setIconIP] = useState(IP)
  const [iconGallery, setIconGallery] = useState(Gallery)
  const [iconLibrary, setIconLibrary] = useState(Library)

  return (
    <div className="d-lg-none fixed-bottom">
      <div className="tabbar-nav">
        <div className="row px-3">
          <div className="col text-center">
            <NavLink
              to={`/`}
              style={{ color: iconHome }}
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? setIconHome(Home_Active) && `tabbar-text-active` : ""}>
              <img src={iconHome} className="tabbar-icon" alt="Home" />
              <div className="h7">Home</div>
            </NavLink>
          </div>

          <div className="col text-center">
            <NavLink
              to={`/about`}
              style={{ color: iconHome }}
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? setIconGallery(Gallery_Active) && `tabbar-text-active` : ""}>
              <img src={iconGallery} className="tabbar-icon" alt="Stage 2" />
              <div className="h7">About</div>
            </NavLink>
          </div>

          <div className="col text-center">
            <NavLink
              to={`/stages`}
              style={{ color: iconHome }}
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? setIconStages(Stages_Active) && `tabbar-text-active` : ""}>
              <img src={iconStages} className="tabbar-icon" alt="Stage 2" />
              <div className="h7">Stages</div>
            </NavLink>
          </div>

          <div className="col text-center">
            <NavLink
              to={`/ip`}
              style={{ color: iconHome }}
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? setIconIP(IP_Active) && `tabbar-text-active` : ""}>
              <img src={iconIP} className="tabbar-icon" alt="Stage 2" />
              <div className="h7">IP</div>
            </NavLink>
          </div>


          <div className="col text-center">
            <NavLink
              to={`/library`}
              style={{ color: iconHome }}
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? setIconLibrary(Library_Active) && `tabbar-text-active` : ""}>
              <img src={iconLibrary} className="tabbar-icon" alt="Library" />
              <div className="h7">Library</div>
            </NavLink>
          </div>

        </div>
      </div>
    </div>
  );
}
