// nvm use 17 // Use Node Version 17

// Components
import Header from "./Header";
import Footer from "./FooterSimple";
import IPStages from "./IPStages";

// Images & Icons
import Stages from "../images/home/stages.png";
import Blur from "../images/home/blur.png";
import Opensea from "../images/home/opensea.png";
import Sneakpeek from "../images/home/pfp-footer.png";

// Lottie

function HomeIIPage() {
  return (
    <div className="container-fluid pb-tabbar">
      <Header bgColor={false} />
      <div className="row pt-0 m-0">
        <div className="col-12 p-0">
          <div className="parent-center-2">
            {/* <img
              src={Stages}
              className="stages-img"
              alt="stages I : II : III"
            /> */}
            <div className="child-center-2">
              <IPStages />
              <div className="text-center pt-4">
                <a
                  className=""
                  href={`https://blur.io/collection/yaypegs`}
                  data-size="large"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="buy on blur"
                >
                  <div className="buy-blur">
                    Buy on <img src={Blur} className="blur-img" alt="blur" />
                  </div>
                </a>
              </div>
              <div className="text-center pt-3">
                <a
                  className=""
                  href={`https://opensea.io/collection/yaypegs`}
                  data-size="large"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="buy on opensea"
                >
                  <div className="buy-opensea">
                    Buy on &nbsp;
                    <img src={Opensea} className="opensea-img" alt="opensea" />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-none d-lg-block position-absolute bottom-0 start-50 translate-middle-x">
        <img src={Sneakpeek} className="yay-footer-img" alt="Sneakpeek" />
      </div>

      <Footer img={Sneakpeek} />
    </div>
  );
}

export default HomeIIPage;
