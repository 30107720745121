import { useState, useEffect } from "react";

// Components
import Header from "./Header";
import Footer from "./Footer";

// Images & Icons
import Future01 from "../images/icons/future-01-w.svg";
import Future02 from "../images/icons/future-02-w.svg";
import Future03 from "../images/icons/future-03-w.svg";
import Future04 from "../images/icons/future-04-w.svg";
import Future05 from "../images/icons/future-05-w.svg";

import Future01_h from "../images/icons/future-01-h.svg";
import Future02_h from "../images/icons/future-02-h.svg";
import Future03_h from "../images/icons/future-03-h.svg";
import Future04_h from "../images/icons/future-04-h.svg";
import Future05_h from "../images/icons/future-05-h.svg";

function FuturePage() {

    const [iconFuture_01, setIconFuture_01] = useState(Future01)
    const [iconFuture_02, setIconFuture_02] = useState(Future02)
    const [iconFuture_03, setIconFuture_03] = useState(Future03)
    const [iconFuture_04, setIconFuture_04] = useState(Future04)
    const [iconFuture_05, setIconFuture_05] = useState(Future05)

    useEffect(() => {
        window.scrollTo(0, 0, 'instant')
    }, [])

    return (
        <div className="container-fluid pt-header pb-tabbar">
            <Header />
            <div className="row pt-0 m-0 pb-5">
                <div className="col-12 text-center">
                    <div className="h-center">
                        {/* Mobile */}
                        <div className="d-lg-none pb-3">

                            <div className="p-grey px-3">
                                The future will be decentralized and we will unlock it together
                            </div>
                        </div>
                        <div className="pt-5 d-none d-lg-block">
                            <div className="py-3">
                                <h5 className="m-auto pt-3 pb-3" style={{ maxWidth: "440px" }}>The future will be decentralized and we will unlock it together</h5>
                            </div>
                        </div>
                        {/* Box 1 */}
                        <div
                            className={`p-3 inline-block`}
                        >
                            <div className="box"
                                onMouseEnter={() => setIconFuture_01(Future01_h)}
                                onMouseLeave={() => setIconFuture_01(Future01)}
                            >
                                <img src={iconFuture_01} className="future-icon" alt="Logo" />
                            </div>
                        </div>
                        {/* Box 2 */}
                        <div
                            className={`p-3 inline-block`}
                        >
                            <div className="box"
                                onMouseEnter={() => setIconFuture_02(Future02_h)}
                                onMouseLeave={() => setIconFuture_02(Future02)}
                            >
                                <img src={iconFuture_02} className="future-icon" alt="Logo" />
                            </div>
                        </div>
                        {/* Box 3 */}
                        <div
                            className={`p-3 inline-block`}
                        >
                            <div className="box"
                                onMouseEnter={() => setIconFuture_03(Future03_h)}
                                onMouseLeave={() => setIconFuture_03(Future03)}
                            >
                                <img src={iconFuture_03} className="future-icon" alt="Logo" />
                            </div>
                        </div>
                        {/* Box 4 */}
                        <div
                            className={`p-3 inline-block`}
                        >
                            <div className="box"
                                onMouseEnter={() => setIconFuture_04(Future04_h)}
                                onMouseLeave={() => setIconFuture_04(Future04)}
                            >
                                <img src={iconFuture_04} className="future-icon" alt="Logo" />
                            </div>
                        </div>
                        {/* Box 5 */}
                        <div
                            className={`p-3 inline-block`}
                        >
                            <div className="box"
                                onMouseEnter={() => setIconFuture_05(Future05_h)}
                                onMouseLeave={() => setIconFuture_05(Future05)}
                            >
                                <img src={iconFuture_05} className="future-icon" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fixed-bottom">
                <Footer />
            </div>
        </div>
    );
}

export default FuturePage;
