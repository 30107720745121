import { NavLink } from "react-router-dom";
import { ConnectButton } from "@rainbow-me/rainbowkit";

// Images & Icons
import Logo from "../images/brand/new-logo.svg";

export default function Header({ bgColor }) {
  return (
    <header>
      <div
        className={
          bgColor
            ? `fixed-top bg-color px-3 d-none d-lg-block`
            : `fixed-top px-3 d-none d-lg-block`
        }
      >
        <div className="row m-0">
          <div className="col-3 px-0 d-none d-lg-block">
            <div className="navbar navbar-start">
              <a aria-current="page" className="null" href="/">
                <img src={Logo} className="logo" alt="Logo" />
              </a>
            </div>
          </div>
          <div className="col-lg-9 col-12 px-0 navbar navbar-end">
            <div className="px-1 inline-block">
              <NavLink
                to={`/ip`}
                className={({ isActive }) =>
                  isActive
                    ? "bubble inline-block active"
                    : "bubble inline-block"
                }
              >
                IP
              </NavLink>
            </div>
            <div className="px-1 inline-block">
              <NavLink
                to={`/about`}
                className={({ isActive }) =>
                  isActive
                    ? "bubble inline-block active"
                    : "bubble inline-block"
                }
              >
                About
              </NavLink>
            </div>
            <div className="px-1 inline-block">
              <NavLink
                to={`/s1`}
                className={({ isActive }) =>
                  isActive
                    ? "bubble inline-block active"
                    : "bubble inline-block"
                }
              >
                S I
              </NavLink>
            </div>
            <div className="px-1 inline-block">
              <NavLink
                to={`/s2`}
                className={({ isActive }) =>
                  isActive
                    ? "bubble inline-block active"
                    : "bubble inline-block"
                }
              >
                S II
              </NavLink>
            </div>
            <div className="px-1 inline-block">
              <NavLink
                to={`/s3`}
                className={({ isActive }) =>
                  isActive
                    ? "bubble inline-block active"
                    : "bubble inline-block"
                }
              >
                S III
              </NavLink>
            </div>
            <div className="px-1 inline-block">
              <NavLink
                to={`/explorer`}
                className={({ isActive }) =>
                  isActive
                    ? "bubble inline-block active"
                    : "bubble inline-block"
                }
              >
                Explorer
              </NavLink>
            </div>
            <div className="px-1 inline-block">
              <ConnectButton accountStatus="address" showBalance={false} />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
