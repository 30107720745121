// Custom

const companios =
{
    frog: [
        {
            "number": 342,
            "image": require("../../images/art/companions/frog/342.png"),
        },
        {
            "number": 374,
            "image": require("../../images/art/companions/frog/374.png"),
        },
        {
            "number": 763,
            "image": require("../../images/art/companions/frog/763.png"),
        },
        {
            "number": 1596,
            "image": require("../../images/art/companions/frog/1596.png"),
        },
        {
            "number": 2448,
            "image": require("../../images/art/companions/frog/2448.png"),
        },
        {
            "number": 2819,
            "image": require("../../images/art/companions/frog/2819.png"),
        },
        {
            "number": 5081,
            "image": require("../../images/art/companions/frog/5081.png"),
        },
        {
            "number": 5678,
            "image": require("../../images/art/companions/frog/5678.png"),
        },
        {
            "number": 7158,
            "image": require("../../images/art/companions/frog/7158.png"),
        },
        {
            "number": 7258,
            "image": require("../../images/art/companions/frog/7258.png"),
        },
        {
            "number": 8118,
            "image": require("../../images/art/companions/frog/8118.png"),
        },
        {
            "number": 8877,
            "image": require("../../images/art/companions/frog/8877.png"),
        },
        {
            "number": 8932,
            "image": require("../../images/art/companions/frog/8932.png"),
        },
    ],
    rat: [
        {
            "number": 186,
            "image": require("../../images/art/companions/rat/186.png"),
        },
        {
            "number": 3203,
            "image": require("../../images/art/companions/rat/3203.png"),
        },
        {
            "number": 3207,
            "image": require("../../images/art/companions/rat/3207.png"),
        },
        {
            "number": 3273,
            "image": require("../../images/art/companions/rat/3273.png"),
        },
        {
            "number": 3329,
            "image": require("../../images/art/companions/rat/3329.png"),
        },
        {
            "number": 5003,
            "image": require("../../images/art/companions/rat/5003.png"),
        },
        {
            "number": 5274,
            "image": require("../../images/art/companions/rat/5274.png"),
        },
        {
            "number": 5508,
            "image": require("../../images/art/companions/rat/5508.png"),
        },
        {
            "number": 5574,
            "image": require("../../images/art/companions/rat/5574.png"),
        },
        {
            "number": 5658,
            "image": require("../../images/art/companions/rat/5658.png"),
        },
        {
            "number": 5679,
            "image": require("../../images/art/companions/rat/5679.png"),
        },
        {
            "number": 6868,
            "image": require("../../images/art/companions/rat/6868.png"),
        },
        {
            "number": 6971,
            "image": require("../../images/art/companions/rat/6971.png"),
        },
        {
            "number": 8304,
            "image": require("../../images/art/companions/rat/8304.png"),
        },
        {
            "number": 8479,
            "image": require("../../images/art/companions/rat/8479.png"),
        },
    ],
    monkey: [
        {
            "number": 1615,
            "image": require("../../images/art/companions/monkey/1615.png"),
        },
        {
            "number": 1990,
            "image": require("../../images/art/companions/monkey/1990.png"),
        },
        {
            "number": 2142,
            "image": require("../../images/art/companions/monkey/2142.png"),
        },
        {
            "number": 2146,
            "image": require("../../images/art/companions/monkey/2146.png"),
        },
        {
            "number": 4789,
            "image": require("../../images/art/companions/monkey/4789.png"),
        },
        {
            "number": 5033,
            "image": require("../../images/art/companions/monkey/5033.png"),
        },
        {
            "number": 5150,
            "image": require("../../images/art/companions/monkey/5150.png"),
        },
        {
            "number": 5343,
            "image": require("../../images/art/companions/monkey/5343.png"),
        },
        {
            "number": 5637,
            "image": require("../../images/art/companions/monkey/5637.png"),
        },
        {
            "number": 5815,
            "image": require("../../images/art/companions/monkey/5815.png"),
        },
        {
            "number": 6237,
            "image": require("../../images/art/companions/monkey/6237.png"),
        },
        {
            "number": 6781,
            "image": require("../../images/art/companions/monkey/6781.png"),
        },
        {
            "number": 6856,
            "image": require("../../images/art/companions/monkey/6856.png"),
        },
        {
            "number": 6928,
            "image": require("../../images/art/companions/monkey/6928.png"),
        },
        {
            "number": 7219,
            "image": require("../../images/art/companions/monkey/7219.png"),
        },
        {
            "number": 9907,
            "image": require("../../images/art/companions/monkey/9907.png"),
        },
    ],
    cat_dog: [
        {
            "number": 1923,
            "image": require("../../images/art/companions/cat_dog/1923.png"),
        },
        {
            "number": 1941,
            "image": require("../../images/art/companions/cat_dog/1941.png"),
        },
        {
            "number": 2052,
            "image": require("../../images/art/companions/cat_dog/2052.png"),
        },
        {
            "number": 2613,
            "image": require("../../images/art/companions/cat_dog/2613.png"),
        },
        {
            "number": 2648,
            "image": require("../../images/art/companions/cat_dog/2648.png"),
        },
        {
            "number": 2711,
            "image": require("../../images/art/companions/cat_dog/2711.png"),
        },
        {
            "number": 2719,
            "image": require("../../images/art/companions/cat_dog/2719.png"),
        },
        {
            "number": 3917,
            "image": require("../../images/art/companions/cat_dog/3917.png"),
        },
        {
            "number": 4174,
            "image": require("../../images/art/companions/cat_dog/4174.png"),
        },
        {
            "number": 4738,
            "image": require("../../images/art/companions/cat_dog/4738.png"),
        },
        {
            "number": 4904,
            "image": require("../../images/art/companions/cat_dog/4904.png"),
        },
        {
            "number": 4934,
            "image": require("../../images/art/companions/cat_dog/4934.png"),
        },
        {
            "number": 4984,
            "image": require("../../images/art/companions/cat_dog/4984.png"),
        },
        {
            "number": 5628,
            "image": require("../../images/art/companions/cat_dog/5628.png"),
        },
        {
            "number": 5700,
            "image": require("../../images/art/companions/cat_dog/5700.png"),
        },
        {
            "number": 5777,
            "image": require("../../images/art/companions/cat_dog/5777.png"),
        },
        {
            "number": 6771,
            "image": require("../../images/art/companions/cat_dog/6771.png"),
        },
        {
            "number": 7285,
            "image": require("../../images/art/companions/cat_dog/7285.png"),
        },
        {
            "number": 9894,
            "image": require("../../images/art/companions/cat_dog/9894.png"),
        },
        {
            "number": 9896,
            "image": require("../../images/art/companions/cat_dog/9896.png"),
        },
    ],
    two_dogs: [
        {
            "number": 63,
            "image": require("../../images/art/companions/two_dogs/63.png"),
        },
        {
            "number": 315,
            "image": require("../../images/art/companions/two_dogs/315.png"),
        },
        {
            "number": 992,
            "image": require("../../images/art/companions/two_dogs/992.png"),
        },
        {
            "number": 1106,
            "image": require("../../images/art/companions/two_dogs/1106.png"),
        },
        {
            "number": 1142,
            "image": require("../../images/art/companions/two_dogs/1142.png"),
        },
        {
            "number": 1158,
            "image": require("../../images/art/companions/two_dogs/1158.png"),
        },
        {
            "number": 1190,
            "image": require("../../images/art/companions/two_dogs/1190.png"),
        },
        {
            "number": 1269,
            "image": require("../../images/art/companions/two_dogs/1269.png"),
        },
        {
            "number": 1295,
            "image": require("../../images/art/companions/two_dogs/1295.png"),
        },
        {
            "number": 1354,
            "image": require("../../images/art/companions/two_dogs/1354.png"),
        },
        {
            "number": 1355,
            "image": require("../../images/art/companions/two_dogs/1355.png"),
        },
        {
            "number": 1357,
            "image": require("../../images/art/companions/two_dogs/1357.png"),
        },
        {
            "number": 1675,
            "image": require("../../images/art/companions/two_dogs/1675.png"),
        },
        {
            "number": 1741,
            "image": require("../../images/art/companions/two_dogs/1741.png"),
        },
        {
            "number": 1786,
            "image": require("../../images/art/companions/two_dogs/1786.png"),
        },
        {
            "number": 2591,
            "image": require("../../images/art/companions/two_dogs/2591.png"),
        },
        {
            "number": 3969,
            "image": require("../../images/art/companions/two_dogs/3969.png"),
        },
        {
            "number": 4811,
            "image": require("../../images/art/companions/two_dogs/4811.png"),
        },
        {
            "number": 5604,
            "image": require("../../images/art/companions/two_dogs/5604.png"),
        },
        {
            "number": 6286,
            "image": require("../../images/art/companions/two_dogs/6286.png"),
        },
        {
            "number": 6757,
            "image": require("../../images/art/companions/two_dogs/6757.png"),
        },
        {
            "number": 8485,
            "image": require("../../images/art/companions/two_dogs/8485.png"),
        },
        {
            "number": 9109,
            "image": require("../../images/art/companions/two_dogs/9109.png"),
        },
        {
            "number": 9808,
            "image": require("../../images/art/companions/two_dogs/9808.png"),
        },
    ],
    two_cats: [
        {
            "number": 1834,
            "image": require("../../images/art/companions/two_cats/1834.png"),
        },
        {
            "number": 1889,
            "image": require("../../images/art/companions/two_cats/1889.png"),
        },
        {
            "number": 1932,
            "image": require("../../images/art/companions/two_cats/1932.png"),
        },
        {
            "number": 2026,
            "image": require("../../images/art/companions/two_cats/2026.png"),
        },
        {
            "number": 2130,
            "image": require("../../images/art/companions/two_cats/2130.png"),
        },
        {
            "number": 2465,
            "image": require("../../images/art/companions/two_cats/2465.png"),
        },
        {
            "number": 2619,
            "image": require("../../images/art/companions/two_cats/2619.png"),
        },
        {
            "number": 2892,
            "image": require("../../images/art/companions/two_cats/2892.png"),
        },
        {
            "number": 4081,
            "image": require("../../images/art/companions/two_cats/4081.png"),
        },
        {
            "number": 4105,
            "image": require("../../images/art/companions/two_cats/4105.png"),
        },
        {
            "number": 4305,
            "image": require("../../images/art/companions/two_cats/4305.png"),
        },
        {
            "number": 4901,
            "image": require("../../images/art/companions/two_cats/4901.png"),
        },
        {
            "number": 5022,
            "image": require("../../images/art/companions/two_cats/5022.png"),
        },
        {
            "number": 5126,
            "image": require("../../images/art/companions/two_cats/5126.png"),
        },
        {
            "number": 5588,
            "image": require("../../images/art/companions/two_cats/5588.png"),
        },
        {
            "number": 5709,
            "image": require("../../images/art/companions/two_cats/5709.png"),
        },
        {
            "number": 6133,
            "image": require("../../images/art/companions/two_cats/6133.png"),
        },
        {
            "number": 6309,
            "image": require("../../images/art/companions/two_cats/6309.png"),
        },
        {
            "number": 6758,
            "image": require("../../images/art/companions/two_cats/6758.png"),
        },
        {
            "number": 6859,
            "image": require("../../images/art/companions/two_cats/6859.png"),
        },
        {
            "number": 6997,
            "image": require("../../images/art/companions/two_cats/6997.png"),
        },
        {
            "number": 7238,
            "image": require("../../images/art/companions/two_cats/7238.png"),
        },
        {
            "number": 7308,
            "image": require("../../images/art/companions/two_cats/7308.png"),
        },
        {
            "number": 7341,
            "image": require("../../images/art/companions/two_cats/7341.png"),
        },
        {
            "number": 9656,
            "image": require("../../images/art/companions/two_cats/9656.png"),
        },
    ],
    animalia: [
        {
            "number": 106,
            "image": require("../../images/art/companions/animalia/106.png"),
        },
        {
            "number": 336,
            "image": require("../../images/art/companions/animalia/336.png"),
        },
        {
            "number": 509,
            "image": require("../../images/art/companions/animalia/509.png"),
        },
        {
            "number": 593,
            "image": require("../../images/art/companions/animalia/593.png"),
        },
        {
            "number": 1029,
            "image": require("../../images/art/companions/animalia/1029.png"),
        },
        {
            "number": 1640,
            "image": require("../../images/art/companions/animalia/1640.png"),
        },
        {
            "number": 1707,
            "image": require("../../images/art/companions/animalia/1707.png"),
        },
        {
            "number": 2217,
            "image": require("../../images/art/companions/animalia/2217.png"),
        },
        {
            "number": 2717,
            "image": require("../../images/art/companions/animalia/2717.png"),
        },
        {
            "number": 3000,
            "image": require("../../images/art/companions/animalia/3000.png"),
        },
        {
            "number": 3292,
            "image": require("../../images/art/companions/animalia/3292.png"),
        },
        {
            "number": 3550,
            "image": require("../../images/art/companions/animalia/3550.png"),
        },
        {
            "number": 3717,
            "image": require("../../images/art/companions/animalia/3717.png"),
        },
        {
            "number": 4041,
            "image": require("../../images/art/companions/animalia/4041.png"),
        },
        {
            "number": 4535,
            "image": require("../../images/art/companions/animalia/4535.png"),
        },
        {
            "number": 4747,
            "image": require("../../images/art/companions/animalia/4747.png"),
        },
        {
            "number": 4766,
            "image": require("../../images/art/companions/animalia/4766.png"),
        },
        {
            "number": 5068,
            "image": require("../../images/art/companions/animalia/5068.png"),
        },
        {
            "number": 5331,
            "image": require("../../images/art/companions/animalia/5331.png"),
        },
        {
            "number": 5413,
            "image": require("../../images/art/companions/animalia/5413.png"),
        },
        {
            "number": 5499,
            "image": require("../../images/art/companions/animalia/5499.png"),
        },
        {
            "number": 5523,
            "image": require("../../images/art/companions/animalia/5523.png"),
        },
        {
            "number": 5656,
            "image": require("../../images/art/companions/animalia/5656.png"),
        },
        {
            "number": 5826,
            "image": require("../../images/art/companions/animalia/5826.png"),
        },
        {
            "number": 5983,
            "image": require("../../images/art/companions/animalia/5983.png"),
        },
        {
            "number": 6273,
            "image": require("../../images/art/companions/animalia/6273.png"),
        },
        {
            "number": 6429,
            "image": require("../../images/art/companions/animalia/6429.png"),
        },
        {
            "number": 6515,
            "image": require("../../images/art/companions/animalia/6515.png"),
        },
        {
            "number": 6551,
            "image": require("../../images/art/companions/animalia/6551.png"),
        },
        {
            "number": 6605,
            "image": require("../../images/art/companions/animalia/6605.png"),
        },
        {
            "number": 6702,
            "image": require("../../images/art/companions/animalia/6702.png"),
        },
        {
            "number": 6923,
            "image": require("../../images/art/companions/animalia/6923.png"),
        },
        {
            "number": 7151,
            "image": require("../../images/art/companions/animalia/7151.png"),
        },
        {
            "number": 7919,
            "image": require("../../images/art/companions/animalia/7919.png"),
        },
        {
            "number": 7926,
            "image": require("../../images/art/companions/animalia/7926.png"),
        },
        {
            "number": 8258,
            "image": require("../../images/art/companions/animalia/8258.png"),
        },
        {
            "number": 8622,
            "image": require("../../images/art/companions/animalia/8622.png"),
        },
        {
            "number": 8970,
            "image": require("../../images/art/companions/animalia/8970.png"),
        },
        {
            "number": 9096,
            "image": require("../../images/art/companions/animalia/9096.png"),
        },
        {
            "number": 9354,
            "image": require("../../images/art/companions/animalia/9354.png"),
        },
        {
            "number": 9499,
            "image": require("../../images/art/companions/animalia/9499.png"),
        },
        {
            "number": 9613,
            "image": require("../../images/art/companions/animalia/9613.png"),
        },
        {
            "number": 9620,
            "image": require("../../images/art/companions/animalia/9620.png"),
        },
        {
            "number": 9741,
            "image": require("../../images/art/companions/animalia/9741.png"),
        },
    ],
    hamster: [
        {
            "number": 114,
            "image": require("../../images/art/companions/hamster/114.png"),
        },
        {
            "number": 144,
            "image": require("../../images/art/companions/hamster/144.png"),
        },
        {
            "number": 947,
            "image": require("../../images/art/companions/hamster/947.png"),
        },
        {
            "number": 1365,
            "image": require("../../images/art/companions/hamster/1365.png"),
        },
        {
            "number": 1500,
            "image": require("../../images/art/companions/hamster/1500.png"),
        },
        {
            "number": 1858,
            "image": require("../../images/art/companions/hamster/1858.png"),
        },
        {
            "number": 2016,
            "image": require("../../images/art/companions/hamster/2016.png"),
        },
        {
            "number": 2466,
            "image": require("../../images/art/companions/hamster/2466.png"),
        },
        {
            "number": 2499,
            "image": require("../../images/art/companions/hamster/2499.png"),
        },
        {
            "number": 2533,
            "image": require("../../images/art/companions/hamster/2533.png"),
        },
        {
            "number": 3156,
            "image": require("../../images/art/companions/hamster/3156.png"),
        },
        {
            "number": 4456,
            "image": require("../../images/art/companions/hamster/4456.png"),
        },
        {
            "number": 5023,
            "image": require("../../images/art/companions/hamster/5023.png"),
        },
        {
            "number": 5181,
            "image": require("../../images/art/companions/hamster/5181.png"),
        },
        {
            "number": 5312,
            "image": require("../../images/art/companions/hamster/5312.png"),
        },
        {
            "number": 5597,
            "image": require("../../images/art/companions/hamster/5597.png"),
        },
        {
            "number": 5643,
            "image": require("../../images/art/companions/hamster/5643.png"),
        },
        {
            "number": 5838,
            "image": require("../../images/art/companions/hamster/5838.png"),
        },
        {
            "number": 5868,
            "image": require("../../images/art/companions/hamster/5868.png"),
        },
        {
            "number": 6613,
            "image": require("../../images/art/companions/hamster/6613.png"),
        },
        {
            "number": 6697,
            "image": require("../../images/art/companions/hamster/6697.png"),
        },
        {
            "number": 6749,
            "image": require("../../images/art/companions/hamster/6749.png"),
        },
        {
            "number": 6832,
            "image": require("../../images/art/companions/hamster/6832.png"),
        },
        {
            "number": 6861,
            "image": require("../../images/art/companions/hamster/6861.png"),
        },
        {
            "number": 7004,
            "image": require("../../images/art/companions/hamster/7004.png"),
        },
        {
            "number": 7075,
            "image": require("../../images/art/companions/hamster/7075.png"),
        },
        {
            "number": 7278,
            "image": require("../../images/art/companions/hamster/7278.png"),
        },
        {
            "number": 7293,
            "image": require("../../images/art/companions/hamster/7293.png"),
        },
        {
            "number": 7511,
            "image": require("../../images/art/companions/hamster/7511.png"),
        },
        {
            "number": 8173,
            "image": require("../../images/art/companions/hamster/8173.png"),
        },
        {
            "number": 8412,
            "image": require("../../images/art/companions/hamster/8412.png"),
        },
        {
            "number": 8990,
            "image": require("../../images/art/companions/hamster/8990.png"),
        },
        {
            "number": 9014,
            "image": require("../../images/art/companions/hamster/9014.png"),
        },
    ],
    alligator_caiman: [
        {
            "number": 164,
            "image": require("../../images/art/companions/alligator_caiman/164.png"),
        },
        {
            "number": 305,
            "image": require("../../images/art/companions/alligator_caiman/305.png"),
        },
        {
            "number": 613,
            "image": require("../../images/art/companions/alligator_caiman/613.png"),
        },
        {
            "number": 670,
            "image": require("../../images/art/companions/alligator_caiman/670.png"),
        },
        {
            "number": 858,
            "image": require("../../images/art/companions/alligator_caiman/858.png"),
        },
        {
            "number": 1046,
            "image": require("../../images/art/companions/alligator_caiman/1046.png"),
        },
        {
            "number": 1284,
            "image": require("../../images/art/companions/alligator_caiman/1284.png"),
        },
        {
            "number": 1907,
            "image": require("../../images/art/companions/alligator_caiman/1907.png"),
        },
        {
            "number": 2207,
            "image": require("../../images/art/companions/alligator_caiman/2207.png"),
        },
        {
            "number": 2353,
            "image": require("../../images/art/companions/alligator_caiman/2353.png"),
        },
        {
            "number": 3399,
            "image": require("../../images/art/companions/alligator_caiman/3399.png"),
        },
        {
            "number": 3542,
            "image": require("../../images/art/companions/alligator_caiman/3542.png"),
        },
        {
            "number": 3722,
            "image": require("../../images/art/companions/alligator_caiman/3722.png"),
        },
        {
            "number": 4235,
            "image": require("../../images/art/companions/alligator_caiman/4235.png"),
        },
        {
            "number": 4566,
            "image": require("../../images/art/companions/alligator_caiman/4566.png"),
        },
        {
            "number": 5525,
            "image": require("../../images/art/companions/alligator_caiman/5525.png"),
        },
        {
            "number": 5591,
            "image": require("../../images/art/companions/alligator_caiman/5591.png"),
        },
        {
            "number": 5706,
            "image": require("../../images/art/companions/alligator_caiman/5706.png"),
        },
        {
            "number": 5756,
            "image": require("../../images/art/companions/alligator_caiman/5756.png"),
        },
        {
            "number": 5872,
            "image": require("../../images/art/companions/alligator_caiman/5872.png"),
        },
        {
            "number": 5997,
            "image": require("../../images/art/companions/alligator_caiman/5997.png"),
        },
        {
            "number": 6039,
            "image": require("../../images/art/companions/alligator_caiman/6039.png"),
        },
        {
            "number": 6545,
            "image": require("../../images/art/companions/alligator_caiman/6545.png"),
        },
        {
            "number": 6723,
            "image": require("../../images/art/companions/alligator_caiman/6723.png"),
        },
        {
            "number": 6824,
            "image": require("../../images/art/companions/alligator_caiman/6824.png"),
        },
        {
            "number": 6873,
            "image": require("../../images/art/companions/alligator_caiman/6873.png"),
        },
        {
            "number": 6880,
            "image": require("../../images/art/companions/alligator_caiman/6880.png"),
        },
        {
            "number": 6959,
            "image": require("../../images/art/companions/alligator_caiman/6959.png"),
        },
        {
            "number": 7212,
            "image": require("../../images/art/companions/alligator_caiman/7212.png"),
        },
        {
            "number": 7369,
            "image": require("../../images/art/companions/alligator_caiman/7369.png"),
        },
        {
            "number": 7371,
            "image": require("../../images/art/companions/alligator_caiman/7371.png"),
        },
        {
            "number": 7732,
            "image": require("../../images/art/companions/alligator_caiman/7732.png"),
        },
        {
            "number": 8356,
            "image": require("../../images/art/companions/alligator_caiman/8356.png"),
        },
        {
            "number": 9209,
            "image": require("../../images/art/companions/alligator_caiman/9209.png"),
        },
        {
            "number": 9556,
            "image": require("../../images/art/companions/alligator_caiman/9556.png"),
        },
    ],
}



export default companios 