import { NavLink } from "react-router-dom";

// Components
import Header from "./Header";
import Footer from "./Footer";

// Images & Icons
import Stage1 from "../images/art/vending.gif";
import Stage2 from "../images/about/img/gif-s2.gif";
import Stage3 from "../images/stage3/container.png";

function StagesPage() {
  return (
    <div className="container-fluid pb-tabbar">
      <Header />
      <div className="w-100">
        <div className="pt-header stages-w">
          <div className="row pt-3 m-0">
            <div className="col-12 p-1">
              <NavLink
                to={`/s1`}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <div className="pill py-3">S I</div>
              </NavLink>
            </div>
            <div className="col-12 p-1">
              <NavLink
                to={`/s1`}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <div className="pill py-3">
                  <img
                    src={Stage1}
                    className="pill-img"
                    alt="stage III : ???"
                  />
                </div>
              </NavLink>
            </div>
            <div className="col-12 p-1">
              <NavLink
                to={`/s2`}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <div className="pill py-3">S II</div>
              </NavLink>
            </div>
            <div className="col-12 p-1">
              <NavLink
                to={`/s2`}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <div className="pill py-3">
                  <img
                    src={Stage2}
                    className="pill-img"
                    alt="stage III : ???"
                  />
                </div>
              </NavLink>
            </div>
            <div className="col-12 p-1">
              <NavLink
                to={`/s3`}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <div className="pill py-3">S III</div>
              </NavLink>
            </div>
            <div className="col-12 p-1">
              <NavLink
                to={`/s3`}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <div className="pill py-5">
                  <img
                    src={Stage3}
                    className="pill-img-s3"
                    alt="stage III : ???"
                  />
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default StagesPage;
