import { createRoot } from 'react-dom/client';

import '@rainbow-me/rainbowkit/styles.css';
import { getDefaultWallets, RainbowKitProvider, darkTheme } from '@rainbow-me/rainbowkit';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { mainnet } from 'wagmi/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';

// Components
import App from "./App";
import reportWebVitals from "./reportWebVitals";


const { chains, publicClient } = configureChains(
  [mainnet],
  [
    alchemyProvider({ apiKey: 't4--Z_UjgxBD1JEb2yiEWQYZDIKeHIBy' }),
    publicProvider()
  ]
);
const { connectors } = getDefaultWallets({
  appName: 'YPGsWeb3',
  projectId: '88d9b84fbb24e0a04b7d1f50405b1d2f',
  chains
});
const wagmiConfig = createConfig({ autoConnect: true, connectors, publicClient })

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <div>
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider modalSize="compact" chains={chains}
        theme={darkTheme({
          accentColor: '#EBEBEB',
          accentColorForeground: 'white',
          borderRadius: 'medium',
          fontStack: 'system',
          overlayBlur: 'small',
        })}>
        <App tab="home" />
      </RainbowKitProvider>
    </WagmiConfig>
  </div>
);

reportWebVitals();
