import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

// Components
// import Header from "./HeaderTransparent";

// Images & Icons
import BoredFrog from "../images/art/bored_frog.gif"

function ErrorPage() {

  const colors = [
    { color: '#5A99F9' },
    { color: '#7CAFFF' },
    { color: '#CDFCFF' },
    { color: '#A3FCC6' },
    { color: '#81FFC0' },
    { color: '#41ECAA' },
    { color: '#FFA128' },
    { color: '#FFE472' },
    { color: '#FBFFAC' },
    { color: '#FFA996' },
    { color: '#FF2173' },
    { color: '#FFA3D3' },
    { color: '#C5C7FA' },
    { color: '#A3A5F2' },
    { color: '#8799FF' }
  ];

  const [bgColourHome, setBgColourHome] = useState("");
  const [bgColourTerms, setBgColourTerms] = useState("");
  const [bgColourProvenance, setBgColourProvenance] = useState("");
  const [bgColourTwitter, setBgColourTwitter] = useState("");
  const [bgColourDiscord, setBgColourDiscord] = useState("");

  const d = new Date();
  let year = d.getFullYear();

  useEffect(() => {
    window.scrollTo(0, 0, 'instant')
  }, [])

  return (

    <div>
      <div className="row error-col-color p-0 m-0">
        {colors.map(({ color }, index) => {
          return (
            <div
              key={index}
              className={`col error-color`}
              style={{ backgroundColor: color, color: color }}
            >
            </div>
          );
        }
        )}
      </div>

      <div className="container-fluid pt-header pb-tabbar">
        {/* <Header /> */}

        {/* Mobile */}
        <div className="fixed-bottom d-lg-none">
          <div className="text-center">
            <div className="section-error-menu">
              <div style={{ display: 'flex' }}>
                <div className="px-1 pb-3">
                  <NavLink
                    exact to={`/`}
                    className='bubble-error-black'
                    activeClassName="active active-home"
                    style={{ backgroundColor: bgColourHome }}
                    onMouseEnter={() => setBgColourHome("#FFFFFF")}
                    onMouseLeave={() => setBgColourHome("")}>
                    Back Home
                  </NavLink>
                </div>
              </div>
              <h5 className="text-year text-left px-3 mb-0">© {year} Yaypegs.</h5>
            </div>
            <div className="pt-4">
              <img src={BoredFrog} className="bored-error" alt="Bored Frog" />
            </div>
          </div>
        </div>

        <div className="fixed-bottom d-none d-lg-block">
          <div className="text-center">
            <div className="section-error-menu">
              <div style={{ display: 'flex' }}>
                <div className="px-1 pb-3">
                  <NavLink
                    exact to={`/`}
                    className='bubble-error-black'
                    activeClassName="active active-home"
                    style={{ backgroundColor: bgColourHome }}
                    onMouseEnter={() => setBgColourHome("#FFFFFF")}
                    onMouseLeave={() => setBgColourHome("")}>
                    Home
                  </NavLink>
                </div>
                <div className="px-1 pb-3">
                  <NavLink
                    exact to={`/terms`}
                    className='bubble-error-black'
                    activeClassName="active active-terms"
                    style={{ backgroundColor: bgColourTerms }}
                    onMouseEnter={() => setBgColourTerms("#FFFFFF")}
                    onMouseLeave={() => setBgColourTerms("")}>
                    Terms
                  </NavLink>
                </div>
                <div className="px-1 pb-3">
                  <NavLink
                    exact to={`/provenance`}
                    className='bubble-error-black'
                    activeClassName="active active-terms"
                    style={{ backgroundColor: bgColourProvenance }}
                    onMouseEnter={() => setBgColourProvenance("#FFFFFF")}
                    onMouseLeave={() => setBgColourProvenance("")}>
                    Provenance
                  </NavLink>
                </div>
                <div className="px-1 pb-3">
                  <a
                    className=""
                    href="https://twitter.com/yaypegs"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Twitter"
                  >
                    <div className="bubble-error-black mb-0"
                      style={{ backgroundColor: bgColourTwitter }}
                      onMouseEnter={() => setBgColourTwitter("#FFFFFF")}
                      onMouseLeave={() => setBgColourTwitter("")}
                    >
                      Twitter
                    </div>
                  </a>
                </div>
                <div className="px-1 pb-3">
                  <a
                    className=""
                    href="http://discord.gg/T7y8Up89Sc"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Discord"
                  >
                    <div className="bubble-error-black mb-0"
                      style={{ backgroundColor: bgColourDiscord }}
                      onMouseEnter={() => setBgColourDiscord("#FFFFFF")}
                      onMouseLeave={() => setBgColourDiscord("")}
                    >
                      Discord
                    </div>
                  </a>
                </div>
              </div>
              <div className="pt-3">
                <h5 className="text-year text-left px-3 mb-0">© {year} Yaypegs.</h5>
              </div>
            </div>

            <div className="pt-5">
              <div className="section-year col-sm-3 col-12 inline-block">
              </div>
              <div className="col-sm-6 col-12 inline-block">
                <img src={BoredFrog} className="bored-error" alt="Bored Frog" />
              </div>
              <div className="col-sm-3 col-12 inline-block"> </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ErrorPage;
