import { useEffect } from "react";
// import Lottie from "react-lottie";

// Components
import Header from "../Header";
import Footer from "../Footer";

// Icons

import Send from "../../images/about/send.svg";
import ReTweet from "../../images/about/retweet.svg";
// import Tweet from "../../images/about/tweet.svg";

// Json
import Post from "./Post";

// Images
// import Headline_About from "../../images/about/time/about.png";
import Subtitle from "../../images/about/highlights.png";
import D_2022 from "../../images/about/time/2022.png";
import D_2023 from "../../images/about/time/2023.png";
import Jan from "../../images/about/time/1.png";
import Feb from "../../images/about/time/2.png";
import Mar from "../../images/about/time/3.png";
// import Apr from "../../images/about/time/4.png";
import May from "../../images/about/time/5.png";
import Jun from "../../images/about/time/6.png";
import Jul from "../../images/about/time/7.png";
import Aug from "../../images/about/time/8.png";
// import Sep from "../../images/about/time/9.png";
// import Oct from "../../images/about/time/10.png";
import Nov from "../../images/about/time/11.png";
import Dec from "../../images/about/time/12.png";

function About_Timeline(post, Month, Year) {
  useEffect(() => {
    window.scrollTo(0, 0, "instant");
  }, []);

  return (
    <div className="pb-5">
      <div className="pt-3">
        <img src={Month} className="about-date" alt="Month" />
        <img src={Year} className="about-year" alt="Year" />
      </div>
      {post.map(({ date, text, note, img, video, tweet }, index) => {
        return (
          <div key={index} className={``}>
            <div className="about-box">
              <hr className="about-line" />
              <div className="about-grey">{date}</div>
              <div className="about-text pt-1">{text}</div>
              {img.length > 0 && (
                <div className="row py-2 px-2">
                  {img.map(({ file }, index) => {
                    if (img.length > 2) {
                      return (
                        <div key={index} className="col-3 p-1">
                          <img
                            src={file}
                            className="col about-img"
                            alt="post-img"
                          />
                        </div>
                      );
                    } else {
                      return (
                        <div key={index} className="col px-1">
                          <img
                            src={file}
                            className="col about-img"
                            alt="post-img"
                          />
                        </div>
                      );
                    }
                  })}
                </div>
              )}
              {video.length > 0 && (
                <div className="row py-2 px-2">
                  {video.map(({ file, poster }, index) => {
                    return (
                      <div key={index} className="col px-1">
                        <video
                          width={`100%`}
                          height={`auto`}
                          controls
                          poster={poster}
                        >
                          <source src={file} type="video/mp4" />
                        </video>
                      </div>
                    );
                  })}
                </div>
              )}
              <div className="about-grey pt-2">* {note}</div>
              <div className="d-flex justify-content-between pt-2">
                <div>
                  {tweet && (
                    <div
                      className="inline-block"
                      style={{ paddingRight: "0.2rem" }}
                    >
                      <a
                        className=""
                        href={tweet}
                        data-size="large"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="tweet"
                      >
                        <img
                          src={ReTweet}
                          className="about-icon pb-1"
                          alt="ReTweet"
                        />
                      </a>
                    </div>
                  )}
                  <a
                    className=""
                    href={`https://twitter.com/intent/tweet?text=facts:%20@yaypegs%0A${note}`}
                    data-size="large"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="tweet"
                  >
                    <img src={Send} className="about-icon pb-1" alt="send" />
                  </a>
                </div>
                {/* <img src={Tweet} className="about-icon pb-1" alt="send" /> */}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

function AboutPage() {
  return (
    <div className="container-fluid pt-header pb-tabbar">
      <Header bgColor={false} />
      <div className="row pt-0 m-0">
        <div className="col-12 p-0">
          <div className="text-center pt-5 pb-2">
            <h1 className="uppercase mb-2">About</h1>
            <img
              src={Subtitle}
              className="about-subtitle pb-1"
              alt="Highlights"
            />
            {/* <img src={Headline_About} className="about-title" alt="about" /> */}
          </div>

          <div className="text-center pt-4">
            <div className="about">
              {About_Timeline(Post.nov22, Nov, D_2022)}
              {About_Timeline(Post.dec22, Dec, D_2022)}
              {About_Timeline(Post.jan23, Jan, D_2023)}
              {About_Timeline(Post.feb23, Feb, D_2023)}
              {About_Timeline(Post.mar23, Mar, D_2023)}
              {About_Timeline(Post.may23, May, D_2023)}
              {About_Timeline(Post.June23, Jun, D_2023)}
              {About_Timeline(Post.July23, Jul, D_2023)}
              {About_Timeline(Post.Aug23, Aug, D_2023)}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AboutPage;
