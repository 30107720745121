import { useState, useEffect } from "react";
import Lottie from "react-lottie";

// Components
import Header from "./Header";
import Footer from "./FooterSimple";

// Images & Icons
import Init from "../images/magic/init.gif";
import VendingMachine from "../images/magic/vending.gif";

import WTF_step_1 from "../images/magic/wtf/step_1.png";
import WTF_step_2 from "../images/magic/wtf/step_2.png";
import WTF_step_3 from "../images/magic/wtf/step_3.png";


// Lottie
import Lever_ from "../images/magic/lottie/lever.json";
import Download_ from "../images/magic/lottie/download.json";
import Crane_ from "../images/magic/lottie/crane.json";


function Stage1Page() {

    const min = 0;
    const max = 10000;

    const bucket = `collection`;

    const [NFTnumber, setNFTnumber] = useState(0);
    const [firebase, setFirebase] = useState('');
    const [isOnWTF, setIsOnWTF] = useState(false);

    const [isStoppedLever1, setIsStoppedLever1] = useState(true);
    const [playAnimationLever1, setPlayAnimationLever1] = useState(false);

    const [isStoppedLever2, setIsStoppedLever2] = useState(true);
    const [playAnimationLever2, setPlayAnimationLever2] = useState(false);

    const [isStoppedDownload, setIsStoppedDownload] = useState(true);
    const [playAnimationDownload, setPlayAnimationDownload] = useState(false);

    const [startVending, setStartVending] = useState(false);
    const [isStoppedCrane, setIsStoppedCrane] = useState(true);
    const [isPausedCrane, setIsPausedCrane] = useState(true);
    const [hideGIF, setHideGIF] = useState('');

    const LeverLottie1 = {
        loop: false,
        autoplay: false,
        animationData: Lever_,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        },
    };

    const LeverLottie2 = {
        loop: false,
        autoplay: false,
        animationData: Lever_,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        },
    };

    const DownloadLottie = {
        loop: false,
        autoplay: false,
        animationData: Download_,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        },
    };

    const CraneLottie = {
        loop: false,
        autoplay: false,
        animationData: Crane_,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        },
    };

    const onChangeHandler = event => {
        const limit = 5;
        const value = Math.max(min, Math.min(max, Number(event.target.value.slice(0, limit))));
        setNFTnumber(value);
    };

    const handleFocus = event => {
        event.target.select();
    };

    useEffect(() => {
        window.scrollTo(0, 0, 'instant');
    }, []);

    useEffect(() => {
        setStartVending(false);
        setHideGIF("");
    }, [NFTnumber]);


    useEffect(() => {
        async function getImagefromFirebase() {
            if (NFTnumber === 0) {
                setStartVending(false);
                setHideGIF("");
            }
            if (NFTnumber !== 0) {
                try {
                    const response = await fetch(`https://firebasestorage.googleapis.com/v0/b/yaypegsnft.appspot.com/o/${bucket}%2F${NFTnumber}.gif`);
                    const responseJson = await response.json();
                    setFirebase(responseJson);
                } catch (error) {
                    console.error(error);
                }
            }
        }
        getImagefromFirebase();
        return () => { // Clean up function:Remove the function from memory when the component unmounts
            getImagefromFirebase = null;
        };
    }, [NFTnumber, bucket]);

    useEffect(() => {
        if (playAnimationLever1 && isStoppedLever1) {
            setIsStoppedLever1(false);
            setTimeout(() => {
                setPlayAnimationLever1(false);
                setIsStoppedLever1(true);
            }, 1500);
        }
    }, [playAnimationLever1, isStoppedLever1]);

    useEffect(() => {
        if (playAnimationLever2 && isStoppedLever2) {
            setIsStoppedLever2(false);
            setTimeout(() => {
                setPlayAnimationLever2(false);
                setIsStoppedLever2(true);
            }, 1500);
        }
    }, [playAnimationLever2, isStoppedLever2]);

    useEffect(() => {
        if (playAnimationDownload && isStoppedDownload) {
            setIsStoppedDownload(false);
            setTimeout(() => {
                setPlayAnimationDownload(false);
                setIsStoppedDownload(true);
            }, 1500);
        }
    }, [playAnimationDownload, isStoppedDownload]);


    const handleClickLever1 = () => {
        if (playAnimationLever1 !== true) {
            setPlayAnimationLever1(true);
            if (NFTnumber !== 0) {
                setTimeout(() => {
                    setHideGIF("");
                    setStartVending(false);
                    setIsStoppedCrane(true);
                    setIsPausedCrane(true);
                }, 1000);
            }
        }
    }

    const handleClickLever2 = () => {
        if (playAnimationLever2 !== true) {
            setPlayAnimationLever2(true);
            if (NFTnumber !== 0) {
                setTimeout(() => {
                    setHideGIF("displayNone");
                    setStartVending(true);
                    setIsStoppedCrane(false);
                    setIsPausedCrane(false);
                }, 700);
            }
        }
    }

    const handleClickDownload = () => {
        if (playAnimationDownload !== true) {
            setPlayAnimationDownload(true);
            if (NFTnumber !== 0) {
                setTimeout(() => {
                    downloadGif();
                }, 700);
            }
        }
    }

    function downloadGif() {
        if (NFTnumber !== 0 && startVending === true) {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', `https://firebasestorage.googleapis.com/v0/b/yaypegsnft.appspot.com/o/${bucket}%2F${NFTnumber}.gif?alt=media&firebase=${firebase.downloadTokens}`, true);
            xhr.responseType = 'blob';

            xhr.onload = () => {
                if (xhr.status === 200) {
                    const blob = new Blob([xhr.response], { type: 'image/gif' });
                    const url = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${NFTnumber}.gif`);
                    document.body.appendChild(link);
                    link.click();
                } else {
                    console.error('Error downloading GIF:', xhr.statusText);
                }
            };
            xhr.onerror = () => {
                console.error('Error downloading GIF:', xhr.statusText);
            };
            xhr.send();
        }
    }

    return (
        <div className="container-fluid pb-tabbar">
            <Header />
            <div className="row pt-0 m-0">
                <div className="col-12 p-0">
                    <div>
                        <div className="parent-center mobile-parent-center">
                            <div className="child-center">
                                {isOnWTF
                                    ? (<div className="d-none d-lg-block">
                                        <img src={WTF_step_1} className="wtf-s1" alt="step 1" />
                                        <img src={WTF_step_2} className="wtf-s2" alt="step 2" />
                                        <img src={WTF_step_3} className="wtf-s3" alt="step 3" />
                                    </div>)
                                    : <div></div>
                                }
                                <img src={VendingMachine} className="vending-machine" alt="Magic Machine" />
                                <div>
                                    <div className={hideGIF}>
                                        <img src={Init} className="gif-machine" alt="gif" />
                                    </div>
                                    <div>
                                        <Lottie
                                            isStopped={isStoppedCrane}
                                            isPaused={isPausedCrane}
                                            speed={0.4}
                                            className="gif-machine"
                                            alt="gif"
                                            style={{ zIndex: '-3', height: '202px', width: '202px', position: 'fixed', top: '82px', left: '27px' }}
                                            options={CraneLottie}
                                        />
                                        <img
                                            src={`https://firebasestorage.googleapis.com/v0/b/yaypegsnft.appspot.com/o/${bucket}%2F${NFTnumber}.gif?alt=media&firebase=${firebase.downloadTokens}`}
                                            className="gif-machine"
                                            style={{ zIndex: '-4' }}
                                            alt="gif"
                                        />
                                    </div>

                                </div>

                                <div className="" onClick={() => handleClickLever1()} style={{ zIndex: '3 !important' }}>
                                    <Lottie
                                        isStopped={isStoppedLever1}
                                        isPaused={false}
                                        speed={0.4}
                                        alt="lever 1"
                                        style={{ height: '67px', width: '46px', position: 'fixed', top: '298px', left: '126px' }}
                                        options={LeverLottie1}
                                    />
                                    {/* <img src={Lever} className="vending-lever-1" alt="gif" /> */}
                                </div>

                                <div className="" onClick={() => handleClickLever2()} style={{ zIndex: '3 !important' }}>
                                    <Lottie
                                        isStopped={isStoppedLever2}
                                        isPaused={false}
                                        speed={0.4}
                                        alt="lever 2"
                                        style={{ height: '67px', width: '46px', position: 'fixed', top: '298px', left: '180px' }}
                                        options={LeverLottie2}
                                    />
                                    {/* <img src={Lever} className="vending-lever-2" alt="gif" /> */}
                                </div>


                                <div onClick={() => handleClickDownload()} style={{ zIndex: '3 !important' }}>
                                    <Lottie
                                        isStopped={isStoppedDownload}
                                        isPaused={false}
                                        speed={0.4}
                                        alt="Download"
                                        style={{ height: '65px', width: '150px', position: 'fixed', top: '391px', left: '30px' }}
                                        options={DownloadLottie}
                                    />
                                    {/* <img src={Download} className="vending-download" alt="gif" /> */}
                                </div>

                                <div className="faceID">
                                    <input
                                        type="number"
                                        name="number"
                                        placeholder="0000"
                                        maxLength={5}
                                        onChange={onChangeHandler}
                                        onFocus={handleFocus}
                                        value={NFTnumber}
                                        min="1"
                                        max="10000"
                                    />
                                </div>
                                <div className="d-none d-lg-block">
                                    <div className="text-center">
                                        <div style={{ paddingRight: '55px' }} className="inline-block pt-2">
                                            <button
                                                onClick={() => setIsOnWTF(!isOnWTF)}
                                                className='bubble bubble-index inline-block'>
                                                wtf?
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Stage1Page;