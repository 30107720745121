// Post About - Timeline

const post =
{
    nov22:
        [
            {
                "date": `Nov 11, 2022`,
                "text": `Allow list mint began`,
                "note": `Allowlist was open for holders for more than a month`,
                img: [
                    { "file": require("../../images/about/img/box-gif-1.gif") },
                    { "file": require("../../images/about/img/box-gif-2.gif") },
                ],
                video: [],
                "tweet": `https://twitter.com/yaypegs/status/1591118912560967681`,
            },
        ],
    dec22: [
        {
            "date": `Dec 15, 2022`,
            "text": `Public mint began! 📦👀\n
            Public Mint: Thursday 12/15 at 15:12 EST
            Price: Free 
            Royalties: 0%`,
            "note": `After a time frame of 34 days, the mint opened for the public`,
            img: [],
            video: [],
            "tweet": `https://twitter.com/yaypegs/status/1603400900311388160`,
        },
        {
            "date": `Dec 15, 2022`,
            "text": `Yaypegs is Sold Out.`,
            "note": `The collection sells out in just 2 min`,
            img: [],
            video: [],
            "tweet": `https://twitter.com/yaypegs/status/1603487005777747972`,
        },
        {
            "date": `Dec 19, 2022`,
            "text": `Yaypegs collection revealed 📦👀`,
            "note": `The collection reveal was 6 days after the public mint`,
            img: [{
                "file": require("../../images/about/img/yay.gif"),
            },],
            video: [],
            "tweet": `https://twitter.com/yaypegs/status/1604930737513566209`,
        },
        {
            "date": `Dec 19, 2022`,
            "text": `Opensea delisted yaypegs from the marketplace and canceled our account. The whole community started using Blur, a not very popular option at the time.`,
            "note": `The Opensea delist was just hours after the reveal, with just over 100 ETH in total volume. This could have killed the project.`,
            img: [],
            video: [],
            "tweet": `https://twitter.com/yaypegs/status/1605046172489506818`,
        },
        {
            "date": `Dec 20, 2022`,
            "text": `Opensea relists yaypegs. The noise generated by the community was enough to achieve it in less than 24 hours`,
            "note": `Thanks to the combined effort of the community and team, we managed to be back on opensea.`,
            img: [],
            video: [],
            "tweet": `https://twitter.com/yaypegs/status/1605268933430353943`,
        },
        {
            "date": `Dec 21, 2022`,
            "text": `"royalties, or not royalties, that was the question": A vote was held among the holders to decide if the royalties should be activated.`,
            "note": `Yaypegs was a 0% royalty project, it was a gift to the NFT community with no financial expectations for the founders.`,
            img: [],
            video: [],
            "tweet": `https://twitter.com/yaypegs/status/1605748221149466624`,
        },
        {
            "date": `Dec 22, 2022`,
            "text": `Voting results 🗳️
            Yes - 5% Royalties 4.3K YAY - 91.67%
            No - 0% Royalties 390 YAY - 8.33%
            Quorum 4.7K / 500`,
            "note": `Yaypegs does not enforce royalties by contract, all funds collected have been delivered voluntarily.`,
            img: [],
            video: [],
            "tweet": `https://twitter.com/yaypegs/status/1607780601150730241`,
        },

    ],

    jan23: [
        {
            "date": `Jan 5, 2023`,
            "text": `Collection was verified on Opensea 🌐`,
            "note": `After going through an in-depth KYC process by Opensea, the account and collection was verified.`,
            img: [],
            video: [],
            "tweet": `https://twitter.com/yaypegs/status/1611083449279840256`,
        },
    ],

    feb23: [
        {
            "date": `Feb 4, 2023`,
            "text": `Collab w/ AlienFriends 👽`,
            "note": `Manson (cofounder/artist @alienfrens) and ccmoret make their first cross-collection collaboration 👽 🤝 🍄`,
            img: [
                { "file": require("../../images/about/img/af-1.png") },
                { "file": require("../../images/about/img/af-2.png") },
            ],
            video: [],
            "tweet": `https://twitter.com/yaypegs/status/1621267398933286914`,
        },
        {
            "date": `Feb 5, 2023`,
            "text": `The future of Yaypegs: Founders vs. Community in charge`,
            "note": `A group of holders believe that the project will be better if it is led by the community. We, as founders, believe that the support of the community is essential to lead a web 3 project, that is why we decided to put it to a vote.`,
            img: [],
            video: [],
            "tweet": `https://snapshot.org/#/yaypegs.eth/proposal/0xb426ba39b109e6755ce23b6973da35aa3699570fb70f9f6fb3d18eb8ed08a252`,
        },
        {
            "date": `Feb 8, 2023`,
            "text": `Founders in charge 98.57% in favor`,
            "note": `The community voted to keep the founders in charge of the project.`,
            img: [],
            video: [],
            "tweet": `https://twitter.com/yaypegs/status/1623427683324403719`,
        },
        {
            "date": `Feb 10, 2023`,
            "text": `Rebranding
            Build it anyway
            Create it anyway`,
            "note": `The team launched its first rebranding campaign`,
            img: [],
            video: [{
                "file": require("../../images/about/img/rebrand.mp4"),
                "poster": require("../../images/about/img/poster-rebrand.png"),
            },],
            "tweet": `https://twitter.com/yaypegs/status/1624047072570638342`,
        },


        {
            "date": `Feb 17, 2023`,
            "text": `Website redesign and new collection explorer`,
            "note": `Yaypegs is designed in such a way that it is impossible to have an official ranking. There are hidden traits that are not reflected in the metadata. For this reason the team create. https://yaypegs.com/explorer`,
            img: [],
            video: [],
            "tweet": `https://twitter.com/yaypegs/status/1626618583961698312`,
        },
    ],
    mar23: [
        {
            "date": `Mar 10, 2023`,
            "text": `Magic Machine teaser`,
            "note": `Magic Machine will not have only one form.`,
            img: [{
                "file": require("../../images/about/img/magic-machine.gif"),
            },],
            video: [],
            "tweet": `https://twitter.com/yaypegs/status/1634236721163321355`,
        },
    ],
    may23: [
        {
            "date": `May 15, 2023`,
            "text": `stage 1 - Magic Machine went live 🏗️`,
            "note": `10,000 animated NFTs, +500 individually animated traits, +120 traits reinterpreted. Making each animated NFT truly unique and expanded the current IP Rights`,
            img: [
                { "file": require("../../images/about/img/gif-1.gif") },
                { "file": require("../../images/about/img/gif-2.gif") },
                { "file": require("../../images/about/img/gif-3.gif") },
                { "file": require("../../images/about/img/gif-4.gif") },
                // { "file": require("../../images/about/img/gif-12.gif") },
                { "file": require("../../images/about/img/gif-6.gif") },
                // { "file": require("../../images/about/img/gif-5.gif") },
                // { "file": require("../../images/about/img/gif-7.gif") },
                // { "file": require("../../images/about/img/gif-8.gif") },
                { "file": require("../../images/about/img/gif-9.gif") },
                { "file": require("../../images/about/img/gif-10.gif") },
                { "file": require("../../images/about/img/gif-11.gif") },

            ],
            video: [],
            "tweet": `https://twitter.com/yaypegs/status/1658249378266599424`,
        },
    ],

    June23: [
        {
            "date": `Jun 19, 2023`,
            "text": `stage 2 preview
            launched: July 2023`,
            "note": `Announcement that the collection will be transformed from pixels to vector illustration and the first sneak peek is shown`,
            img: [{
                "file": require("../../images/about/img/sneakpeek2-s2.png"),
            },],
            video: [],
            "tweet": `https://twitter.com/yaypegs/status/1670894080648200192`,
        },
    ],

    July23: [
        {
            "date": `Jul 27, 2023`,
            "text": `teaser stage 2  🪄`,
            "note": `Stage 2 was in ccmoret's mind from the first pixel stroke`,
            img: [],
            video: [{
                "file": require("../../images/about/img/trailer-s2.mp4"),
                "poster": require("../../images/about/img/poster2-s2.png"),
            },],
            "tweet": `https://twitter.com/yaypegs/status/1684633814289592320`,
        },
        {
            "date": `Jul 27, 2023`,
            "text": `stage 2 went live 🪄`,
            "note": `The vector illustration has as its main objective to attract new holders that resonate with this type of art, showing the versatility of IP in different formats. 🪄 https://yaypegs.com/s2`,
            img: [{
                "file": require("../../images/about/img/gif-s2.gif"),
            },],
            video: [],
            "tweet": `https://twitter.com/yaypegs/status/1684633814289592320`,
        },
    ],
    Aug23: [
        {
            "date": `Aug 21, 2023`,
            "text": `IP center 2 went live 🧸`,
            "note": `IP center is a new way to view and download all your Yaypegs NFT versions in one place. 🪄 https://yaypegs.com/ip`,
            img: [{
                "file": require("../../images/about/img/IP.png"),
            },],
            video: [],
            "tweet": `https://twitter.com/yaypegs/status/1693669795823030515`,
        },
    ],
}

export default post 