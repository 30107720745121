import { NavLink } from "react-router-dom";

// Images & Icons
import BoredFrog from "../images/art/bored_frog.gif";
// import BoredFrog from "../images/art/bored_frog.svg";

// Components 
import MenuMobile from "./FooterMobile";

export default function Footer() {

  const colors = [
    { color: '#5A99F9' },
    { color: '#7CAFFF' },
    { color: '#CDFCFF' },
    { color: '#A3FCC6' },
    { color: '#81FFC0' },
    { color: '#41ECAA' },
    { color: '#FFA128' },
    { color: '#FFE472' },
    { color: '#FBFFAC' },
    { color: '#FFA996' },
    { color: '#FF2173' },
    { color: '#FFA3D3' },
    { color: '#C5C7FA' },
    { color: '#A3A5F2' },
    { color: '#8799FF' }
  ];

  const d = new Date();
  let year = d.getFullYear();

  return (
    <footer className="">
      <div className="text-center pt-5">
        <img src={BoredFrog} className="bored-footer" alt="Bored Frog" />
      </div>
      <div className="row brand-col-colors p-0 m-0">
        {colors.map(({ color }, index) => {
          return (
            <div
              key={index}
              className={`col brand-colors`}
              style={{ backgroundColor: color, color: color }}
            >
            </div>
          );
        }
        )}
      </div>
      <div style={{ lineHeight: "100px" }} className="row m-0 d-none d-lg-block">
        {/* Section 1 */}
        <div className="col-sm-3 col-12 m-auto py-1 inline-block">
          <h5 className="mb-0">© {year} Yaypegs.</h5>
        </div>

        {/* Section 2 */}
        {/* <div className="col-sm-2 col-12 m-auto py-1 text-center inline-block"></div> */}

        {/* Section 3 */}
        <div className="col-sm-9 col-12 m-auto py-1 inline-block">
          <div className="footer-end">
            <div className="px-1 inline-block">
              <NavLink
                to={`/terms`}
                className={({ isActive }) => (isActive ? "bubble inline-block active" : 'bubble inline-block')}>
                Terms
              </NavLink>
            </div>
            <div className="px-1 inline-block">
              <NavLink
                to={`/trading-policy`}
                className={({ isActive }) => (isActive ? "bubble inline-block active" : 'bubble inline-block')}>
                Policy
              </NavLink>
            </div>
            <div className="px-1 inline-block">
              <NavLink
                to={`/provenance`}
                className={({ isActive }) => (isActive ? "bubble inline-block active" : 'bubble inline-block')}>
                Provenance
              </NavLink>
            </div>
            <div className="px-1 inline-block py-1" style={{ paddingLeft: "10px" }}>
              <a
                className="link"
                href="https://twitter.com/yaypegs"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Twitter"
              >
                <div className="bubble mb-0">
                  Twitter
                </div>
              </a>
            </div>

            <div className="px-1 inline-block py-1" style={{ paddingLeft: "10px" }}>
              <a
                className="link"
                href="http://discord.gg/T7y8Up89Sc"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Discord"
              >
                <div className="bubble mb-0">
                  Discord
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile */}
      <MenuMobile />
    </footer >
  );
}
