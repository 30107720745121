// Traits List

const traits =
{
    accessory: [
        { trait: "Tennis Ball", quantity: 167 },
        { trait: "Cardboard Box", quantity: 158 },
        { trait: "Newspaper", quantity: 151 },
        { trait: "Trombone", quantity: 150 },
        { trait: "Walkie Talkie", quantity: 150 },
        { trait: "Coffee", quantity: 146 },
        { trait: "Basketball", quantity: 145 },
        { trait: "Smartphone (Black)", quantity: 145 },
        { trait: "Football", quantity: 144 },
        { trait: "Instrument Case", quantity: 143 },
        { trait: "Accordion", quantity: 139 },
        { trait: "Laptop (Grey)", quantity: 139 },
        { trait: "Racquet", quantity: 139 },
        { trait: "Contract", quantity: 137 },
        { trait: "Guitar 01", quantity: 137 },
        { trait: "Controller (White)", quantity: 133 },
        { trait: "Volleyball", quantity: 133 },
        { trait: "American Football", quantity: 130 },
        { trait: "Cricket", quantity: 130 },
        { trait: "Magazine", quantity: 130 },
        { trait: "Butcher Knive", quantity: 128 },
        { trait: "Amplifier", quantity: 127 },
        { trait: "Controller (Black)", quantity: 127 },
        { trait: "Surgical Gloves", quantity: 127 },
        { trait: "Laptop (Black)", quantity: 126 },
        { trait: "Camera (Cinema)", quantity: 124 },
        { trait: "Megaphone", quantity: 124 },
        { trait: "ピース", quantity: 124 },
        { trait: "Crutches", quantity: 123 },
        { trait: "Saxophone", quantity: 123 },
        { trait: "Excellent", quantity: 122 },
        { trait: "Radio", quantity: 122 },
        { trait: "Smartphone (White)", quantity: 120 },
        { trait: "Skateboard", quantity: 119 },
        { trait: "Drums", quantity: 118 },
        { trait: "Ping Pong Paddle", quantity: 118 },
        { trait: "Guitar 02", quantity: 116 },
        { trait: "Bass", quantity: 115 },
        { trait: "Cast", quantity: 115 },
        { trait: "Baseball", quantity: 114 },
        { trait: "Ladder", quantity: 114 },
        { trait: "Laptop (White)", quantity: 111 },
        { trait: "Red Cup", quantity: 109 },
        { trait: "Conga Drum", quantity: 106 },
        { trait: "Podcast", quantity: 101 },
        { trait: "Cooler", quantity: 100 },
        { trait: "Golf Bag", quantity: 98 },
        { trait: "Trumpet", quantity: 95 },
        { trait: "Boom Microphone", quantity: 81 },
        { trait: "Synthesizer 01", quantity: 77 },
        { trait: "Synthesizer 02", quantity: 75 },
        { trait: "Map", quantity: 72 },
        { trait: "Extinguisher", quantity: 71 },
        { trait: "Camera (Film)", quantity: 69 },
        { trait: "Synthesizer 03", quantity: 69 },
        { trait: "Boxing (Red)", quantity: 68 },
        { trait: "Camera (Digital)", quantity: 67 },
        { trait: "Hockey Stick", quantity: 66 },
        { trait: "Boxing (Blue)", quantity: 64 },
        { trait: "Keyboard 01", quantity: 64 },
        { trait: "Tuba", quantity: 64 },
        { trait: "Keyboard 02", quantity: 62 },
        { trait: "Fishing Net", quantity: 61 },
        { trait: "Hamlet", quantity: 39 },
        { trait: "Flower 01", quantity: 36 },
        { trait: "Flower 02", quantity: 32 },
        { trait: "Oxygen Tank", quantity: 26 },
        { trait: "Microdose", quantity: 24 },
        { trait: "Magic Mushroom 02", quantity: 19 },
        { trait: "Magic Mushroom 01", quantity: 17 },
        { trait: "Magic Mushroom 03", quantity: 14 },
        { trait: "Magic Mushroom 04", quantity: 14 },
        { trait: "Fire Ball", quantity: 8 },
    ],
    background: [
        { trait: "Light Pink", quantity: 814 },
        { trait: "OffWhite", quantity: 809 },
        { trait: "Orange", quantity: 791 },
        { trait: "Purple", quantity: 772 },
        { trait: "Dark Grey", quantity: 769 },
        { trait: "Green", quantity: 744 },
        { trait: "Pink", quantity: 737 },
        { trait: "Yellow", quantity: 711 },
        { trait: "Gradient 03", quantity: 710 },
        { trait: "Blue", quantity: 686 },
        { trait: "Gradient 04", quantity: 670 },
        { trait: "Gradient 01", quantity: 615 },
        { trait: "Gradient 02", quantity: 614 },
        { trait: "Sky", quantity: 212 },
        { trait: "Night", quantity: 211 },
        { trait: "Fire", quantity: 70 },
        { trait: "SMPTE Color Bars", quantity: 29 },
        { trait: "Floating", quantity: 20 },
        { trait: "Above Clouds", quantity: 16 },
    ],
    body: [
        { trait: "Med", quantity: 1811 },
        { trait: "Orange", quantity: 1762 },
        { trait: "Tan", quantity: 1670 },
        { trait: "Pale", quantity: 1658 },
        { trait: "Black", quantity: 1584 },
        { trait: "Blue", quantity: 499 },
        { trait: "Purple", quantity: 487 },
        { trait: "Cat", quantity: 44 },
        { trait: "Hidden", quantity: 42 },
        { trait: "Vitiligo Pale", quantity: 41 },
        { trait: "Bored Frog", quantity: 40 },
        { trait: "Vitiligo Orange", quantity: 40 },
        { trait: "Skull", quantity: 36 },
        { trait: "Crustacean", quantity: 33 },
        { trait: "Vitiligo Black", quantity: 32 },
        { trait: "Magic Panda", quantity: 31 },
        { trait: "Vitiligo Med", quantity: 31 },
        { trait: "Devil", quantity: 23 },
        { trait: "Vitiligo Tan", quantity: 18 },
        { trait: "SMPTE Color Bars", quantity: 17 },
        { trait: "Magic Red Panda", quantity: 16 },
        { trait: "Rainbow Power", quantity: 15 },
        { trait: "Magic Ape", quantity: 12 },
        { trait: "Alien", quantity: 9 },
        { trait: "Chimp (Black)", quantity: 7 },
        { trait: "Chimp (Blue)", quantity: 7 },
        { trait: "Chimp (Med)", quantity: 7 },
        { trait: "Chimp (Orange)", quantity: 7 },
        { trait: "Chimp (Pale)", quantity: 7 },
        { trait: "Chimp (Purple)", quantity: 7 },
        { trait: "Chimp (Tan)", quantity: 6 },
        { trait: "Chimp (Vitiligo Tan)", quantity: 1 },
    ],
    companion: [
        { trait: "Cat (Calico)", quantity: 100 },
        { trait: "Dog (Golden Retriever)", quantity: 98 },
        { trait: "Dog (Black Lab)", quantity: 95 },
        { trait: "Dog (Pug)", quantity: 95 },
        { trait: "Dog (Beagle)", quantity: 93 },
        { trait: "Cat (Ragdoll)", quantity: 90 },
        { trait: "Dog (Great Pyrenees)", quantity: 90 },
        { trait: "Cat (Siamese)", quantity: 89 },
        { trait: "Cat (Siberian)", quantity: 89 },
        { trait: "Cat (Maine Coon)", quantity: 88 },
        { trait: "Dog (Shiba Inu)", quantity: 88 },
        { trait: "Dog (Chocolate Lab)", quantity: 87 },
        { trait: "Cat (Bombay)", quantity: 80 },
        { trait: "Dog (Dalmatian)", quantity: 80 },
        { trait: "Cat (Russian Blue)", quantity: 79 },
        { trait: "Dog (Rottweiler)", quantity: 75 },
        { trait: "Cat (Persian)", quantity: 73 },
        { trait: "Dog (Husky)", quantity: 69 },
        { trait: "Animalia", quantity: 44 },
        { trait: "Bird (Eagle)", quantity: 38 },
        { trait: "Bird (Goldfish)", quantity: 36 },
        { trait: "Bird (Blue Parrot)", quantity: 35 },
        { trait: "Bird (Green Parrot)", quantity: 34 },
        { trait: "Bird (Raven)", quantity: 30 },
        { trait: "Bird (Red Parrot)", quantity: 30 },
        { trait: "Bird (Masked Lapwings)", quantity: 26 },
        { trait: "2 Cats", quantity: 25 },
        { trait: "2 Dogs", quantity: 24 },
        { trait: "Caiman", quantity: 23 },
        { trait: "Snake (Green)", quantity: 22 },
        { trait: "Cat + Dog", quantity: 20 },
        { trait: "Bird Owl (Blue)", quantity: 19 },
        { trait: "Bird Owl (Tawny)", quantity: 19 },
        { trait: "Snake (White)", quantity: 19 },
        { trait: "Snake (Blue)", quantity: 18 },
        { trait: "Hamster (Golden)", quantity: 17 },
        { trait: "Hamster (Roborovski)", quantity: 16 },
        { trait: "Rat", quantity: 15 },
        { trait: "Alligator", quantity: 13 },
        { trait: "Frog (Neobatrachia)", quantity: 7 },
        { trait: "Monkey (White)", quantity: 6 },
        { trait: "Monkey (Black)", quantity: 4 },
        { trait: "Monkey (Orange)", quantity: 4 },
        { trait: "Frog (Mantella)", quantity: 3 },
        { trait: "Frog (Tree)", quantity: 3 },
        { trait: "Monkey (Blue)", quantity: 2 },
    ],
    eyes: [
        { trait: "Down", quantity: 410 },
        { trait: "Blush", quantity: 408 },
        { trait: "Hope", quantity: 407 },
        { trait: "Reptilian", quantity: 405 },
        { trait: "Ambiguity", quantity: 399 },
        { trait: "Acid", quantity: 393 },
        { trait: "Yay", quantity: 393 },
        { trait: "Staid", quantity: 391 },
        { trait: "Stunned", quantity: 391 },
        { trait: "XX", quantity: 391 },
        { trait: "Chill", quantity: 387 },
        { trait: "Careful", quantity: 369 },
        { trait: "Natural", quantity: 364 },
        { trait: "Tired", quantity: 363 },
        { trait: "Mean", quantity: 358 },
        { trait: "Monolid", quantity: 343 },
        { trait: "Up", quantity: 341 },
        { trait: "Stoned", quantity: 327 },
        { trait: "Angry", quantity: 298 },
        { trait: "Happy Patch", quantity: 219 },
        { trait: "Supper V", quantity: 193 },
        { trait: "Sad Patch", quantity: 192 },
        { trait: "Designer (White)", quantity: 188 },
        { trait: "Sunglasses", quantity: 188 },
        { trait: "Clout Goggles (Champagne)", quantity: 182 },
        { trait: "Designer (Black)", quantity: 180 },
        { trait: "Dark", quantity: 178 },
        { trait: "Clout Goggles (Black)", quantity: 176 },
        { trait: "XX Sunglasses", quantity: 172 },
        { trait: "Designer (Champagne)", quantity: 163 },
        { trait: "Spectacles A", quantity: 162 },
        { trait: "Spectacles B", quantity: 154 },
        { trait: "Spectacles C", quantity: 139 },
        { trait: "Cat", quantity: 44 },
        { trait: "Bored Frog", quantity: 40 },
        { trait: "Knockout", quantity: 37 },
        { trait: "Skull", quantity: 36 },
        { trait: "Crustacean", quantity: 33 },
        { trait: "Nouns", quantity: 33 },
        { trait: "Magic Panda", quantity: 31 },
        { trait: "Skull Mask", quantity: 30 },
        { trait: "Devil", quantity: 23 },
        { trait: "Magic Red Panda", quantity: 16 },
        { trait: "Magic Ape", quantity: 12 },
        { trait: "Alien", quantity: 9 },
    ],
    mouth: [
        { trait: "Bandit", quantity: 693 },
        { trait: "L", quantity: 689 },
        { trait: "Lucky", quantity: 685 },
        { trait: "Yay", quantity: 661 },
        { trait: "Uncertain", quantity: 631 },
        { trait: "Tolerable", quantity: 622 },
        { trait: "Half Smile", quantity: 617 },
        { trait: "Doubtful", quantity: 615 },
        { trait: "Happiness", quantity: 614 },
        { trait: "Smile", quantity: 613 },
        { trait: "Surprised", quantity: 613 },
        { trait: "Unquiet", quantity: 600 },
        { trait: "Happy", quantity: 596 },
        { trait: "Problematic", quantity: 583 },
        { trait: "Default", quantity: 326 },
        { trait: "Beard", quantity: 83 },
        { trait: "Wise Beard", quantity: 59 },
        { trait: "Blue Beard", quantity: 53 },
        { trait: "Blue Beard (Yay)", quantity: 52 },
        { trait: "Cat", quantity: 44 },
        { trait: "Beard (Doubtful)", quantity: 43 },
        { trait: "Purple Beard", quantity: 42 },
        { trait: "Wise Beard (Unquiet)", quantity: 41 },
        { trait: "Bored Frog", quantity: 40 },
        { trait: "Green Beard (Bandit)", quantity: 40 },
        { trait: "Purple Beard (Smile)", quantity: 37 },
        { trait: "Green Beard", quantity: 36 },
        { trait: "Skull", quantity: 36 },
        { trait: "Crustacean", quantity: 33 },
        { trait: "Magic Panda", quantity: 31 },
        { trait: "Mustache", quantity: 31 },
        { trait: "Skull Mask", quantity: 30 },
        { trait: "Magic Red Panda", quantity: 16 },
        { trait: "Magic Ape", quantity: 12 },
        { trait: "Alien", quantity: 9 },
    ],
    outfit: [
        { trait: "Shark (Black)", quantity: 157 },
        { trait: "Dino (Blue)", quantity: 153 },
        { trait: "Alligator (Yellow)", quantity: 149 },
        { trait: "Shark (Blue)", quantity: 148 },
        { trait: "Deer (Baby)", quantity: 142 },
        { trait: "Cat (Cool)", quantity: 141 },
        { trait: "Crocodile", quantity: 141 },
        { trait: "Leopard Shark", quantity: 136 },
        { trait: "Octopus (Black)", quantity: 136 },
        { trait: "Seal (Spotted)", quantity: 136 },
        { trait: "Dino (Rare)", quantity: 135 },
        { trait: "Bee", quantity: 134 },
        { trait: "Duck (Juvenile Mallard)", quantity: 134 },
        { trait: "Alligator (Green)", quantity: 132 },
        { trait: "Cat (White)", quantity: 131 },
        { trait: "Rabbit (Black & White)", quantity: 130 },
        { trait: "Zebra", quantity: 130 },
        { trait: "Frog (Blue)", quantity: 129 },
        { trait: "Leopard", quantity: 129 },
        { trait: "Puma (Spotted)", quantity: 128 },
        { trait: "Tiger (White)", quantity: 128 },
        { trait: "Lion (East Africa)", quantity: 125 },
        { trait: "Raccoon", quantity: 124 },
        { trait: "Chicken", quantity: 123 },
        { trait: "Giraffe (Western)", quantity: 122 },
        { trait: "Puma", quantity: 121 },
        { trait: "Pig (Sandy & Black)", quantity: 120 },
        { trait: "Rhino (Orange)", quantity: 119 },
        { trait: "Bison", quantity: 118 },
        { trait: "Rabbit (Orange)", quantity: 118 },
        { trait: "Dolphin (Spotted)", quantity: 117 },
        { trait: "Giant Squid (Purple)", quantity: 117 },
        { trait: "Pig (Pink)", quantity: 116 },
        { trait: "Cow", quantity: 115 },
        { trait: "Buffalo (Orange)", quantity: 114 },
        { trait: "Ladybug", quantity: 113 },
        { trait: "Orca", quantity: 113 },
        { trait: "Artic Seal", quantity: 111 },
        { trait: "Seal", quantity: 111 },
        { trait: "Giant Squid (Red)", quantity: 110 },
        { trait: "Octopus (Purple)", quantity: 110 },
        { trait: "Kangaroo", quantity: 109 },
        { trait: "Hammerhead Shark (Black)", quantity: 108 },
        { trait: "Chick", quantity: 107 },
        { trait: "Rabbit (White)", quantity: 107 },
        { trait: "Leopard (Charcoal)", quantity: 106 },
        { trait: "Penguin", quantity: 106 },
        { trait: "Walrus (Pacific)", quantity: 106 },
        { trait: "Gorilla (Mountain)", quantity: 105 },
        { trait: "Tiger (Orange)", quantity: 104 },
        { trait: "Walrus (Atlantic)", quantity: 104 },
        { trait: "Lion (West Africa)", quantity: 103 },
        { trait: "Deer (Black)", quantity: 102 },
        { trait: "Frog (Oophaga)", quantity: 102 },
        { trait: "Dino (Green)", quantity: 101 },
        { trait: "Husky", quantity: 101 },
        { trait: "Rhino (Black)", quantity: 101 },
        { trait: "Toucan (Rainbow)", quantity: 101 },
        { trait: "Bull (Black)", quantity: 100 },
        { trait: "Fox", quantity: 100 },
        { trait: "Frog (Green)", quantity: 100 },
        { trait: "Deer (Brown)", quantity: 98 },
        { trait: "Wolf (Black)", quantity: 98 },
        { trait: "Arctic Wolf", quantity: 96 },
        { trait: "Toucan (Toco)", quantity: 96 },
        { trait: "Red Panda", quantity: 95 },
        { trait: "Rhino (Grey)", quantity: 94 },
        { trait: "Whale", quantity: 92 },
        { trait: "Hammerhead Shark (Blue)", quantity: 91 },
        { trait: "Gorilla (Eastern)", quantity: 89 },
        { trait: "Bull (Red)", quantity: 88 },
        { trait: "Bear (Panda)", quantity: 86 },
        { trait: "Chimp (Black)", quantity: 83 },
        { trait: "Wolf (Blue)", quantity: 83 },
        { trait: "Chimp (White)", quantity: 82 },
        { trait: "Duck (Mallard)", quantity: 81 },
        { trait: "Elephant (Black & Spots)", quantity: 78 },
        { trait: "Gorilla (Cross River)", quantity: 78 },
        { trait: "Bear (Grizzly)", quantity: 77 },
        { trait: "Bear (Polar)", quantity: 76 },
        { trait: "Elephant (Orange)", quantity: 76 },
        { trait: "Horse (Duns)", quantity: 76 },
        { trait: "Chimp (Yellow)", quantity: 74 },
        { trait: "Horse (Polka Dot)", quantity: 74 },
        { trait: "Bear (Black)", quantity: 73 },
        { trait: "Dolphin", quantity: 72 },
        { trait: "Horse (Black)", quantity: 70 },
        { trait: "Hippopotamus (Purple)", quantity: 67 },
        { trait: "Hippopotamus (Black & Spots)", quantity: 66 },
        { trait: "Giraffe (Southern)", quantity: 65 },
        { trait: "Elephant (Purple)", quantity: 61 },
        { trait: "Chimp (Pink)", quantity: 60 },
        { trait: "Hippopotamus (Grey)", quantity: 60 },
        { trait: "Buffalo (Black)", quantity: 51 },
        { trait: "Dog (Spotted)", quantity: 50 },
        { trait: "Dog (Golden)", quantity: 49 },
        { trait: "Maltese Tiger", quantity: 6 },
    ],
}
export default traits 