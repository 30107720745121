// Custom

const accessories =
{
    fire_ball: [
        {
            "number": 2327,
            "image": require("../../images/art/accessories/fire_ball/2327.png"),
        },
        {
            "number": 3868,
            "image": require("../../images/art/accessories/fire_ball/3868.png"),
        },
        {
            "number": 5601,
            "image": require("../../images/art/accessories/fire_ball/5601.png"),
        },
        {
            "number": 6395,
            "image": require("../../images/art/accessories/fire_ball/6395.png"),
        },
        {
            "number": 7132,
            "image": require("../../images/art/accessories/fire_ball/7132.png"),
        },
        {
            "number": 8414,
            "image": require("../../images/art/accessories/fire_ball/8414.png"),
        },
        {
            "number": 9119,
            "image": require("../../images/art/accessories/fire_ball/9119.png"),
        },
        {
            "number": 9264,
            "image": require("../../images/art/accessories/fire_ball/9264.png"),
        },
    ],
    rainbow_power: [
        {
            "number": 222,
            "image": require("../../images/art/accessories/rainbow_power/222.png"),
        },
        {
            "number": 1645,
            "image": require("../../images/art/accessories/rainbow_power/1645.png"),
        },
        {
            "number": 2415,
            "image": require("../../images/art/accessories/rainbow_power/2415.png"),
        },
        {
            "number": 2747,
            "image": require("../../images/art/accessories/rainbow_power/2747.png"),
        },
        {
            "number": 4575,
            "image": require("../../images/art/accessories/rainbow_power/4575.png"),
        },
        {
            "number": 5414,
            "image": require("../../images/art/accessories/rainbow_power/5414.png"),
        },
        {
            "number": 5571,
            "image": require("../../images/art/accessories/rainbow_power/5571.png"),
        },
        {
            "number": 6041,
            "image": require("../../images/art/accessories/rainbow_power/6041.png"),
        },
        {
            "number": 6358,
            "image": require("../../images/art/accessories/rainbow_power/6358.png"),
        },
        {
            "number": 6460,
            "image": require("../../images/art/accessories/rainbow_power/6460.png"),
        },
        {
            "number": 7640,
            "image": require("../../images/art/accessories/rainbow_power/7640.png"),
        },
        {
            "number": 9119,
            "image": require("../../images/art/accessories/rainbow_power/9119.png"),
        },
        {
            "number": 9370,
            "image": require("../../images/art/accessories/rainbow_power/9370.png"),
        },
        {
            "number": 9511,
            "image": require("../../images/art/accessories/rainbow_power/9511.png"),
        },
        {
            "number": 9623,
            "image": require("../../images/art/accessories/rainbow_power/9623.png"),
        },
    ],
    above_clouds: [
        {
            "number": 100,
            "image": require("../../images/art/accessories/above_clouds/100.png"),
        },
        {
            "number": 942,
            "image": require("../../images/art/accessories/above_clouds/942.png"),
        },
        {
            "number": 1950,
            "image": require("../../images/art/accessories/above_clouds/1950.png"),
        },
        {
            "number": 2164,
            "image": require("../../images/art/accessories/above_clouds/2164.png"),
        },
        {
            "number": 3272,
            "image": require("../../images/art/accessories/above_clouds/3272.png"),
        },
        {
            "number": 4025,
            "image": require("../../images/art/accessories/above_clouds/4025.png"),
        },
        {
            "number": 5323,
            "image": require("../../images/art/accessories/above_clouds/5323.png"),
        },
        {
            "number": 5622,
            "image": require("../../images/art/accessories/above_clouds/5622.png"),
        },
        {
            "number": 5802,
            "image": require("../../images/art/accessories/above_clouds/5802.png"),
        },
        {
            "number": 6202,
            "image": require("../../images/art/accessories/above_clouds/6202.png"),
        },
        {
            "number": 6599,
            "image": require("../../images/art/accessories/above_clouds/6599.png"),
        },
        {
            "number": 6684,
            "image": require("../../images/art/accessories/above_clouds/6684.png"),
        },
        {
            "number": 6920,
            "image": require("../../images/art/accessories/above_clouds/6920.png"),
        },
        {
            "number": 7142,
            "image": require("../../images/art/accessories/above_clouds/7142.png"),
        },
        {
            "number": 7610,
            "image": require("../../images/art/accessories/above_clouds/7610.png"),
        },
        {
            "number": 9706,
            "image": require("../../images/art/accessories/above_clouds/9706.png"),
        },
    ],
    smpte_color_bars: [
        {
            "number": 339,
            "image": require("../../images/art/accessories/smpte_color_bars/339.png"),
        },
        {
            "number": 372,
            "image": require("../../images/art/accessories/smpte_color_bars/372.png"),
        },
        {
            "number": 512,
            "image": require("../../images/art/accessories/smpte_color_bars/512.png"),
        },
        {
            "number": 763,
            "image": require("../../images/art/accessories/smpte_color_bars/763.png"),
        },
        {
            "number": 1475,
            "image": require("../../images/art/accessories/smpte_color_bars/1475.png"),
        },
        {
            "number": 1527,
            "image": require("../../images/art/accessories/smpte_color_bars/1527.png"),
        },
        {
            "number": 3415,
            "image": require("../../images/art/accessories/smpte_color_bars/3415.png"),
        },
        {
            "number": 3552,
            "image": require("../../images/art/accessories/smpte_color_bars/3552.png"),
        },
        {
            "number": 3838,
            "image": require("../../images/art/accessories/smpte_color_bars/3838.png"),
        },
        {
            "number": 4376,
            "image": require("../../images/art/accessories/smpte_color_bars/4376.png"),
        },
        {
            "number": 4846,
            "image": require("../../images/art/accessories/smpte_color_bars/4846.png"),
        },
        {
            "number": 5370,
            "image": require("../../images/art/accessories/smpte_color_bars/5370.png"),
        },
        {
            "number": 6553,
            "image": require("../../images/art/accessories/smpte_color_bars/6553.png"),
        },
        {
            "number": 7419,
            "image": require("../../images/art/accessories/smpte_color_bars/7419.png"),
        },
        {
            "number": 7937,
            "image": require("../../images/art/accessories/smpte_color_bars/7937.png"),
        },
        {
            "number": 8365,
            "image": require("../../images/art/accessories/smpte_color_bars/8365.png"),
        },
        {
            "number": 8653,
            "image": require("../../images/art/accessories/smpte_color_bars/8653.png"),
        },
    ],
    floating: [
        {
            "number": 311,
            "image": require("../../images/art/accessories/floating/311.png"),
        },
        {
            "number": 458,
            "image": require("../../images/art/accessories/floating/458.png"),
        },
        {
            "number": 579,
            "image": require("../../images/art/accessories/floating/579.png"),
        },
        {
            "number": 1639,
            "image": require("../../images/art/accessories/floating/1639.png"),
        },
        {
            "number": 1695,
            "image": require("../../images/art/accessories/floating/1695.png"),
        },
        {
            "number": 2699,
            "image": require("../../images/art/accessories/floating/2699.png"),
        },
        {
            "number": 2843,
            "image": require("../../images/art/accessories/floating/2843.png"),
        },
        {
            "number": 3534,
            "image": require("../../images/art/accessories/floating/3534.png"),
        },
        {
            "number": 3693,
            "image": require("../../images/art/accessories/floating/3693.png"),
        },
        {
            "number": 4744,
            "image": require("../../images/art/accessories/floating/4744.png"),
        },
        {
            "number": 5017,
            "image": require("../../images/art/accessories/floating/5017.png"),
        },
        {
            "number": 5441,
            "image": require("../../images/art/accessories/floating/5441.png"),
        },
        {
            "number": 5981,
            "image": require("../../images/art/accessories/floating/5981.png"),
        },
        {
            "number": 6513,
            "image": require("../../images/art/accessories/floating/6513.png"),
        },
        {
            "number": 7911,
            "image": require("../../images/art/accessories/floating/7911.png"),
        },
        {
            "number": 8206,
            "image": require("../../images/art/accessories/floating/8206.png"),
        },
        {
            "number": 8944,
            "image": require("../../images/art/accessories/floating/8944.png"),
        },
        {
            "number": 9346,
            "image": require("../../images/art/accessories/floating/9346.png"),
        },
        {
            "number": 9489,
            "image": require("../../images/art/accessories/floating/9489.png"),
        },
        {
            "number": 9607,
            "image": require("../../images/art/accessories/floating/9607.png"),
        },
    ],
    microdose: [
        {
            "number": 246,
            "image": require("../../images/art/accessories/microdose/246.png"),
        },
        {
            "number": 254,
            "image": require("../../images/art/accessories/microdose/254.png"),
        },
        {
            "number": 1245,
            "image": require("../../images/art/accessories/microdose/1245.png"),
        },
        {
            "number": 1685,
            "image": require("../../images/art/accessories/microdose/1685.png"),
        },
        {
            "number": 2802,
            "image": require("../../images/art/accessories/microdose/2802.png"),
        },
        {
            "number": 3417,
            "image": require("../../images/art/accessories/microdose/3417.png"),
        },
        {
            "number": 3640,
            "image": require("../../images/art/accessories/microdose/3640.png"),
        },
        {
            "number": 3684,
            "image": require("../../images/art/accessories/microdose/3684.png"),
        },
        {
            "number": 4521,
            "image": require("../../images/art/accessories/microdose/4521.png"),
        },
        {
            "number": 4888,
            "image": require("../../images/art/accessories/microdose/4888.png"),
        },
        {
            "number": 5357,
            "image": require("../../images/art/accessories/microdose/5357.png"),
        },
        {
            "number": 5431,
            "image": require("../../images/art/accessories/microdose/5431.png"),
        },
        {
            "number": 5594,
            "image": require("../../images/art/accessories/microdose/5594.png"),
        },
        {
            "number": 5600,
            "image": require("../../images/art/accessories/microdose/5600.png"),
        },
        {
            "number": 6158,
            "image": require("../../images/art/accessories/microdose/6158.png"),
        },
        {
            "number": 6161,
            "image": require("../../images/art/accessories/microdose/6161.png"),
        },
        {
            "number": 6225,
            "image": require("../../images/art/accessories/microdose/6225.png"),
        },
        {
            "number": 6400,
            "image": require("../../images/art/accessories/microdose/6400.png"),
        },
        {
            "number": 6572,
            "image": require("../../images/art/accessories/microdose/6572.png"),
        },
        {
            "number": 8037,
            "image": require("../../images/art/accessories/microdose/8037.png"),
        },
        {
            "number": 8653,
            "image": require("../../images/art/accessories/microdose/8653.png"),
        },
        {
            "number": 9390,
            "image": require("../../images/art/accessories/microdose/9390.png"),
        },
        {
            "number": 9852,
            "image": require("../../images/art/accessories/microdose/9852.png"),
        },
        {
            "number": 9959,
            "image": require("../../images/art/accessories/microdose/9959.png"),
        },
    ],
    magic_mushroom_01: [
        {
            "number": 99,
            "image": require("../../images/art/accessories/magic_mushroom_01/99.png"),
        },
        {
            "number": 556,
            "image": require("../../images/art/accessories/magic_mushroom_01/556.png"),
        },
        {
            "number": 1299,
            "image": require("../../images/art/accessories/magic_mushroom_01/1299.png"),
        },
        {
            "number": 1469,
            "image": require("../../images/art/accessories/magic_mushroom_01/1469.png"),
        },
        {
            "number": 1628,
            "image": require("../../images/art/accessories/magic_mushroom_01/1628.png"),
        },
        {
            "number": 3215,
            "image": require("../../images/art/accessories/magic_mushroom_01/3215.png"),
        },
        {
            "number": 3481,
            "image": require("../../images/art/accessories/magic_mushroom_01/3481.png"),
        },
        {
            "number": 4242,
            "image": require("../../images/art/accessories/magic_mushroom_01/4242.png"),
        },
        {
            "number": 4532,
            "image": require("../../images/art/accessories/magic_mushroom_01/4532.png"),
        },
        {
            "number": 4973,
            "image": require("../../images/art/accessories/magic_mushroom_01/4973.png"),
        },
        {
            "number": 5395,
            "image": require("../../images/art/accessories/magic_mushroom_01/5395.png"),
        },
        {
            "number": 5399,
            "image": require("../../images/art/accessories/magic_mushroom_01/5399.png"),
        },
        {
            "number": 7079,
            "image": require("../../images/art/accessories/magic_mushroom_01/7079.png"),
        },
        {
            "number": 7521,
            "image": require("../../images/art/accessories/magic_mushroom_01/7521.png"),
        },
        {
            "number": 7702,
            "image": require("../../images/art/accessories/magic_mushroom_01/7702.png"),
        },
        {
            "number": 9025,
            "image": require("../../images/art/accessories/magic_mushroom_01/9025.png"),
        },
    ],
    magic_mushroom_02: [
        {
            "number": 339,
            "image": require("../../images/art/accessories/magic_mushroom_02/339.png"),
        },
        {
            "number": 357,
            "image": require("../../images/art/accessories/magic_mushroom_02/357.png"),
        },
        {
            "number": 565,
            "image": require("../../images/art/accessories/magic_mushroom_02/565.png"),
        },
        {
            "number": 903,
            "image": require("../../images/art/accessories/magic_mushroom_02/903.png"),
        },
        {
            "number": 1912,
            "image": require("../../images/art/accessories/magic_mushroom_02/1912.png"),
        },
        {
            "number": 2133,
            "image": require("../../images/art/accessories/magic_mushroom_02/2133.png"),
        },
        {
            "number": 5010,
            "image": require("../../images/art/accessories/magic_mushroom_02/5010.png"),
        },
        {
            "number": 5290,
            "image": require("../../images/art/accessories/magic_mushroom_02/5290.png"),
        },
        {
            "number": 5697,
            "image": require("../../images/art/accessories/magic_mushroom_02/5697.png"),
        },
        {
            "number": 5726,
            "image": require("../../images/art/accessories/magic_mushroom_02/5726.png"),
        },
        {
            "number": 6512,
            "image": require("../../images/art/accessories/magic_mushroom_02/6512.png"),
        },
        {
            "number": 6614,
            "image": require("../../images/art/accessories/magic_mushroom_02/6614.png"),
        },
        {
            "number": 7640,
            "image": require("../../images/art/accessories/magic_mushroom_02/7640.png"),
        },
        {
            "number": 7728,
            "image": require("../../images/art/accessories/magic_mushroom_02/7728.png"),
        },
        {
            "number": 7828,
            "image": require("../../images/art/accessories/magic_mushroom_02/7828.png"),
        },
        {
            "number": 8488,
            "image": require("../../images/art/accessories/magic_mushroom_02/8488.png"),
        },
        {
            "number": 9158,
            "image": require("../../images/art/accessories/magic_mushroom_02/9158.png"),
        },
        {
            "number": 9453,
            "image": require("../../images/art/accessories/magic_mushroom_02/9453.png"),
        },
        {
            "number": 9562,
            "image": require("../../images/art/accessories/magic_mushroom_02/9562.png"),
        },
    ],
    magic_mushroom_03: [
        {
            "number": 252,
            "image": require("../../images/art/accessories/magic_mushroom_03/252.png"),
        },
        {
            "number": 416,
            "image": require("../../images/art/accessories/magic_mushroom_03/416.png"),
        },
        {
            "number": 1215,
            "image": require("../../images/art/accessories/magic_mushroom_03/1215.png"),
        },
        {
            "number": 1369,
            "image": require("../../images/art/accessories/magic_mushroom_03/1369.png"),
        },
        {
            "number": 1691,
            "image": require("../../images/art/accessories/magic_mushroom_03/1691.png"),
        },
        {
            "number": 1916,
            "image": require("../../images/art/accessories/magic_mushroom_03/1916.png"),
        },
        {
            "number": 2753,
            "image": require("../../images/art/accessories/magic_mushroom_03/2753.png"),
        },
        {
            "number": 4883,
            "image": require("../../images/art/accessories/magic_mushroom_03/4883.png"),
        },
        {
            "number": 5541,
            "image": require("../../images/art/accessories/magic_mushroom_03/5541.png"),
        },
        {
            "number": 6917,
            "image": require("../../images/art/accessories/magic_mushroom_03/6917.png"),
        },
        {
            "number": 7127,
            "image": require("../../images/art/accessories/magic_mushroom_03/7127.png"),
        },
        {
            "number": 7558,
            "image": require("../../images/art/accessories/magic_mushroom_03/7558.png"),
        },
        {
            "number": 8892,
            "image": require("../../images/art/accessories/magic_mushroom_03/8892.png"),
        },
        {
            "number": 9668,
            "image": require("../../images/art/accessories/magic_mushroom_03/9668.png"),
        }
    ],
    magic_mushroom_04: [
        {
            "number": 1250,
            "image": require("../../images/art/accessories/magic_mushroom_04/1250.png"),
        },
        {
            "number": 3218,
            "image": require("../../images/art/accessories/magic_mushroom_04/3218.png"),
        },
        {
            "number": 3685,
            "image": require("../../images/art/accessories/magic_mushroom_04/3685.png"),
        },
        {
            "number": 3924,
            "image": require("../../images/art/accessories/magic_mushroom_04/3924.png"),
        },
        {
            "number": 5432,
            "image": require("../../images/art/accessories/magic_mushroom_04/5432.png"),
        },
        {
            "number": 6563,
            "image": require("../../images/art/accessories/magic_mushroom_04/6563.png"),
        },
        {
            "number": 6682,
            "image": require("../../images/art/accessories/magic_mushroom_04/6682.png"),
        },
        {
            "number": 6879,
            "image": require("../../images/art/accessories/magic_mushroom_04/6879.png"),
        },
        {
            "number": 7954,
            "image": require("../../images/art/accessories/magic_mushroom_04/7954.png"),
        },
        {
            "number": 7991,
            "image": require("../../images/art/accessories/magic_mushroom_04/7991.png"),
        },
        {
            "number": 8560,
            "image": require("../../images/art/accessories/magic_mushroom_04/8560.png"),
        },
        {
            "number": 8911,
            "image": require("../../images/art/accessories/magic_mushroom_04/8911.png"),
        },
        {
            "number": 9278,
            "image": require("../../images/art/accessories/magic_mushroom_04/9278.png"),
        },
        {
            "number": 9488,
            "image": require("../../images/art/accessories/magic_mushroom_04/9488.png"),
        }
    ],
    oxygen_tank: [
        {
            "number": 258,
            "image": require("../../images/art/accessories/oxygen_tank/258.png"),
        },
        {
            "number": 515,
            "image": require("../../images/art/accessories/oxygen_tank/515.png"),
        },
        {
            "number": 665,
            "image": require("../../images/art/accessories/oxygen_tank/665.png"),
        },
        {
            "number": 1146,
            "image": require("../../images/art/accessories/oxygen_tank/1146.png"),
        },
        {
            "number": 1370,
            "image": require("../../images/art/accessories/oxygen_tank/1370.png"),
        },
        {
            "number": 1865,
            "image": require("../../images/art/accessories/oxygen_tank/1865.png"),
        },
        {
            "number": 2457,
            "image": require("../../images/art/accessories/oxygen_tank/2457.png"),
        },
        {
            "number": 2682,
            "image": require("../../images/art/accessories/oxygen_tank/2682.png"),
        },
        {
            "number": 3558,
            "image": require("../../images/art/accessories/oxygen_tank/3558.png"),
        },
        {
            "number": 3973,
            "image": require("../../images/art/accessories/oxygen_tank/3973.png"),
        },
        {
            "number": 4245,
            "image": require("../../images/art/accessories/oxygen_tank/4245.png"),
        },
        {
            "number": 4282,
            "image": require("../../images/art/accessories/oxygen_tank/4282.png"),
        },
        {
            "number": 4387,
            "image": require("../../images/art/accessories/oxygen_tank/4387.png"),
        },
        {
            "number": 4927,
            "image": require("../../images/art/accessories/oxygen_tank/4927.png"),
        },
        {
            "number": 5043,
            "image": require("../../images/art/accessories/oxygen_tank/5043.png"),
        },
        {
            "number": 5188,
            "image": require("../../images/art/accessories/oxygen_tank/5188.png"),
        },
        {
            "number": 5589,
            "image": require("../../images/art/accessories/oxygen_tank/5589.png"),
        },
        {
            "number": 5593,
            "image": require("../../images/art/accessories/oxygen_tank/5593.png"),
        },
        {
            "number": 5747,
            "image": require("../../images/art/accessories/oxygen_tank/5747.png"),
        },
        {
            "number": 5862,
            "image": require("../../images/art/accessories/oxygen_tank/5862.png"),
        },
        {
            "number": 6005,
            "image": require("../../images/art/accessories/oxygen_tank/6005.png"),
        },
        {
            "number": 6052,
            "image": require("../../images/art/accessories/oxygen_tank/6052.png"),
        },
        {
            "number": 7078,
            "image": require("../../images/art/accessories/oxygen_tank/7078.png"),
        },
        {
            "number": 7555,
            "image": require("../../images/art/accessories/oxygen_tank/7555.png"),
        },
        {
            "number": 8747,
            "image": require("../../images/art/accessories/oxygen_tank/8747.png"),
        },
        {
            "number": 8887,
            "image": require("../../images/art/accessories/oxygen_tank/8887.png"),
        },
    ],
    skull_mask: [
        {
            "number": 127,
            "image": require("../../images/art/accessories/skull_mask/127.png"),
        },
        {
            "number": 246,
            "image": require("../../images/art/accessories/skull_mask/246.png"),
        },
        {
            "number": 374,
            "image": require("../../images/art/accessories/skull_mask/374.png"),
        },
        {
            "number": 1052,
            "image": require("../../images/art/accessories/skull_mask/1052.png"),
        },
        {
            "number": 1500,
            "image": require("../../images/art/accessories/skull_mask/1500.png"),
        },
        {
            "number": 1863,
            "image": require("../../images/art/accessories/skull_mask/1863.png"),
        },
        {
            "number": 2027,
            "image": require("../../images/art/accessories/skull_mask/2027.png"),
        },
        {
            "number": 2338,
            "image": require("../../images/art/accessories/skull_mask/2338.png"),
        },
        {
            "number": 2533,
            "image": require("../../images/art/accessories/skull_mask/2533.png"),
        },
        {
            "number": 3203,
            "image": require("../../images/art/accessories/skull_mask/3203.png"),
        },
        {
            "number": 3430,
            "image": require("../../images/art/accessories/skull_mask/3430.png"),
        },
        {
            "number": 4210,
            "image": require("../../images/art/accessories/skull_mask/4210.png"),
        },
        {
            "number": 4424,
            "image": require("../../images/art/accessories/skull_mask/4424.png"),
        },
        {
            "number": 5172,
            "image": require("../../images/art/accessories/skull_mask/5172.png"),
        },
        {
            "number": 5346,
            "image": require("../../images/art/accessories/skull_mask/5346.png"),
        },
        {
            "number": 5382,
            "image": require("../../images/art/accessories/skull_mask/5382.png"),
        },
        {
            "number": 5669,
            "image": require("../../images/art/accessories/skull_mask/5669.png"),
        },
        {
            "number": 5850,
            "image": require("../../images/art/accessories/skull_mask/5850.png"),
        },
        {
            "number": 6332,
            "image": require("../../images/art/accessories/skull_mask/6332.png"),
        },
        {
            "number": 6378,
            "image": require("../../images/art/accessories/skull_mask/6378.png"),
        },
        {
            "number": 6612,
            "image": require("../../images/art/accessories/skull_mask/6612.png"),
        },
        {
            "number": 6730,
            "image": require("../../images/art/accessories/skull_mask/6730.png"),
        },
        {
            "number": 6978,
            "image": require("../../images/art/accessories/skull_mask/6978.png"),
        },
        {
            "number": 7232,
            "image": require("../../images/art/accessories/skull_mask/7232.png"),
        },
        {
            "number": 7313,
            "image": require("../../images/art/accessories/skull_mask/7313.png"),
        },
        {
            "number": 7445,
            "image": require("../../images/art/accessories/skull_mask/7445.png"),
        },
        {
            "number": 8326,
            "image": require("../../images/art/accessories/skull_mask/8326.png"),
        },
        {
            "number": 8376,
            "image": require("../../images/art/accessories/skull_mask/8376.png"),
        },
        {
            "number": 9435,
            "image": require("../../images/art/accessories/skull_mask/9435.png"),
        },
        {
            "number": 9852,
            "image": require("../../images/art/accessories/skull_mask/9852.png"),
        },
    ],
    flower_01: [
        {
            "number": 2,
            "image": require("../../images/art/accessories/flower_01/2.png"),
        },
        {
            "number": 491,
            "image": require("../../images/art/accessories/flower_01/491.png"),
        },
        {
            "number": 737,
            "image": require("../../images/art/accessories/flower_01/737.png"),
        },
        {
            "number": 854,
            "image": require("../../images/art/accessories/flower_01/854.png"),
        },
        {
            "number": 1606,
            "image": require("../../images/art/accessories/flower_01/1606.png"),
        },
        {
            "number": 1689,
            "image": require("../../images/art/accessories/flower_01/1689.png"),
        },
        {
            "number": 1782,
            "image": require("../../images/art/accessories/flower_01/1782.png"),
        },
        {
            "number": 2191,
            "image": require("../../images/art/accessories/flower_01/2191.png"),
        },
        {
            "number": 2336,
            "image": require("../../images/art/accessories/flower_01/2336.png"),
        },
        {
            "number": 3026,
            "image": require("../../images/art/accessories/flower_01/3026.png"),
        },
        {
            "number": 3181,
            "image": require("../../images/art/accessories/flower_01/3181.png"),
        },
        {
            "number": 3383,
            "image": require("../../images/art/accessories/flower_01/3383.png"),
        },
        {
            "number": 3760,
            "image": require("../../images/art/accessories/flower_01/3760.png"),
        },
        {
            "number": 3805,
            "image": require("../../images/art/accessories/flower_01/3805.png"),
        },
        {
            "number": 4139,
            "image": require("../../images/art/accessories/flower_01/4139.png"),
        },
        {
            "number": 4220,
            "image": require("../../images/art/accessories/flower_01/4220.png"),
        },
        {
            "number": 4424,
            "image": require("../../images/art/accessories/flower_01/4424.png"),
        },
        {
            "number": 4736,
            "image": require("../../images/art/accessories/flower_01/4736.png"),
        },
        {
            "number": 5014,
            "image": require("../../images/art/accessories/flower_01/5014.png"),
        },
        {
            "number": 5324,
            "image": require("../../images/art/accessories/flower_01/5324.png"),
        },
        {
            "number": 5664,
            "image": require("../../images/art/accessories/flower_01/5664.png"),
        },
        {
            "number": 5824,
            "image": require("../../images/art/accessories/flower_01/5824.png"),
        },
        {
            "number": 5916,
            "image": require("../../images/art/accessories/flower_01/5916.png"),
        },
        {
            "number": 5943,
            "image": require("../../images/art/accessories/flower_01/5943.png"),
        },
        {
            "number": 6089,
            "image": require("../../images/art/accessories/flower_01/6089.png"),
        },
        {
            "number": 6510,
            "image": require("../../images/art/accessories/flower_01/6510.png"),
        },
        {
            "number": 6890,
            "image": require("../../images/art/accessories/flower_01/6890.png"),
        },
        {
            "number": 7097,
            "image": require("../../images/art/accessories/flower_01/7097.png"),
        },
        {
            "number": 7126,
            "image": require("../../images/art/accessories/flower_01/7126.png"),
        },
        {
            "number": 7186,
            "image": require("../../images/art/accessories/flower_01/7186.png"),
        },
        {
            "number": 7321,
            "image": require("../../images/art/accessories/flower_01/7321.png"),
        },
        {
            "number": 8827,
            "image": require("../../images/art/accessories/flower_01/8827.png"),
        },
        {
            "number": 9011,
            "image": require("../../images/art/accessories/flower_01/9011.png"),
        },
        {
            "number": 9428,
            "image": require("../../images/art/accessories/flower_01/9428.png"),
        },
        {
            "number": 9633,
            "image": require("../../images/art/accessories/flower_01/9633.png"),
        },
        {
            "number": 9747,
            "image": require("../../images/art/accessories/flower_01/9747.png"),
        },
    ],
    flower_02: [
        {
            "number": 369,
            "image": require("../../images/art/accessories/flower_02/369.png"),
        },
        {
            "number": 757,
            "image": require("../../images/art/accessories/flower_02/757.png"),
        },
        {
            "number": 787,
            "image": require("../../images/art/accessories/flower_02/787.png"),
        },
        {
            "number": 841,
            "image": require("../../images/art/accessories/flower_02/841.png"),
        },
        {
            "number": 998,
            "image": require("../../images/art/accessories/flower_02/998.png"),
        },
        {
            "number": 1319,
            "image": require("../../images/art/accessories/flower_02/1319.png"),
        },
        {
            "number": 1364,
            "image": require("../../images/art/accessories/flower_02/1364.png"),
        },
        {
            "number": 1524,
            "image": require("../../images/art/accessories/flower_02/1524.png"),
        },
        {
            "number": 1630,
            "image": require("../../images/art/accessories/flower_02/1630.png"),
        },
        {
            "number": 2252,
            "image": require("../../images/art/accessories/flower_02/2252.png"),
        },
        {
            "number": 2365,
            "image": require("../../images/art/accessories/flower_02/2365.png"),
        },
        {
            "number": 2647,
            "image": require("../../images/art/accessories/flower_02/2647.png"),
        },
        {
            "number": 2705,
            "image": require("../../images/art/accessories/flower_02/2705.png"),
        },
        {
            "number": 2729,
            "image": require("../../images/art/accessories/flower_02/2729.png"),
        },
        {
            "number": 2940,
            "image": require("../../images/art/accessories/flower_02/2940.png"),
        },
        {
            "number": 2972,
            "image": require("../../images/art/accessories/flower_02/2972.png"),
        },
        {
            "number": 3046,
            "image": require("../../images/art/accessories/flower_02/3046.png"),
        },
        {
            "number": 3110,
            "image": require("../../images/art/accessories/flower_02/3110.png"),
        },
        {
            "number": 3150,
            "image": require("../../images/art/accessories/flower_02/3150.png"),
        },
        {
            "number": 3371,
            "image": require("../../images/art/accessories/flower_02/3371.png"),
        },
        {
            "number": 4076,
            "image": require("../../images/art/accessories/flower_02/4076.png"),
        },
        {
            "number": 4368,
            "image": require("../../images/art/accessories/flower_02/4368.png"),
        },
        {
            "number": 5021,
            "image": require("../../images/art/accessories/flower_02/5021.png"),
        },
        {
            "number": 5636,
            "image": require("../../images/art/accessories/flower_02/5636.png"),
        },
        {
            "number": 6142,
            "image": require("../../images/art/accessories/flower_02/6142.png"),
        },
        {
            "number": 6506,
            "image": require("../../images/art/accessories/flower_02/6506.png"),
        },
        {
            "number": 6557,
            "image": require("../../images/art/accessories/flower_02/6557.png"),
        },
        {
            "number": 6949,
            "image": require("../../images/art/accessories/flower_02/6949.png"),
        },
        {
            "number": 7294,
            "image": require("../../images/art/accessories/flower_02/7294.png"),
        },
        {
            "number": 7800,
            "image": require("../../images/art/accessories/flower_02/7800.png"),
        },
        {
            "number": 9190,
            "image": require("../../images/art/accessories/flower_02/9190.png"),
        },
        {
            "number": 9771,
            "image": require("../../images/art/accessories/flower_02/9771.png"),
        },
    ],
    nouns: [
        {
            "number": 347,
            "image": require("../../images/art/accessories/nouns/347.png"),
        },
        {
            "number": 542,
            "image": require("../../images/art/accessories/nouns/542.png"),
        },
        {
            "number": 796,
            "image": require("../../images/art/accessories/nouns/796.png"),
        },
        {
            "number": 1122,
            "image": require("../../images/art/accessories/nouns/1122.png"),
        },
        {
            "number": 1660,
            "image": require("../../images/art/accessories/nouns/1660.png"),
        },
        {
            "number": 1904,
            "image": require("../../images/art/accessories/nouns/1904.png"),
        },
        {
            "number": 2047,
            "image": require("../../images/art/accessories/nouns/2047.png"),
        },
        {
            "number": 2749,
            "image": require("../../images/art/accessories/nouns/2749.png"),
        },
        {
            "number": 3207,
            "image": require("../../images/art/accessories/nouns/3207.png"),
        },
        {
            "number": 3555,
            "image": require("../../images/art/accessories/nouns/3555.png"),
        },
        {
            "number": 3862,
            "image": require("../../images/art/accessories/nouns/3862.png"),
        },
        {
            "number": 4235,
            "image": require("../../images/art/accessories/nouns/4235.png"),
        },
        {
            "number": 4456,
            "image": require("../../images/art/accessories/nouns/4456.png"),
        },
        {
            "number": 4630,
            "image": require("../../images/art/accessories/nouns/4630.png"),
        },
        {
            "number": 4882,
            "image": require("../../images/art/accessories/nouns/4882.png"),
        },
        {
            "number": 5181,
            "image": require("../../images/art/accessories/nouns/5181.png"),
        },
        {
            "number": 5421,
            "image": require("../../images/art/accessories/nouns/5421.png"),
        },
        {
            "number": 5578,
            "image": require("../../images/art/accessories/nouns/5578.png"),
        },
        {
            "number": 5678,
            "image": require("../../images/art/accessories/nouns/5678.png"),
        },
        {
            "number": 6157,
            "image": require("../../images/art/accessories/nouns/6157.png"),
        },
        {
            "number": 6463,
            "image": require("../../images/art/accessories/nouns/6463.png"),
        },
        {
            "number": 6559,
            "image": require("../../images/art/accessories/nouns/6559.png"),
        },
        {
            "number": 6613,
            "image": require("../../images/art/accessories/nouns/6613.png"),
        },
        {
            "number": 7511,
            "image": require("../../images/art/accessories/nouns/7511.png"),
        },
        {
            "number": 7702,
            "image": require("../../images/art/accessories/nouns/7702.png"),
        },
        {
            "number": 7953,
            "image": require("../../images/art/accessories/nouns/7953.png"),
        },
        {
            "number": 8356,
            "image": require("../../images/art/accessories/nouns/8356.png"),
        },
        {
            "number": 8412,
            "image": require("../../images/art/accessories/nouns/8412.png"),
        },
        {
            "number": 8864,
            "image": require("../../images/art/accessories/nouns/8864.png"),
        },
        {
            "number": 9237,
            "image": require("../../images/art/accessories/nouns/9237.png"),
        },
        {
            "number": 9520,
            "image": require("../../images/art/accessories/nouns/9520.png"),
        },
        {
            "number": 9644,
            "image": require("../../images/art/accessories/nouns/9644.png"),
        },
        {
            "number": 9907,
            "image": require("../../images/art/accessories/nouns/9907.png"),
        },
    ],
    hamlet: [
        {
            "number": 22,
            "image": require("../../images/art/accessories/hamlet/22.png"),
        },
        {
            "number": 372,
            "image": require("../../images/art/accessories/hamlet/372.png"),
        },
        {
            "number": 412,
            "image": require("../../images/art/accessories/hamlet/412.png"),
        },
        {
            "number": 892,
            "image": require("../../images/art/accessories/hamlet/892.png"),
        },
        {
            "number": 1391,
            "image": require("../../images/art/accessories/hamlet/1391.png"),
        },
        {
            "number": 2070,
            "image": require("../../images/art/accessories/hamlet/2070.png"),
        },
        {
            "number": 2092,
            "image": require("../../images/art/accessories/hamlet/2092.png"),
        },
        {
            "number": 2154,
            "image": require("../../images/art/accessories/hamlet/2154.png"),
        },
        {
            "number": 2639,
            "image": require("../../images/art/accessories/hamlet/2639.png"),
        },
        {
            "number": 2640,
            "image": require("../../images/art/accessories/hamlet/2640.png"),
        },
        {
            "number": 3102,
            "image": require("../../images/art/accessories/hamlet/3102.png"),
        },
        {
            "number": 3237,
            "image": require("../../images/art/accessories/hamlet/3237.png"),
        },
        {
            "number": 3494,
            "image": require("../../images/art/accessories/hamlet/3494.png"),
        },
        {
            "number": 3862,
            "image": require("../../images/art/accessories/hamlet/3862.png"),
        },
        {
            "number": 4633,
            "image": require("../../images/art/accessories/hamlet/4633.png"),
        },
        {
            "number": 4712,
            "image": require("../../images/art/accessories/hamlet/4712.png"),
        },
        {
            "number": 4903,
            "image": require("../../images/art/accessories/hamlet/4903.png"),
        },
        {
            "number": 5305,
            "image": require("../../images/art/accessories/hamlet/5305.png"),
        },
        {
            "number": 5370,
            "image": require("../../images/art/accessories/hamlet/5370.png"),
        },
        {
            "number": 5398,
            "image": require("../../images/art/accessories/hamlet/5398.png"),
        },
        {
            "number": 5543,
            "image": require("../../images/art/accessories/hamlet/5543.png"),
        },
        {
            "number": 5554,
            "image": require("../../images/art/accessories/hamlet/5554.png"),
        },
        {
            "number": 6187,
            "image": require("../../images/art/accessories/hamlet/6187.png"),
        },
        {
            "number": 6193,
            "image": require("../../images/art/accessories/hamlet/6193.png"),
        },
        {
            "number": 6332,
            "image": require("../../images/art/accessories/hamlet/6332.png"),
        },
        {
            "number": 6413,
            "image": require("../../images/art/accessories/hamlet/6413.png"),
        },
        {
            "number": 6486,
            "image": require("../../images/art/accessories/hamlet/6486.png"),
        },
        {
            "number": 6691,
            "image": require("../../images/art/accessories/hamlet/6691.png"),
        },
        {
            "number": 7103,
            "image": require("../../images/art/accessories/hamlet/7103.png"),
        },
        {
            "number": 8326,
            "image": require("../../images/art/accessories/hamlet/8326.png"),
        },
        {
            "number": 8432,
            "image": require("../../images/art/accessories/hamlet/8432.png"),
        },
        {
            "number": 8864,
            "image": require("../../images/art/accessories/hamlet/8864.png"),
        },
        {
            "number": 9239,
            "image": require("../../images/art/accessories/hamlet/9239.png"),
        },
        {
            "number": 9334,
            "image": require("../../images/art/accessories/hamlet/9334.png"),
        },
        {
            "number": 9449,
            "image": require("../../images/art/accessories/hamlet/9449.png"),
        },
        {
            "number": 9487,
            "image": require("../../images/art/accessories/hamlet/9487.png"),
        },
        {
            "number": 9561,
            "image": require("../../images/art/accessories/hamlet/9561.png"),
        },
        {
            "number": 9623,
            "image": require("../../images/art/accessories/hamlet/9623.png"),
        },
        {
            "number": 9645,
            "image": require("../../images/art/accessories/hamlet/9645.png"),
        },
    ],
}



export default accessories 