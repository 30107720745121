import React, { useEffect } from "react";

// Components
import Header from "./../Header";
import Footer from "./../Footer";

// Images & Icons
import Box from "../../images/art/yay.gif";

function ArtPage() {
    const audio = "https://firebasestorage.googleapis.com/v0/b/yaypegsnft.appspot.com/o/nyc.ogg?alt=media&token=a825b727-5228-47c1-a84b-242eb5811271";

    useEffect(() => {
        window.scrollTo(0, 0, 'instant')
    }, [])

    return (
        <div className="container-fluid pt-header pb-tabbar">
            <Header />
            <div className="row m-0">
                <div className="col-12 h-center text-center">
                    <div className="h-vertical">
                        <div className="art-gif">
                            <div className="py-5">
                                <img src={Box} className="section-box" alt="Box" />
                                <div className="box-text-left ">
                                    <div className="p color-text-secoundary pt-2 m-0">Yaypegs</div>
                                    {/* <div className="h5">Yaypegs #TBD</div> */}
                                    <div className="h5">+300 Traits</div>

                                    <div className="pt-2 d-none d-md-block">
                                        <audio controls loop autoPlay controlsList="nodownload noplaybackrate">
                                            <source src={audio} type="audio/ogg" />
                                            <div className="h5"> Your browser does not support the audio element.</div>
                                        </audio>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fixed-bottom">
                <Footer />
            </div>
        </div>
    );
}

export default ArtPage;