import { useEffect } from "react";

// Components
import Header from "./Header";
import Footer from "./Footer";

// Images & Icons
import Isotye from "../images/brand/isotype-w.svg";

function ProvenancePage() {
  // var provenanceRecord = [
  //     { startingIndex: 1, token: "????", hash: '????????????????????????????????????????????????????????????????', link: '/' },
  //     { startingIndex: 2, token: "????", hash: '????????????????????????????????????????????????????????????????', link: '/' },
  //     { startingIndex: 3, token: "????", hash: '????????????????????????????????????????????????????????????????', link: '/' },
  //     { startingIndex: 4, token: "????", hash: '????????????????????????????????????????????????????????????????', link: '/' },
  //     { startingIndex: 5, token: "????", hash: '????????????????????????????????????????????????????????????????', link: '/' },
  //     { startingIndex: '...', token: "....", hash: '...', link: '/' },
  // ];

  useEffect(() => {
    window.scrollTo(0, 0, "instant");
  }, []);

  return (
    <div className="container-fluid pt-header pb-tabbar">
      <Header bgColor={false} />

      <div className="row pt-0 m-0 pb-5">
        <div className="col-12 pt-5">
          <div className="text-center py-5">
            <h1 className="uppercase mb-2">Provenance</h1>
            <div className="uppercase pb-3 p">Immutable Records.</div>
            <div className="py-3">
              <img src={Isotye} className="isotype" alt="Isotype" />
            </div>
            <div className="terms pt-3">
              {/* <h2 className="uppercase pb-2">Warning</h2> */}
              {/* <p className="pb-3">Provenance Record is defined minutes before the reveal according to the methodology outlined below.</p> */}
              <h2 className="uppercase pb-2">Intro</h2>
              <p>
                Below you will find a record of origin or ownership of a work of
                art, used as a guide to authenticity.
              </p>
              <p>
                This page is a faithful record of representing each Yaypegs and
                their respective provenance record. This is a way in which
                Developers can prove that the order of the Token Image and
                metadata was set pre-mint, and was not manipulated. Also
                generate a fair random number post-mint to start the
                distribution of the NFTs from that number.
              </p>
              <p>
                Yaypegs use the hash functions SHA-256. In order to validate the
                provenance or any image hash, you must use the same hashing
                algorithm.
              </p>

              <h2 className="uppercase pt-5 pb-2">Methodology</h2>
              <p className="pt-3">
                <strong>1. Shuffle:</strong> Once the creation of all the art
                assets is finished, we shuffle all 10,000 Yaypegs metadata
                files.
              </p>
              <p className="pt-1">
                <strong>2. Hash Images:</strong> A hash was generated for each
                image using SHA-256 algorithm.
              </p>
              <p className="pt-1">
                <strong>3. Concatenated:</strong> All the resulting hashes were
                concatenated into a single huge string in the specific order as
                listed below.
              </p>
              <p className="pt-1">
                <strong>4. Hash Concatenated:</strong> The final proof was
                obtained by SHA-256 hashing the concatenated string.
              </p>
              <p className="pt-1">
                <strong>5. Provenance Function:</strong> The final provenance
                record was saved in the smart contract through the provenance
                function.
              </p>
              <p className="pt-1">
                <strong>6. Starting Index:</strong> We use the last 4 digits
                (not including the 0) of the Transaction Hash:{" "}
                <span className="break-word">
                  0xde11db9032df290a23fcbc3bf2ffaa4
                  f7ff2b89608a0d28627fe30ed5cb90aba
                </span>{" "}
                originated from executing the Set Provenance function in the
                contract, resulting in a stratating index of&nbsp;
                <a
                  className="link-text link-text-hash break-word"
                  href={`https://etherscan.io/tx/0xde11db9032df290a23fcbc3bf2ffaa4f7ff2b89608a0d28627fe30ed5cb90aba`}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Twitter"
                >
                  {" "}
                  7359
                </a>
                . So we show that the initial number was not manipulated.
              </p>
              <p className="pt-1">
                <strong>7. New Index:</strong> The start of the collection will
                be moved from the original shuffled metadata of token #1 to the
                new Starting Index #7359.
              </p>

              <h2 className="uppercase pt-5 pb-2">Summary</h2>
              <p className="break-word mb-0">Starting Index -> 7359</p>
              <p className="break-word mb-0 pt-3">
                <a
                  className="link-text link-text-hash break-word"
                  href={`https://etherscan.io/address/0x7fda36c8daEDcc55B73E964c2831D6161eF60a75`}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Twitter"
                >
                  Contract Address
                </a>{" "}
                ->
              </p>
              <div className="break-word hash-xl">
                0x7fda36c8daEDcc55B73E964c2831D6161eF60a75
              </div>

              <p className="break-word mb-0 pt-3">Collection Proof Hash -> </p>
              <div className="break-word hash-xl">
                c9baeaba72444e7a23d095f2abae92fe665cf4845905de2894b31401e0d6b8b2
              </div>

              {/* <h2 className="uppercase pt-5 pb-2">Record</h2>
                            <p>The table below lists the original index, assigned Yaypegs Token ID, image SHA256 Hash and IPFS link of each image.</p> */}
            </div>

            {/* <div className="concatenated-hash text-left pt-3">
                            <table style={{ margin: "0 auto" }} id="customers">
                                <tbody>
                                    <tr>
                                        <th className="h6 p-2">INDEX</th>
                                        <th className="h6 p-2">TOKEN</th>
                                        <th className="h6 p-2 d-none d-md-block m-0">SHA-256 HASH</th>
                                        <th className="h6 p-2">IPFS LINK</th>
                                    </tr>
                                    {provenanceRecord.map(({ startingIndex, token, hash, link }, index) => {
                                        return (
                                            <tr key={index} className={``}>
                                                <th className="px-2"><h6 className="mb-0">{startingIndex}</h6></th>
                                                <th className="px-2"><h6 className="mb-0">{startingIndex + 20}</h6></th>
                                                <th className="px-2 d-none d-md-block"><h6 className="mb-0">{hash}</h6></th>
                                                <th className="px-2"><h6 className="mb-0">

                                                    IPFS - {startingIndex}

                                                </h6></th>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div> */}
            {/* <div className="terms d-md-none pt-5">
                            <p>To see the SHA-256 HASH number, please open the page from an ipad or desktop.</p>
                        </div> */}

            {/* <div className="concatenated-hash pt-5">
                            <h2 className="uppercase pt-5 pb-2">Concatenated Hash String</h2>
                            <h6 className=""></h6>
                        </div> */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ProvenancePage;
