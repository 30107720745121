import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import { useAccount } from "wagmi";
import { useBalance } from "wagmi";
import { useContractRead } from "wagmi";

// Components
import Header from "./Header";
import Footer from "./Footer";
import { wagmiContract } from "./contract";

// Components
import { ConnectButton } from "@rainbow-me/rainbowkit";

// Images & Icons
import IP_Font from "../images/home/ip1.png";
import Blur from "../images/home/blur.png";
import Opensea from "../images/home/opensea.png";
import Download from "../images/tools-gallery/download.svg";
import LoaderImg from "../images/twitter/image-loader.svg";

function IPpage() {
  const bucket = `pixel`;
  const bucket_I = `collection`;
  const bucket_II = `illustration`;

  const [firebase, setFirebase] = useState("");
  const [firebaseI, setFirebaseI] = useState("");
  const [firebaseII, setFirebaseII] = useState("");

  const [isLoaded, setIsLoaded] = useState(false);
  const [ramdom, setRamdom] = useState(0);
  const [NFTnumber, setNFTnumber] = useState(0);

  const { address, isConnecting, isDisconnected } = useAccount();
  const {
    data: dataBalance,
    isError: isErrorBalance,
    isLoading: isLoadingBalance,
  } = useBalance({
    address: address,
  });

  const {
    data: tokensOfOwner,
    isError,
    isLoading,
  } = useContractRead({
    address: wagmiContract.address,
    abi: wagmiContract.abi,
    functionName: "tokensOfOwner",
    chainId: 1,
    args: [address],
  });

  useEffect(() => {
    if (address && tokensOfOwner) {
      setRamdom(Math.floor(Math.random() * tokensOfOwner.length));
      setNFTnumber(Number(tokensOfOwner[ramdom]));
    }
  }, [address, ramdom, tokensOfOwner]);

  useEffect(() => {
    window.scrollTo(0, 0, "instant");
  }, []);

  useEffect(() => {
    async function getImagefromFirebase() {
      if (NFTnumber !== 0) {
        try {
          const response = await fetch(
            `https://firebasestorage.googleapis.com/v0/b/yaypegsnft.appspot.com/o/${bucket}%2F${NFTnumber}.png`
          );
          const responseJson = await response.json();
          setFirebase(responseJson);
        } catch (error) {
          console.error(error);
        }
      }
    }
    getImagefromFirebase();
    return () => {
      getImagefromFirebase = null;
    };
  }, [NFTnumber, bucket, address]);

  useEffect(() => {
    async function getImagefromFirebaseI() {
      if (NFTnumber !== 0) {
        try {
          const response = await fetch(
            `https://firebasestorage.googleapis.com/v0/b/yaypegsnft.appspot.com/o/${bucket_I}%2F${NFTnumber}.gif`
          );
          const responseJson = await response.json();
          setFirebaseI(responseJson);
        } catch (error) {
          console.error(error);
        }
      }
    }
    getImagefromFirebaseI();
    return () => {
      getImagefromFirebaseI = null;
    };
  }, [NFTnumber, bucket_I, address]);

  useEffect(() => {
    async function getImagefromFirebaseII() {
      if (NFTnumber !== 0) {
        try {
          const response = await fetch(
            `https://firebasestorage.googleapis.com/v0/b/yaypegsnft.appspot.com/o/${bucket_II}%2F${NFTnumber}.png`
          );
          const responseJson = await response.json();
          setFirebaseII(responseJson);
        } catch (error) {
          console.error(error);
        }
      }
    }
    getImagefromFirebaseII();
    return () => {
      getImagefromFirebaseII = null;
    };
  }, [NFTnumber, bucket_II, address]);

  function downloadImages() {
    if (NFTnumber !== 0) {
      const xhr = new XMLHttpRequest();
      xhr.open(
        "GET",
        `https://firebasestorage.googleapis.com/v0/b/yaypegsnft.appspot.com/o/${bucket}%2F${NFTnumber}.png?alt=media&firebase=${firebase.downloadTokens}`,
        true
      );
      xhr.responseType = "blob";

      xhr.onload = () => {
        if (xhr.status === 200) {
          const blob = new Blob([xhr.response], { type: "image/png" });
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${NFTnumber}.png`);
          document.body.appendChild(link);
          link.click();
        } else {
          console.error("Error downloading PNG:", xhr.statusText);
        }
      };
      xhr.onerror = () => {
        console.error("Error downloading PNG:", xhr.statusText);
      };
      xhr.send();

      const xhrI = new XMLHttpRequest();
      xhrI.open(
        "GET",
        `https://firebasestorage.googleapis.com/v0/b/yaypegsnft.appspot.com/o/${bucket_I}%2F${NFTnumber}.gif?alt=media&firebase=${firebase.downloadTokens}`,
        true
      );
      xhrI.responseType = "blob";

      xhrI.onload = () => {
        if (xhrI.status === 200) {
          const blob = new Blob([xhrI.response], { type: "image/png" });
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${NFTnumber}(I).gif`);
          document.body.appendChild(link);
          link.click();
        } else {
          console.error("Error downloading PNG sI:", xhrI.statusText);
        }
      };
      xhrI.onerror = () => {
        console.error("Error downloading PNG sI:", xhrI.statusText);
      };
      xhrI.send();

      const xhrII = new XMLHttpRequest();
      xhrII.open(
        "GET",
        `https://firebasestorage.googleapis.com/v0/b/yaypegsnft.appspot.com/o/${bucket_II}%2F${NFTnumber}.png?alt=media&firebase=${firebase.downloadTokens}`,
        true
      );
      xhrII.responseType = "blob";

      xhrII.onload = () => {
        if (xhrII.status === 200) {
          const blob = new Blob([xhrII.response], { type: "image/png" });
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${NFTnumber}(II).png`);
          document.body.appendChild(link);
          link.click();
        } else {
          console.error("Error downloading PNG sII:", xhrII.statusText);
        }
      };
      xhrII.onerror = () => {
        console.error("Error downloading PNG sII:", xhrII.statusText);
      };
      xhrII.send();
    }
  }
  // console.log(`useAccount`, address, isConnecting, isDisconnected);
  // console.log(`useBalance`, dataBalance, isErrorBalance, isLoadingBalance);
  // console.log(`useContractRead`, tokensOfOwner, isError, isLoading);

  return (
    <div className="container-fluid pb-tabbar">
      <Header bgColor={true} />
      {!address || !tokensOfOwner ? (
        <div className="row pt-0 m-0">
          <div className="col-12 p-0">
            <div className="parent-center-2">
              <div className="child-center-2">
                <div className="col-12 p-1">
                  <div className="pill-ip text-center py-4 px-4">
                    <img src={IP_Font} className="stages-img pb-3" alt="IP" />

                    <div style={{ display: "block" }} className="">
                      <span className="pill-color-text">
                        Welcome to IP center.
                        <br />
                        Connect your wallet to have access.
                      </span>
                      &nbsp;
                    </div>
                    <div
                      className="pt-4"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <ConnectButton
                        accountStatus="address"
                        showBalance={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-100">
          <div className="pt-header-pill ip-w">
            <div className="row pt-1 m-0">
              <div className="col-12 p-1">
                <div className="pill py-3">IP</div>
              </div>
              <div className="col-12 p-1">
                <div className="pill-ip py-4 px-4">
                  <div style={{ display: "block" }} className="">
                    <span className="pill-color-text">Wallet:</span>&nbsp;&nbsp;
                    {address ? address.slice(0, 4) : "Null Wallet"}...
                    {address ? address.slice(-4) : "Null Wallet"}
                    &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                    {Number(dataBalance?.formatted).toFixed(5)}{" "}
                    {dataBalance?.symbol}
                  </div>
                  <div style={{ display: "block" }} className="">
                    <span className="pill-color-text">
                      NFT(s) in wallet(s):
                    </span>
                    &nbsp;&nbsp;{tokensOfOwner ? tokensOfOwner.length : `0`}
                    &nbsp;Yaypegs
                  </div>
                </div>
              </div>
              <div className="col-12 p-1">
                {tokensOfOwner.length > 0 ? (
                  <div className="pill-ip py-4 px-4">
                    <div className="row">
                      <div className="col m-1 p-0">
                        <div className="text-center pb-3">OG</div>
                        {!isLoaded && (
                          <div className="pill-ip-img-loading">
                            <img
                              src={LoaderImg}
                              className="loader-img"
                              alt="step 1"
                            />
                          </div>
                        )}
                        <img
                          src={`https://firebasestorage.googleapis.com/v0/b/yaypegsnft.appspot.com/o/${bucket}%2F${NFTnumber}.png?alt=media&firebase=${firebase.downloadTokens}`}
                          className="pill-ip-img"
                          onLoad={() => setIsLoaded(true)}
                          style={{ display: isLoaded ? "block" : "none" }}
                          alt="img"
                        />
                      </div>
                      <div className="col m-1 p-0">
                        <div className="text-center pb-3">S I</div>
                        {!isLoaded && (
                          <div className="pill-ip-img-loading">
                            <img
                              src={LoaderImg}
                              className="loader-img"
                              alt="step 1"
                            />
                          </div>
                        )}
                        <img
                          src={`https://firebasestorage.googleapis.com/v0/b/yaypegsnft.appspot.com/o/${bucket_I}%2F${NFTnumber}.gif?alt=media&firebase=${firebaseI.downloadTokens}`}
                          className="pill-ip-img"
                          style={{ display: isLoaded ? "block" : "none" }}
                          onLoad={() => setIsLoaded(true)}
                          alt="img"
                        />
                        {!isLoaded && <div />}
                      </div>
                      <div className="col m-1 p-0">
                        <div className="text-center pb-3">S II</div>
                        {!isLoaded && (
                          <div className="pill-ip-img-loading">
                            <img
                              src={LoaderImg}
                              className="loader-img"
                              alt="step 1"
                            />
                          </div>
                        )}
                        <img
                          src={`https://firebasestorage.googleapis.com/v0/b/yaypegsnft.appspot.com/o/${bucket_II}%2F${NFTnumber}.png?alt=media&firebase=${firebaseII.downloadTokens}`}
                          className="pill-ip-img"
                          style={{ display: isLoaded ? "block" : "none" }}
                          onLoad={() => setIsLoaded(true)}
                          alt="img"
                        />
                        {!isLoaded && <div />}
                      </div>
                    </div>
                    <div className="pill-center pt-3">
                      <div className="pb-2">
                        #{tokensOfOwner ? NFTnumber : `0000`}{" "}
                      </div>
                      <img
                        src={Download}
                        onClick={() => downloadImages()}
                        className="tweet-icon pb-2"
                        alt="download"
                      />
                    </div>
                  </div>
                ) : (
                  <div className="pill-ip py-4 px-4">
                    <div style={{ display: "block" }} className="">
                      Your wallet:{" "}
                      {address ? address.slice(0, 4) : "Null Wallet"}...
                      {address ? address.slice(-4) : "Null Wallet"} does not
                      have Yaypegs.
                      <br />
                      Connect a new wallet or buy your first yaypegs on Opensea
                      or Blur!
                    </div>

                    <div className="pt-3">
                      <a
                        className=""
                        href={`https://opensea.io/collection/yaypegs`}
                        data-size="large"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="buy on opensea"
                      >
                        <div className="buy-opensea">
                          Buy on &nbsp;
                          <img
                            src={Opensea}
                            className="opensea-img"
                            alt="opensea"
                          />
                        </div>
                      </a>
                    </div>
                    <div className="pt-3">
                      <a
                        className=""
                        href={`https://blur.io/collection/yaypegs`}
                        data-size="large"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="buy on blur"
                      >
                        <div className="buy-blur">
                          Buy on{" "}
                          <img src={Blur} className="blur-img" alt="blur" />
                        </div>
                      </a>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-12 p-1">
                <div className="pill-ip py-4 px-4">
                  NFTs [{tokensOfOwner ? tokensOfOwner.length : `0`}]
                  <div className="row pt-2 px-2">
                    {tokensOfOwner ? (
                      tokensOfOwner.map((number, index) => (
                        <div key={index} className="pill-grid m-1 p-0 pt-2">
                          {!isLoaded && (
                            <div className="pill-ip-img-loading-sm">
                              <img
                                src={LoaderImg}
                                className="loader-img-sm"
                                alt="step 1"
                              />
                            </div>
                          )}
                          <img
                            src={`https://firebasestorage.googleapis.com/v0/b/yaypegsnft.appspot.com/o/${bucket_II}%2F${Number(
                              number
                            )}.png?alt=media&firebase=${
                              firebaseII.downloadTokens
                            }`}
                            className={
                              NFTnumber === Number(number)
                                ? "pill-ip-img-sm pill-active"
                                : "pill-ip-img-sm"
                            }
                            style={{ display: isLoaded ? "block" : "none" }}
                            onLoad={() => setIsLoaded(true)}
                            onClick={() => setNFTnumber(Number(number))}
                            alt="img"
                          />
                          {!isLoaded && <div />}
                        </div>
                      ))
                    ) : (
                      <div>No Yaypegs in this wallet</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-12 p-1">
                <div className="pill py-4 px-4">
                  IP Terms
                  <br />
                  <br />
                  You are the owner of all versions of your Yaypegs, you can
                  make use of intellectual property throughout its different
                  versions.
                  <br />
                  <br />
                  To know more:{" "}
                  <NavLink to={`/terms`} style={{ display: `contents` }}>
                    <span
                      style={{ display: `contents` }}
                      className="color-gold"
                    >
                      -> Terms
                    </span>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
}

export default IPpage;
