import { useEffect } from "react";
import { NavLink } from "react-router-dom";

// Components
import Header from "./Header";
import Footer from "./Footer";

// Images & Icons
import Isotype from "../images/brand/isotype-w.svg";

function LibraryPage() {
  const d = new Date();
  let year = d.getFullYear();

  useEffect(() => {
    window.scrollTo(0, 0, "instant");
  }, []);

  return (
    <div className="container-fluid pt-header pb-tabbar">
      <Header bgColor={true} />
      <div className="row pt-0 m-0 px-3">
        <div className="col-12 px-0">
          {/* Mobile */}
          <div className="d-lg-none pt-3 pb-3">
            <div className="p-grey">
              Explore and discover all the content available in Yaypegs through
              the library ••
            </div>
          </div>
          <NavLink exact to={`/provenance`} className="">
            <h5 className="text-left mb-0 py-1">
              Provenance <span className="color-gold">-></span>
            </h5>
          </NavLink>
          <NavLink exact to={`/terms`} className="">
            <h5 className="text-left mb-0 py-1">
              Terms <span className="color-gold">-></span>
            </h5>
          </NavLink>

          <NavLink exact to={`/trading-policy`} className="">
            <h5 className="text-left mb-0 py-1">
              Trading Policy <span className="color-gold">-></span>
            </h5>
          </NavLink>

          <div className="line my-3"></div>
          <div className="p-grey-subtitle pb-2">yaypegs</div>

          <NavLink exact to={`/`} className="">
            <h5 className="text-left mb-0 py-1">
              Home - 家 <span className="color-gold">-></span>
            </h5>
          </NavLink>
          <NavLink exact to={`/about`} className="">
            <h5 className="text-left mb-0 py-1">
              About - 我ら <span className="color-gold">-></span>
            </h5>
          </NavLink>
          <NavLink exact to={`/ip`} className="">
            <h5 className="text-left mb-0 py-1">
              IP - 知的財産 <span className="color-gold">-></span>
            </h5>
          </NavLink>
          <NavLink exact to={`/s1`} className="">
            <h5 className="text-left mb-0 py-1">
              Stage 1: Animation - アニマド{" "}
              <span className="color-gold">-></span>
            </h5>
          </NavLink>
          <NavLink exact to={`/s2`} className="">
            <h5 className="text-left mb-0 py-1">
              Stage 2: Vector - ベクター <span className="color-gold">-></span>
            </h5>
          </NavLink>
          <NavLink exact to={`/s3`} className="">
            <h5 className="text-left mb-0 py-1">
              Stage 3: ??? <span className="color-gold">-></span>
            </h5>
          </NavLink>
          <NavLink exact to={`/explorer`} className="">
            <h5 className="text-left mb-0 py-1">
              Explorer - 冒険者 <span className="color-gold">-></span>
            </h5>
          </NavLink>
          <div className="line my-3"></div>

          <div className="p-grey-subtitle pb-2">community</div>
          <div className="py-1">
            <a
              className=""
              href="https://twitter.com/yaypegs"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Twitter"
            >
              <div className="mb-0">
                Twitter <span className="color-gold">-></span>
              </div>
            </a>
          </div>
          <div className="py-1">
            <a
              className=""
              href="http://discord.gg/T7y8Up89Sc"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Discord"
            >
              <div className="mb-0">
                Discord <span className="color-gold">-></span>
              </div>
            </a>
          </div>

          <div className="line my-3"></div>
          <div className="pb-2">
            <img src={Isotype} className="isotype-sm" alt="Isotype" />
          </div>
          <h5 className="mb-0 pb-3">© {year} Yaypegs.</h5>
        </div>
      </div>
      <div className="">
        <Footer />
      </div>
    </div>
  );
}

export default LibraryPage;
