import { useState, useEffect } from "react";

// Components
import Header from "../Header";
import Footer from "../Footer";
import TraitsPage from "./TraitsPage";

// Json
import Custom_Art from "./Custom";
import Companions_Art from "./Companions";
import Accessories_Art from "./Accessories";

// Images & Icons
import Round_Corners_OFF from "../../images/tools-gallery/round-off.svg";
import Round_Corners_ON from "../../images/tools-gallery/round-on.svg";
import Text_OFF from "../../images/tools-gallery/text-off.svg";
import Text_ON from "../../images/tools-gallery/text-on.svg";
import Zoom_OFF from "../../images/tools-gallery/zoom-off.svg";
import Zoom_ON from "../../images/tools-gallery/zoom-on.svg";

import accessoriesIcon from "../../images/icons/explorer/accessoriesIcon.svg";
import companionsIcon from "../../images/icons/explorer/companionsIcon.svg";
import customsIcon from "../../images/icons/explorer/customsIcon.svg";
import outfitIcon from "../../images/icons/explorer/outfitIcon.svg";

function ArtPage() {
  useEffect(() => {});

  const [iconRoundCorners, setIconRoundCorners] = useState(Round_Corners_OFF);
  const [isRoundCorner, setIsRoundCorner] = useState(false);

  const [iconText, setIconText] = useState(Text_OFF);
  const [isTextON, setIsTextON] = useState(false);

  const [iconGrid, setIconGrid] = useState(Zoom_OFF);
  const [isGridON, setIsGridON] = useState(true);

  // Central SubMenu
  const [isOutfit, setIsOutfit] = useState(true);
  const [isCustom, setIsCustom] = useState(false);
  const [isCompanions, setIsCompanions] = useState(false);
  const [isAccessories, setIsAccessories] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0, "instant");
  }, []);

  function onClickOutfit() {
    setIsCustom(false);
    setIsCompanions(false);
    setIsAccessories(false);
    setIsOutfit(true);
    window.scrollTo(0, 0, "instant");
  }

  function onClickCustom() {
    setIsOutfit(false);
    setIsCompanions(false);
    setIsAccessories(false);
    setIsCustom(true);
    window.scrollTo(0, 0, "instant");
  }

  function onClickCompanions() {
    setIsOutfit(false);
    setIsAccessories(false);
    setIsCustom(false);
    setIsCompanions(true);
    window.scrollTo(0, 0, "instant");
  }

  function onClickAccessories() {
    setIsOutfit(false);
    setIsCompanions(false);
    setIsCustom(false);
    setIsAccessories(true);
    window.scrollTo(0, 0, "instant");
  }

  function onClickRoundCorner() {
    setIsRoundCorner(!isRoundCorner);
    if (isRoundCorner === false) {
      setIconRoundCorners(Round_Corners_ON);
    } else {
      setIconRoundCorners(Round_Corners_OFF);
    }
  }

  function onClickText() {
    setIsTextON(!isTextON);
    if (isTextON === true) {
      setIconText(Text_OFF);
    } else {
      setIconText(Text_ON);
    }
  }

  function onClickGrid() {
    setIsGridON(!isGridON);
    if (isGridON === true) {
      setIconGrid(Zoom_ON);
    } else {
      setIconGrid(Zoom_OFF);
    }
  }

  function MasterGrid(art, category) {
    return (
      <div className="pb-4">
        <div className={isGridON ? `h5 px-1` : `h5 px-1`}>
          {category} [{art.length}]
        </div>
        {art.map(({ number, image }, index) => {
          return (
            <div
              key={index}
              className={
                isGridON
                  ? `col-xl-1 col-md-2 col-sm-3 col-4 p-2 inline-block`
                  : `col-xl-2 col-md-3 col-sm-4 col-6 p-1 inline-block`
              }
            >
              <div className="">
                <div className={isRoundCorner ? `px-1` : ``}>
                  <img
                    src={image}
                    className={
                      isRoundCorner
                        ? `yaypegs-custom round-all p-2`
                        : `yaypegs-custom`
                    }
                    alt={number}
                  />
                </div>
                <div className={isTextON ? `yaypegs-box-below` : `d-none`}>
                  <p className="text-secoundary mb-0 py-2">#{number}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  function Custom() {
    return (
      <div className="p-0">
        {MasterGrid(Custom_Art.maltise_tiger, "Maltise Tiger")}
        {MasterGrid(Custom_Art.alien, "Alien")}
        {MasterGrid(Custom_Art.magic_ape, "Magic Ape")}
        {MasterGrid(Custom_Art.magic_red_panda, "Magic Red Panda")}
        {MasterGrid(Custom_Art.devil, "Devil")}
        {MasterGrid(Custom_Art.magic_panda, "Magic Panda")}
        {MasterGrid(Custom_Art.crustacean, "Crustacean")}
        {MasterGrid(Custom_Art.skull, "Skull")}
        {MasterGrid(Custom_Art.bored_frog, "Bored Frog")}
        {MasterGrid(Custom_Art.hidden, "Hidden")}
        {MasterGrid(Custom_Art.cat, "Cat")}
        {MasterGrid(Custom_Art.chimp, "Chimp")}
      </div>
    );
  }

  function Companions() {
    return (
      <div className="p-0">
        {MasterGrid(Companions_Art.frog, "Frog")}
        {MasterGrid(Companions_Art.rat, "Rat")}
        {MasterGrid(Companions_Art.monkey, "Monkey")}
        {MasterGrid(Companions_Art.cat_dog, "Cat + Dog")}
        {MasterGrid(Companions_Art.two_dogs, "2 Dogs")}
        {MasterGrid(Companions_Art.two_cats, "2 Cats")}
        {MasterGrid(Companions_Art.hamster, "Hamster")}
        {MasterGrid(Companions_Art.alligator_caiman, "Alligator/Caiman")}
        {MasterGrid(Companions_Art.animalia, "Animalia")}
      </div>
    );
  }

  function Accessories() {
    return (
      <div className="p-0">
        {MasterGrid(Accessories_Art.fire_ball, "Fire Ball")}
        {MasterGrid(Accessories_Art.magic_mushroom_03, "Magic Mushroom 03")}
        {MasterGrid(Accessories_Art.magic_mushroom_04, "Magic Mushroom 04")}
        {MasterGrid(Accessories_Art.rainbow_power, "Rainbow Power")}
        {MasterGrid(Accessories_Art.above_clouds, "Above Clouds")}
        {MasterGrid(Accessories_Art.smpte_color_bars, "SMPTE Color Bars")}
        {MasterGrid(Accessories_Art.magic_mushroom_01, "Magic Mushroom 01")}
        {MasterGrid(Accessories_Art.magic_mushroom_02, "Magic Mushroom 02")}
        {MasterGrid(Accessories_Art.floating, "Floating")}
        {MasterGrid(Accessories_Art.microdose, "Microdose")}
        {MasterGrid(Accessories_Art.oxygen_tank, "Oxygen Tank")}
        {MasterGrid(Accessories_Art.skull_mask, "Skull Mask")}
        {MasterGrid(Accessories_Art.flower_02, "Flower 02")}
        {MasterGrid(Accessories_Art.nouns, "Nouns")}
        {MasterGrid(Accessories_Art.flower_01, "Flower 01")}
        {MasterGrid(Accessories_Art.hamlet, "Hamlet")}
      </div>
    );
  }

  function SubMenu() {
    return (
      <div className="tool-menu">
        <div className="row m-0">
          <div className="col-3 px-0 d-none d-lg-block">
            {isOutfit ? (
              <span></span>
            ) : (
              <div className="menu-center menu-left px-3">
                <div
                  className="tool-box-icon px-3"
                  onClick={() => onClickRoundCorner()}
                >
                  <img
                    src={iconRoundCorners}
                    className="tool-icon"
                    alt="Round Corners"
                  />
                  {/* <h6 className="text-center mb-0 pt-1">Circle</h6> */}
                </div>
                <div
                  className="tool-box-icon px-3"
                  onClick={() => onClickText()}
                >
                  <img src={iconText} className="tool-icon" alt="Text ON/OFF" />
                  {/* <h6 className="text-center mb-0 pt-1">Text</h6> */}
                </div>
                <div
                  className="tool-box-icon px-3"
                  onClick={() => onClickGrid()}
                >
                  <img src={iconGrid} className="tool-icon" alt="Grid System" />
                  {/* <h6 className="text-center mb-0 pt-1">Zoom</h6> */}
                </div>
              </div>
            )}
          </div>

          <div className="col-lg-6 col-12 px-0 text-center">
            <div className="menu-center">
              <div
                onClick={() => onClickOutfit()}
                className="tool-box-icon px-3"
              >
                <img src={outfitIcon} className="tool-icon" alt="Grid System" />
                <h6
                  className={
                    isOutfit
                      ? "text-center mb-0 pt-1 pb-2 h-line"
                      : "text-center mb-0 pt-1 pb-2 h-line-none"
                  }
                >
                  Traits Info
                </h6>
              </div>
              <div
                onClick={() => onClickCustom()}
                className="tool-box-icon px-3"
              >
                <img
                  src={customsIcon}
                  className="tool-icon"
                  alt="Grid System"
                />
                <h6
                  className={
                    isCustom
                      ? "text-center mb-0 pt-1 pb-2 h-line"
                      : "text-center mb-0 pt-1 pb-2 h-line-none"
                  }
                >
                  Customs
                </h6>
              </div>
              <div
                onClick={() => onClickAccessories()}
                className="tool-box-icon px-3"
              >
                <img
                  src={accessoriesIcon}
                  className="tool-icon"
                  alt="Grid System"
                />
                <h6
                  className={
                    isAccessories
                      ? "text-center mb-0 pt-1 pb-2 h-line"
                      : "text-center mb-0 pt-1 pb-2 h-line-none"
                  }
                >
                  Rare Traits
                </h6>
              </div>
              <div
                onClick={() => onClickCompanions()}
                className="tool-box-icon px-3"
              >
                <img
                  src={companionsIcon}
                  className="tool-icon"
                  alt="Grid System"
                />
                <h6
                  className={
                    isCompanions
                      ? "text-center mb-0 pt-1 pb-2 h-line"
                      : "text-center mb-0 pt-1 pb-2 h-line-none"
                  }
                >
                  Rare Pets
                </h6>
              </div>
            </div>
          </div>

          <div className="col-3 d-none d-lg-block px-0"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid pt-header-menu pb-tabbar">
      <Header bgColor={true} />
      <div className={"fixed-top-tool"}>
        <SubMenu />
      </div>
      <div className="row m-0 p-3">
        {isOutfit ? <TraitsPage /> : <span></span>}
        {isCustom ? <Custom /> : <span></span>}
        {isCompanions ? <Companions /> : <span></span>}
        {isAccessories ? <Accessories /> : <span></span>}
      </div>
      <Footer />
    </div>
  );
}

export default ArtPage;
