import { useState, useEffect } from "react";
// import Lottie from "react-lottie";

// Components
import Header from "./Header";
import Footer from "./FooterSimple";

// Images & Icons
import Download from "../images/twitter/download.svg";
import LoaderImg from "../images/twitter/image-loader.svg";

// Lottie

function Stage2Page() {
  const NFTnumber = `10kmin`;
  const NFTnumberBig = `10k`;
  const bucket = `poster`;

  const [firebase, setFirebase] = useState("");
  const [firebaseII, setFirebaseII] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0, "instant");
  }, []);

  useEffect(() => {
    async function getImagefromFirebase() {
      try {
        const response = await fetch(
          `https://firebasestorage.googleapis.com/v0/b/yaypegsnft.appspot.com/o/${bucket}%2F${NFTnumber}.png`
        );
        const responseJson = await response.json();
        setFirebase(responseJson);
      } catch (error) {
        console.error(error);
      }

      try {
        const response = await fetch(
          `https://firebasestorage.googleapis.com/v0/b/yaypegsnft.appspot.com/o/${bucket}%2F${NFTnumberBig}.png`
        );
        const responseJson = await response.json();
        setFirebaseII(responseJson);
      } catch (error) {
        console.error(error);
      }
    }
    getImagefromFirebase();
    return () => {
      getImagefromFirebase = null;
    };
  }, [NFTnumber, bucket]);

  function downloadImages() {
    if (NFTnumber !== 0) {
      const xhr = new XMLHttpRequest();
      xhr.open(
        "GET",
        `https://firebasestorage.googleapis.com/v0/b/yaypegsnft.appspot.com/o/${bucket}%2F${NFTnumberBig}.png?alt=media&firebase=${firebaseII.downloadTokens}`,
        true
      );
      xhr.responseType = "blob";

      xhr.onload = () => {
        if (xhr.status === 200) {
          const blob = new Blob([xhr.response], { type: "image/png" });
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${NFTnumber}.png`);
          document.body.appendChild(link);
          link.click();
        } else {
          console.error("Error downloading PNG:", xhr.statusText);
        }
      };
      xhr.onerror = () => {
        console.error("Error downloading PNG:", xhr.statusText);
      };
      xhr.send();
    }
  }

  return (
    <div className="container-fluid pb-tabbar bg-white">
      <Header />
      <div className="row pt-0 m-0">
        <div className="col-12 p-0">
          <div className="loader-conteiner">
            <div className="loader"></div>
          </div>

          <div>
            <div className="parent-center mobile-parent-center">
              <div className="child-center-tweet">
                <div className="twitter-box">
                  <div className="row pt-2">
                    <div className="col m-1 p-0">
                      {!isLoaded && (
                        <div className="img-10k-loading">
                          <img
                            src={LoaderImg}
                            className="loader-img"
                            alt="step 1"
                          />
                        </div>
                      )}
                      <img
                        src={`https://firebasestorage.googleapis.com/v0/b/yaypegsnft.appspot.com/o/${bucket}%2F${NFTnumber}.png?alt=media&firebase=${firebase.downloadTokens}`}
                        className="img-10k"
                        onLoad={() => setIsLoaded(true)}
                        style={{ display: isLoaded ? "block" : "none" }}
                        alt="img"
                      />
                    </div>
                  </div>
                  {/* <hr /> */}

                  <div className="i-10k-center">
                    <img
                      src={Download}
                      onClick={() => downloadImages()}
                      className="tweet-icon pb-2"
                      alt="download"
                    />
                    <div className="pt-1">
                      <h6
                        className="d-flex"
                        style={{
                          color: "#101010",
                          fontSize: "10px",
                          width: "280px",
                        }}
                      >
                        "After clicking the download icon, wait 1 minute for the
                        high-resolution image to download. Use a desktop
                        computer with a strong internet connection for best
                        results."
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Stage2Page;
