// import React from "react";

// Components
import Header from "./Header";
import Footer from "./FooterSimple";

// Images & Icons
import Animaglia from "../images/stage3/animaglia.png";
import Container from "../images/stage3/container.png";

function Stage3Page() {
  return (
    <div className="container-fluid pb-tabbar animaglia-color">
      <Header bgColor={false} />
      <div className="row pt-0 m-0">
        <div className="col-12 p-0">
          <div className="animaglia-center">
            <img
              src={Animaglia}
              className="d-none d-lg-block animaglia-img"
              alt="stage III : Animaglia"
            />
            <img
              src={Container}
              className="d-lg-none container-img"
              alt="stage III : Animaglia"
            />
            <div style={{ display: "block" }} className="pt-3">
              <a
                className="link"
                href="http://animaglia.com"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Animaglia"
              >
                <div className="bubble mb-0">Animaglia</div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Stage3Page;
