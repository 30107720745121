import React, { useEffect } from "react";

// Components

// Json
import TraitsList from "./Traits"

// Images & Icons
import GIF from "../../images/art/yay.gif";

function TraitList(trait, name) {
    return (
        <div className="pb-4">
            <div className={`h4 px-1 py-3`}>
                {name}
            </div>
            {trait.map(({ trait, quantity }, index) => {
                return (
                    <div
                        key={index}
                        className={`col-6 p-1 inline-block`}
                    >
                        <div className="p-traits">
                            {trait} : {quantity}
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

function TraitType() {
    return (
        <div className="p-0">
            {TraitList(TraitsList.accessory, "Accesory")}
            {TraitList(TraitsList.background, "Background")}
            {TraitList(TraitsList.body, "Body")}
            {TraitList(TraitsList.companion, "Companion")}
            {TraitList(TraitsList.eyes, "Eyes")}
            {TraitList(TraitsList.mouth, "Mouth")}
            {TraitList(TraitsList.outfit, "Outfit")}
        </div>
    );
}

function TraitsPage() {

    useEffect(() => {
        window.scrollTo(0, 0, 'instant')
    }, [])

    return (
        <div className="container-fluid pt-header pb-tabbar">
            <div className="row pt-0 m-0">
                <div className="col-12 pt-5">
                    <div className="text-center pt-3">
                        <h1 className="uppercase mb-2">Rarities</h1>
                        <div className="uppercase pb-3 p">Road to rare</div>
                        <div className="py-3">
                            <img src={GIF} className="yaypegs-gif-sm" alt="Collection Gif" />
                        </div>
                        <div className="terms pt-3">
                            <p className="pt-3">
                                Yaypegs is designed in such a way that it is impossible to have an official Ranking. There are hidden traits that are not reflected in the metadata. According to the metadata there are 347 traits, but in reality there are much more than that.
                            </p>
                            <p>
                                Some curiosities within the collection:
                            </p>
                            <p>
                                <strong>(1)</strong> Magic Ape: There are 12 Apes, a number inspired by the movie: 12 Monkeys, and each Magic Ape has the necessary traits to form a band.

                            </p>
                            <p>
                                <strong>(2)</strong> Crustacean / Bored Frog / Cat: Although they are categorized as if each of them had only one trait, there are 3 types of Crustaceans, 2 types of Bored Frogs and 3 types of Cats.
                            </p>
                            <p>
                                <strong>(3)</strong> Chimp: Unlike the previous explanation, the Chimps are divided according to the trait, there are 7 of each type and one vitiligo. But in reality they can all be grouped in a single trait.
                            </p>
                            <p>
                                <strong>(4)</strong> Animalia: each combination of companions is almost unrepeatable.
                            </p>
                            <p>
                                These are just some of the curiosities within the collection, there are many more to be discovered.
                            </p>
                            <p>
                                The way the rares in the collection were grouped on this website represents ccmoret's vision, but as with any piece of art or collectible, individual interpretations are part of how we evaluate the collection. The value of a collectible goes far beyond a ranking.
                            </p>
                            <p>
                                It is the decision of the collector and the community to give the value that each Yaypeg represents. By designing the collection in a non-obvious way, it makes the search for rare and original yaypegs much more interesting, which cannot be detected by ranking sites and marketplaces.
                            </p>
                            <p>
                                Yaypegs is a collection for collectors.
                            </p>
                        </div>
                    </div>

                    <div className="text-center pt-5">
                        <h1 className="uppercase mb-2">Traits</h1>
                        <div className="uppercase pb-1 p">Official Listing</div>
                        <div className="pb-3 p">Trait : Quantity</div>
                        <div className="terms pt-3">
                            <TraitType />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TraitsPage;