// Custom

const custom =
{
    maltise_tiger: [
        {
            "number": 700,
            "image": require("../../images/art/custom/maltise_tiger/700.png"),
        },
        {
            "number": 2271,
            "image": require("../../images/art/custom/maltise_tiger/2271.png"),
        },
        {
            "number": 4875,
            "image": require("../../images/art/custom/maltise_tiger/4875.png"),
        },
        {
            "number": 6225,
            "image": require("../../images/art/custom/maltise_tiger/6225.png"),
        },
        {
            "number": 9025,
            "image": require("../../images/art/custom/maltise_tiger/9025.png"),
        },
        {
            "number": 9626,
            "image": require("../../images/art/custom/maltise_tiger/9626.png"),
        },
    ],
    alien: [
        {
            "number": 267,
            "image": require("../../images/art/custom/alien/267.png"),
        },
        {
            "number": 1134,
            "image": require("../../images/art/custom/alien/1134.png"),
        },
        {
            "number": 1858,
            "image": require("../../images/art/custom/alien/1858.png"),
        },
        {
            "number": 2299,
            "image": require("../../images/art/custom/alien/2299.png"),
        },
        {
            "number": 2524,
            "image": require("../../images/art/custom/alien/2524.png"),
        },
        {
            "number": 3542,
            "image": require("../../images/art/custom/alien/3542.png"),
        },
        {
            "number": 7114,
            "image": require("../../images/art/custom/alien/7114.png"),
        },
        {
            "number": 7981,
            "image": require("../../images/art/custom/alien/7981.png"),
        },
        {
            "number": 8919,
            "image": require("../../images/art/custom/alien/8919.png"),
        },
    ],
    magic_ape: [
        {
            "number": 700,
            "image": require("../../images/art/custom/magic_ape/700.png"),
        },
        {
            "number": 1777,
            "image": require("../../images/art/custom/magic_ape/1777.png"),
        },
        {
            "number": 2064,
            "image": require("../../images/art/custom/magic_ape/2064.png"),
        },
        {
            "number": 2588,
            "image": require("../../images/art/custom/magic_ape/2588.png"),
        },
        {
            "number": 3219,
            "image": require("../../images/art/custom/magic_ape/3219.png"),
        },
        {
            "number": 4134,
            "image": require("../../images/art/custom/magic_ape/4134.png"),
        },
        {
            "number": 5204,
            "image": require("../../images/art/custom/magic_ape/5204.png"),
        },
        {
            "number": 5768,
            "image": require("../../images/art/custom/magic_ape/5768.png"),
        },
        {
            "number": 6874,
            "image": require("../../images/art/custom/magic_ape/6874.png"),
        },
        {
            "number": 7096,
            "image": require("../../images/art/custom/magic_ape/7096.png"),
        },
        {
            "number": 8602,
            "image": require("../../images/art/custom/magic_ape/8602.png"),
        },
        {
            "number": 9700,
            "image": require("../../images/art/custom/magic_ape/9700.png"),
        },
    ],
    magic_red_panda: [
        {
            "number": 659,
            "image": require("../../images/art/custom/magic_red_panda/659.png"),
        },
        {
            "number": 1568,
            "image": require("../../images/art/custom/magic_red_panda/1568.png"),
        },
        {
            "number": 2209,
            "image": require("../../images/art/custom/magic_red_panda/2209.png"),
        },
        {
            "number": 2725,
            "image": require("../../images/art/custom/magic_red_panda/2725.png"),
        },
        {
            "number": 3102,
            "image": require("../../images/art/custom/magic_red_panda/3102.png"),
        },
        {
            "number": 4720,
            "image": require("../../images/art/custom/magic_red_panda/4720.png"),
        },
        {
            "number": 5595,
            "image": require("../../images/art/custom/magic_red_panda/5595.png"),
        },
        {
            "number": 6161,
            "image": require("../../images/art/custom/magic_red_panda/6161.png"),
        },
        {
            "number": 6458,
            "image": require("../../images/art/custom/magic_red_panda/6458.png"),
        },
        {
            "number": 6697,
            "image": require("../../images/art/custom/magic_red_panda/6697.png"),
        },
        {
            "number": 7158,
            "image": require("../../images/art/custom/magic_red_panda/7158.png"),
        },
        {
            "number": 7280,
            "image": require("../../images/art/custom/magic_red_panda/7280.png"),
        },
        {
            "number": 7732,
            "image": require("../../images/art/custom/magic_red_panda/7732.png"),
        },
        {
            "number": 7961,
            "image": require("../../images/art/custom/magic_red_panda/7961.png"),
        },
        {
            "number": 8916,
            "image": require("../../images/art/custom/magic_red_panda/8916.png"),
        },
        {
            "number": 9809,
            "image": require("../../images/art/custom/magic_red_panda/9809.png"),
        },
    ],
    devil: [
        {
            "number": 316,
            "image": require("../../images/art/custom/devil/316.png"),
        },
        {
            "number": 1071,
            "image": require("../../images/art/custom/devil/1071.png"),
        },
        {
            "number": 1143,
            "image": require("../../images/art/custom/devil/1143.png"),
        },
        {
            "number": 1369,
            "image": require("../../images/art/custom/devil/1369.png"),
        },
        {
            "number": 1939,
            "image": require("../../images/art/custom/devil/1939.png"),
        },
        {
            "number": 2093,
            "image": require("../../images/art/custom/devil/2093.png"),
        },
        {
            "number": 2142,
            "image": require("../../images/art/custom/devil/2142.png"),
        },
        {
            "number": 2383,
            "image": require("../../images/art/custom/devil/2383.png"),
        },
        {
            "number": 2448,
            "image": require("../../images/art/custom/devil/2448.png"),
        },
        {
            "number": 3104,
            "image": require("../../images/art/custom/devil/3104.png"),
        },
        {
            "number": 3329,
            "image": require("../../images/art/custom/devil/3329.png"),
        },
        {
            "number": 3912,
            "image": require("../../images/art/custom/devil/3912.png"),
        },
        {
            "number": 4903,
            "image": require("../../images/art/custom/devil/4903.png"),
        },
        {
            "number": 5376,
            "image": require("../../images/art/custom/devil/5376.png"),
        },
        {
            "number": 6392,
            "image": require("../../images/art/custom/devil/6392.png"),
        },
        {
            "number": 7059,
            "image": require("../../images/art/custom/devil/7059.png"),
        },
        {
            "number": 7466,
            "image": require("../../images/art/custom/devil/7466.png"),
        },
        {
            "number": 7499,
            "image": require("../../images/art/custom/devil/7499.png"),
        },
        {
            "number": 8414,
            "image": require("../../images/art/custom/devil/8414.png"),
        },
        {
            "number": 8553,
            "image": require("../../images/art/custom/devil/8553.png"),
        },
        {
            "number": 8750,
            "image": require("../../images/art/custom/devil/8750.png"),
        },
        {
            "number": 9390,
            "image": require("../../images/art/custom/devil/9390.png"),
        },
        {
            "number": 9392,
            "image": require("../../images/art/custom/devil/9392.png"),
        },
    ],
    magic_panda: [
        {
            "number": 613,
            "image": require("../../images/art/custom/magic_panda/613.png"),
        },
        {
            "number": 1281,
            "image": require("../../images/art/custom/magic_panda/1281.png"),
        },
        {
            "number": 1469,
            "image": require("../../images/art/custom/magic_panda/1469.png"),
        },
        {
            "number": 1718,
            "image": require("../../images/art/custom/magic_panda/1718.png"),
        },
        {
            "number": 2128,
            "image": require("../../images/art/custom/magic_panda/2128.png"),
        },
        {
            "number": 2390,
            "image": require("../../images/art/custom/magic_panda/2390.png"),
        },
        {
            "number": 2529,
            "image": require("../../images/art/custom/magic_panda/2529.png"),
        },
        {
            "number": 3181,
            "image": require("../../images/art/custom/magic_panda/3181.png"),
        },
        {
            "number": 3190,
            "image": require("../../images/art/custom/magic_panda/3190.png"),
        },
        {
            "number": 3279,
            "image": require("../../images/art/custom/magic_panda/3279.png"),
        },
        {
            "number": 3603,
            "image": require("../../images/art/custom/magic_panda/3603.png"),
        },
        {
            "number": 3684,
            "image": require("../../images/art/custom/magic_panda/3684.png"),
        },
        {
            "number": 3722,
            "image": require("../../images/art/custom/magic_panda/3722.png"),
        },
        {
            "number": 4567,
            "image": require("../../images/art/custom/magic_panda/4567.png"),
        },
        {
            "number": 4875,
            "image": require("../../images/art/custom/magic_panda/4875.png"),
        },
        {
            "number": 5079,
            "image": require("../../images/art/custom/magic_panda/5079.png"),
        },
        {
            "number": 5508,
            "image": require("../../images/art/custom/magic_panda/5508.png"),
        },
        {
            "number": 5601,
            "image": require("../../images/art/custom/magic_panda/5601.png"),
        },
        {
            "number": 5658,
            "image": require("../../images/art/custom/magic_panda/5658.png"),
        },
        {
            "number": 5997,
            "image": require("../../images/art/custom/magic_panda/5997.png"),
        },
        {
            "number": 5998,
            "image": require("../../images/art/custom/magic_panda/5998.png"),
        },
        {
            "number": 6018,
            "image": require("../../images/art/custom/magic_panda/6018.png"),
        },
        {
            "number": 6607,
            "image": require("../../images/art/custom/magic_panda/6607.png"),
        },
        {
            "number": 6691,
            "image": require("../../images/art/custom/magic_panda/6691.png"),
        },
        {
            "number": 6751,
            "image": require("../../images/art/custom/magic_panda/6751.png"),
        },
        {
            "number": 8073,
            "image": require("../../images/art/custom/magic_panda/8073.png"),
        },
        {
            "number": 8304,
            "image": require("../../images/art/custom/magic_panda/8304.png"),
        },
        {
            "number": 8560,
            "image": require("../../images/art/custom/magic_panda/8560.png"),
        },
        {
            "number": 9283,
            "image": require("../../images/art/custom/magic_panda/9283.png"),
        },
        {
            "number": 9557,
            "image": require("../../images/art/custom/magic_panda/9557.png"),
        },
        {
            "number": 9755,
            "image": require("../../images/art/custom/magic_panda/9755.png"),
        },
    ],
    crustacean: [
        {
            "number": 99,
            "image": require("../../images/art/custom/crustacean/99.png"),
        },
        {
            "number": 186,
            "image": require("../../images/art/custom/crustacean/186.png"),
        },
        {
            "number": 369,
            "image": require("../../images/art/custom/crustacean/369.png"),
        },
        {
            "number": 670,
            "image": require("../../images/art/custom/crustacean/670.png"),
        },
        {
            "number": 1368,
            "image": require("../../images/art/custom/crustacean/1368.png"),
        },
        {
            "number": 1615,
            "image": require("../../images/art/custom/crustacean/1615.png"),
        },
        {
            "number": 2207,
            "image": require("../../images/art/custom/crustacean/2207.png"),
        },
        {
            "number": 2327,
            "image": require("../../images/art/custom/crustacean/2327.png"),
        },
        {
            "number": 2621,
            "image": require("../../images/art/custom/crustacean/2621.png"),
        },
        {
            "number": 3342,
            "image": require("../../images/art/custom/crustacean/3342.png"),
        },
        {
            "number": 3823,
            "image": require("../../images/art/custom/crustacean/3823.png"),
        },
        {
            "number": 4253,
            "image": require("../../images/art/custom/crustacean/4253.png"),
        },
        {
            "number": 4973,
            "image": require("../../images/art/custom/crustacean/4973.png"),
        },
        {
            "number": 5049,
            "image": require("../../images/art/custom/crustacean/5049.png"),
        },
        {
            "number": 5565,
            "image": require("../../images/art/custom/crustacean/5565.png"),
        },
        {
            "number": 5707,
            "image": require("../../images/art/custom/crustacean/5707.png"),
        },
        {
            "number": 5872,
            "image": require("../../images/art/custom/crustacean/5872.png"),
        },
        {
            "number": 5934,
            "image": require("../../images/art/custom/crustacean/5934.png"),
        },
        {
            "number": 6104,
            "image": require("../../images/art/custom/crustacean/6104.png"),
        },
        {
            "number": 6225,
            "image": require("../../images/art/custom/crustacean/6225.png"),
        },
        {
            "number": 6770,
            "image": require("../../images/art/custom/crustacean/6770.png"),
        },
        {
            "number": 6833,
            "image": require("../../images/art/custom/crustacean/6833.png"),
        },
        {
            "number": 6836,
            "image": require("../../images/art/custom/crustacean/6836.png"),
        },
        {
            "number": 6868,
            "image": require("../../images/art/custom/crustacean/6868.png"),
        },
        {
            "number": 7090,
            "image": require("../../images/art/custom/crustacean/7090.png"),
        },
        {
            "number": 7192,
            "image": require("../../images/art/custom/crustacean/7192.png"),
        },
        {
            "number": 7274,
            "image": require("../../images/art/custom/crustacean/7274.png"),
        },
        {
            "number": 7338,
            "image": require("../../images/art/custom/crustacean/7338.png"),
        },
        {
            "number": 7903,
            "image": require("../../images/art/custom/crustacean/7903.png"),
        },
        {
            "number": 8039,
            "image": require("../../images/art/custom/crustacean/8039.png"),
        },
        {
            "number": 8588,
            "image": require("../../images/art/custom/crustacean/8588.png"),
        },
        {
            "number": 9196,
            "image": require("../../images/art/custom/crustacean/9196.png"),
        },
        {
            "number": 9598,
            "image": require("../../images/art/custom/crustacean/9598.png"),
        },
    ],
    skull: [
        {
            "number": 426,
            "image": require("../../images/art/custom/skull/426.png"),
        },
        {
            "number": 494,
            "image": require("../../images/art/custom/skull/494.png"),
        },
        {
            "number": 1391,
            "image": require("../../images/art/custom/skull/1391.png"),
        },
        {
            "number": 1486,
            "image": require("../../images/art/custom/skull/1486.png"),
        },
        {
            "number": 2133,
            "image": require("../../images/art/custom/skull/2133.png"),
        },
        {
            "number": 2260,
            "image": require("../../images/art/custom/skull/2260.png"),
        },
        {
            "number": 2381,
            "image": require("../../images/art/custom/skull/2381.png"),
        },
        {
            "number": 2446,
            "image": require("../../images/art/custom/skull/2446.png"),
        },
        {
            "number": 3156,
            "image": require("../../images/art/custom/skull/3156.png"),
        },
        {
            "number": 3218,
            "image": require("../../images/art/custom/skull/3218.png"),
        },
        {
            "number": 3284,
            "image": require("../../images/art/custom/skull/3284.png"),
        },
        {
            "number": 3417,
            "image": require("../../images/art/custom/skull/3417.png"),
        },
        {
            "number": 3471,
            "image": require("../../images/art/custom/skull/3471.png"),
        },
        {
            "number": 3536,
            "image": require("../../images/art/custom/skull/3536.png"),
        },
        {
            "number": 4576,
            "image": require("../../images/art/custom/skull/4576.png"),
        },
        {
            "number": 4709,
            "image": require("../../images/art/custom/skull/4709.png"),
        },
        {
            "number": 5312,
            "image": require("../../images/art/custom/skull/5312.png"),
        },
        {
            "number": 5525,
            "image": require("../../images/art/custom/skull/5525.png"),
        },
        {
            "number": 5540,
            "image": require("../../images/art/custom/skull/5540.png"),
        },
        {
            "number": 5541,
            "image": require("../../images/art/custom/skull/5541.png"),
        },
        {
            "number": 5992,
            "image": require("../../images/art/custom/skull/5992.png"),
        },
        {
            "number": 6002,
            "image": require("../../images/art/custom/skull/6002.png"),
        },
        {
            "number": 6193,
            "image": require("../../images/art/custom/skull/6193.png"),
        },
        {
            "number": 6416,
            "image": require("../../images/art/custom/skull/6416.png"),
        },
        {
            "number": 6545,
            "image": require("../../images/art/custom/skull/6545.png"),
        },
        {
            "number": 6416,
            "image": require("../../images/art/custom/skull/6416.png"),
        },
        {
            "number": 6558,
            "image": require("../../images/art/custom/skull/6558.png"),
        },
        {
            "number": 7193,
            "image": require("../../images/art/custom/skull/7193.png"),
        },
        {
            "number": 7296,
            "image": require("../../images/art/custom/skull/7296.png"),
        },
        {
            "number": 7566,
            "image": require("../../images/art/custom/skull/7566.png"),
        },
        {
            "number": 7599,
            "image": require("../../images/art/custom/skull/7599.png"),
        },
        {
            "number": 8079,
            "image": require("../../images/art/custom/skull/8079.png"),
        },
        {
            "number": 8604,
            "image": require("../../images/art/custom/skull/8604.png"),
        },
        {
            "number": 8755,
            "image": require("../../images/art/custom/skull/8755.png"),
        },
        {
            "number": 9382,
            "image": require("../../images/art/custom/skull/9382.png"),
        },
        {
            "number": 9414,
            "image": require("../../images/art/custom/skull/9414.png"),
        },
    ],
    bored_frog: [
        {
            "number": 164,
            "image": require("../../images/art/custom/bored_frog/164.png"),
        },
        {
            "number": 582,
            "image": require("../../images/art/custom/bored_frog/582.png"),
        },
        {
            "number": 726,
            "image": require("../../images/art/custom/bored_frog/726.png"),
        },
        {
            "number": 1387,
            "image": require("../../images/art/custom/bored_frog/1387.png"),
        },
        {
            "number": 1595,
            "image": require("../../images/art/custom/bored_frog/1595.png"),
        },
        {
            "number": 2084,
            "image": require("../../images/art/custom/bored_frog/2084.png"),
        },
        {
            "number": 2253,
            "image": require("../../images/art/custom/bored_frog/2253.png"),
        },
        {
            "number": 2342,
            "image": require("../../images/art/custom/bored_frog/2342.png"),
        },
        {
            "number": 2353,
            "image": require("../../images/art/custom/bored_frog/2353.png"),
        },
        {
            "number": 2389,
            "image": require("../../images/art/custom/bored_frog/2389.png"),
        },
        {
            "number": 2396,
            "image": require("../../images/art/custom/bored_frog/2396.png"),
        },
        {
            "number": 3573,
            "image": require("../../images/art/custom/bored_frog/3573.png"),
        },
        {
            "number": 3618,
            "image": require("../../images/art/custom/bored_frog/3618.png"),
        },
        {
            "number": 4566,
            "image": require("../../images/art/custom/bored_frog/4566.png"),
        },
        {
            "number": 4607,
            "image": require("../../images/art/custom/bored_frog/4607.png"),
        },
        {
            "number": 5299,
            "image": require("../../images/art/custom/bored_frog/5299.png"),
        },
        {
            "number": 5343,
            "image": require("../../images/art/custom/bored_frog/5343.png"),
        },
        {
            "number": 5354,
            "image": require("../../images/art/custom/bored_frog/5354.png"),
        },
        {
            "number": 5542,
            "image": require("../../images/art/custom/bored_frog/5542.png"),
        },
        {
            "number": 5554,
            "image": require("../../images/art/custom/bored_frog/5554.png"),
        },
        {
            "number": 5574,
            "image": require("../../images/art/custom/bored_frog/5574.png"),
        },
        {
            "number": 5868,
            "image": require("../../images/art/custom/bored_frog/5868.png"),
        },
        {
            "number": 6003,
            "image": require("../../images/art/custom/bored_frog/6003.png"),
        },
        {
            "number": 6031,
            "image": require("../../images/art/custom/bored_frog/6031.png"),
        },
        {
            "number": 6039,
            "image": require("../../images/art/custom/bored_frog/6039.png"),
        },
        {
            "number": 6643,
            "image": require("../../images/art/custom/bored_frog/6643.png"),
        },
        {
            "number": 6666,
            "image": require("../../images/art/custom/bored_frog/6666.png"),
        },
        {
            "number": 6807,
            "image": require("../../images/art/custom/bored_frog/6807.png"),
        },
        {
            "number": 7067,
            "image": require("../../images/art/custom/bored_frog/7067.png"),
        },
        {
            "number": 7075,
            "image": require("../../images/art/custom/bored_frog/7075.png"),
        },
        {
            "number": 7079,
            "image": require("../../images/art/custom/bored_frog/7079.png"),
        },
        {
            "number": 7173,
            "image": require("../../images/art/custom/bored_frog/7173.png"),
        },
        {
            "number": 7258,
            "image": require("../../images/art/custom/bored_frog/7258.png"),
        },
        {
            "number": 7290,
            "image": require("../../images/art/custom/bored_frog/7290.png"),
        },
        {
            "number": 7742,
            "image": require("../../images/art/custom/bored_frog/7742.png"),
        },
        {
            "number": 8037,
            "image": require("../../images/art/custom/bored_frog/8037.png"),
        },
        {
            "number": 9025,
            "image": require("../../images/art/custom/bored_frog/9025.png"),
        },
        {
            "number": 9328,
            "image": require("../../images/art/custom/bored_frog/9328.png"),
        },
        {
            "number": 9450,
            "image": require("../../images/art/custom/bored_frog/9450.png"),
        },
        {
            "number": 9453,
            "image": require("../../images/art/custom/bored_frog/9453.png"),
        },
    ],
    hidden: [
        {
            "number": 3,
            "image": require("../../images/art/custom/hidden/3.png"),
        },
        {
            "number": 305,
            "image": require("../../images/art/custom/hidden/305.png"),
        },
        {
            "number": 485,
            "image": require("../../images/art/custom/hidden/485.png"),
        },
        {
            "number": 800,
            "image": require("../../images/art/custom/hidden/800.png"),
        },
        {
            "number": 947,
            "image": require("../../images/art/custom/hidden/947.png"),
        },
        {
            "number": 1245,
            "image": require("../../images/art/custom/hidden/1245.png"),
        },
        {
            "number": 1365,
            "image": require("../../images/art/custom/hidden/1365.png"),
        },
        {
            "number": 1596,
            "image": require("../../images/art/custom/hidden/1596.png"),
        },
        {
            "number": 1808,
            "image": require("../../images/art/custom/hidden/1808.png"),
        },
        {
            "number": 2059,
            "image": require("../../images/art/custom/hidden/2059.png"),
        },
        {
            "number": 2089,
            "image": require("../../images/art/custom/hidden/2089.png"),
        },
        {
            "number": 2189,
            "image": require("../../images/art/custom/hidden/2189.png"),
        },
        {
            "number": 2271,
            "image": require("../../images/art/custom/hidden/2271.png"),
        },
        {
            "number": 2365,
            "image": require("../../images/art/custom/hidden/2365.png"),
        },
        {
            "number": 2598,
            "image": require("../../images/art/custom/hidden/2598.png"),
        },
        {
            "number": 3031,
            "image": require("../../images/art/custom/hidden/3031.png"),
        },
        {
            "number": 3353,
            "image": require("../../images/art/custom/hidden/3353.png"),
        },
        {
            "number": 3419,
            "image": require("../../images/art/custom/hidden/3419.png"),
        },
        {
            "number": 3478,
            "image": require("../../images/art/custom/hidden/3478.png"),
        },
        {
            "number": 4312,
            "image": require("../../images/art/custom/hidden/4312.png"),
        },
        {
            "number": 4713,
            "image": require("../../images/art/custom/hidden/4713.png"),
        },
        {
            "number": 5010,
            "image": require("../../images/art/custom/hidden/5010.png"),
        },
        {
            "number": 5357,
            "image": require("../../images/art/custom/hidden/5357.png"),
        },
        {
            "number": 5543,
            "image": require("../../images/art/custom/hidden/5543.png"),
        },
        {
            "number": 5667,
            "image": require("../../images/art/custom/hidden/5667.png"),
        },
        {
            "number": 5861,
            "image": require("../../images/art/custom/hidden/5861.png"),
        },
        {
            "number": 6337,
            "image": require("../../images/art/custom/hidden/6337.png"),
        },
        {
            "number": 6651,
            "image": require("../../images/art/custom/hidden/6651.png"),
        },
        {
            "number": 6802,
            "image": require("../../images/art/custom/hidden/6802.png"),
        },
        {
            "number": 7256,
            "image": require("../../images/art/custom/hidden/7256.png"),
        },
        {
            "number": 7347,
            "image": require("../../images/art/custom/hidden/7347.png"),
        },
        {
            "number": 7437,
            "image": require("../../images/art/custom/hidden/7437.png"),
        },
        {
            "number": 7553,
            "image": require("../../images/art/custom/hidden/7553.png"),
        },
        {
            "number": 7902,
            "image": require("../../images/art/custom/hidden/7902.png"),
        },
        {
            "number": 8265,
            "image": require("../../images/art/custom/hidden/8265.png"),
        },
        {
            "number": 8473,
            "image": require("../../images/art/custom/hidden/8473.png"),
        },
        {
            "number": 8518,
            "image": require("../../images/art/custom/hidden/8518.png"),
        },
        {
            "number": 8695,
            "image": require("../../images/art/custom/hidden/8695.png"),
        },
        {
            "number": 8940,
            "image": require("../../images/art/custom/hidden/8940.png"),
        },
        {
            "number": 9014,
            "image": require("../../images/art/custom/hidden/9014.png"),
        },
        {
            "number": 9388,
            "image": require("../../images/art/custom/hidden/9388.png"),
        },
        {
            "number": 9654,
            "image": require("../../images/art/custom/hidden/9654.png"),
        },
    ],
    cat: [
        {
            "number": 114,
            "image": require("../../images/art/custom/cat/114.png"),
        },
        {
            "number": 144,
            "image": require("../../images/art/custom/cat/144.png"),
        },
        {
            "number": 332,
            "image": require("../../images/art/custom/cat/332.png"),
        },
        {
            "number": 371,
            "image": require("../../images/art/custom/cat/371.png"),
        },
        {
            "number": 819,
            "image": require("../../images/art/custom/cat/819.png"),
        },
        {
            "number": 877,
            "image": require("../../images/art/custom/cat/877.png"),
        },
        {
            "number": 1046,
            "image": require("../../images/art/custom/cat/1046.png"),
        },
        {
            "number": 1299,
            "image": require("../../images/art/custom/cat/1299.png"),
        },
        {
            "number": 1629,
            "image": require("../../images/art/custom/cat/1629.png"),
        },
        {
            "number": 1683,
            "image": require("../../images/art/custom/cat/1683.png"),
        },
        {
            "number": 2016,
            "image": require("../../images/art/custom/cat/2016.png"),
        },
        {
            "number": 2092,
            "image": require("../../images/art/custom/cat/2092.png"),
        },
        {
            "number": 2200,
            "image": require("../../images/art/custom/cat/2200.png"),
        },
        {
            "number": 2246,
            "image": require("../../images/art/custom/cat/2246.png"),
        },
        {
            "number": 2429,
            "image": require("../../images/art/custom/cat/2429.png"),
        },
        {
            "number": 2550,
            "image": require("../../images/art/custom/cat/2550.png"),
        },
        {
            "number": 3309,
            "image": require("../../images/art/custom/cat/3309.png"),
        },
        {
            "number": 3754,
            "image": require("../../images/art/custom/cat/3754.png"),
        },
        {
            "number": 3844,
            "image": require("../../images/art/custom/cat/3844.png"),
        },
        {
            "number": 4108,
            "image": require("../../images/art/custom/cat/4108.png"),
        },
        {
            "number": 4300,
            "image": require("../../images/art/custom/cat/4300.png"),
        },
        {
            "number": 4692,
            "image": require("../../images/art/custom/cat/4692.png"),
        },
        {
            "number": 4789,
            "image": require("../../images/art/custom/cat/4789.png"),
        },
        {
            "number": 4911,
            "image": require("../../images/art/custom/cat/4911.png"),
        },
        {
            "number": 5023,
            "image": require("../../images/art/custom/cat/5023.png"),
        },
        {
            "number": 5337,
            "image": require("../../images/art/custom/cat/5337.png"),
        },
        {
            "number": 5591,
            "image": require("../../images/art/custom/cat/5591.png"),
        },
        {
            "number": 5838,
            "image": require("../../images/art/custom/cat/5838.png"),
        },
        {
            "number": 5866,
            "image": require("../../images/art/custom/cat/5866.png"),
        },
        {
            "number": 6237,
            "image": require("../../images/art/custom/cat/6237.png"),
        },
        {
            "number": 6316,
            "image": require("../../images/art/custom/cat/6316.png"),
        },
        {
            "number": 6723,
            "image": require("../../images/art/custom/cat/6723.png"),
        },
        {
            "number": 6749,
            "image": require("../../images/art/custom/cat/6749.png"),
        },
        {
            "number": 6801,
            "image": require("../../images/art/custom/cat/6801.png"),
        },
        {
            "number": 6928,
            "image": require("../../images/art/custom/cat/6928.png"),
        },
        {
            "number": 7175,
            "image": require("../../images/art/custom/cat/7175.png"),
        },
        {
            "number": 7186,
            "image": require("../../images/art/custom/cat/7186.png"),
        },
        {
            "number": 7229,
            "image": require("../../images/art/custom/cat/7229.png"),
        },
        {
            "number": 8042,
            "image": require("../../images/art/custom/cat/8042.png"),
        },
        {
            "number": 8273,
            "image": require("../../images/art/custom/cat/8273.png"),
        },
        {
            "number": 8436,
            "image": require("../../images/art/custom/cat/8436.png"),
        },
        {
            "number": 8734,
            "image": require("../../images/art/custom/cat/8734.png"),
        },
        {
            "number": 9264,
            "image": require("../../images/art/custom/cat/9264.png"),
        },
        {
            "number": 9626,
            "image": require("../../images/art/custom/cat/9626.png"),
        },
    ],
    chimp: [
        {
            "number": 197,
            "image": require("../../images/art/custom/chimp/197.png"),
        },
        {
            "number": 344,
            "image": require("../../images/art/custom/chimp/344.png"),
        },
        {
            "number": 366,
            "image": require("../../images/art/custom/chimp/366.png"),
        },
        {
            "number": 663,
            "image": require("../../images/art/custom/chimp/663.png"),
        },
        {
            "number": 1058,
            "image": require("../../images/art/custom/chimp/1058.png"),
        },
        {
            "number": 1128,
            "image": require("../../images/art/custom/chimp/1128.png"),
        },
        {
            "number": 1326,
            "image": require("../../images/art/custom/chimp/1326.png"),
        },
        {
            "number": 1443,
            "image": require("../../images/art/custom/chimp/1443.png"),
        },
        {
            "number": 1592,
            "image": require("../../images/art/custom/chimp/1592.png"),
        },
        {
            "number": 1643,
            "image": require("../../images/art/custom/chimp/1643.png"),
        },
        {
            "number": 1890,
            "image": require("../../images/art/custom/chimp/1890.png"),
        },
        {
            "number": 2139,
            "image": require("../../images/art/custom/chimp/2139.png"),
        },
        {
            "number": 2258,
            "image": require("../../images/art/custom/chimp/2258.png"),
        },
        {
            "number": 2344,
            "image": require("../../images/art/custom/chimp/2344.png"),
        },
        {
            "number": 2364,
            "image": require("../../images/art/custom/chimp/2364.png"),
        },
        {
            "number": 2438,
            "image": require("../../images/art/custom/chimp/2438.png"),
        },
        {
            "number": 2499,
            "image": require("../../images/art/custom/chimp/2499.png"),
        },
        {
            "number": 2659,
            "image": require("../../images/art/custom/chimp/2659.png"),
        },
        {
            "number": 3080,
            "image": require("../../images/art/custom/chimp/3080.png"),
        },
        {
            "number": 3285,
            "image": require("../../images/art/custom/chimp/3285.png"),
        },
        {
            "number": 3399,
            "image": require("../../images/art/custom/chimp/3399.png"),
        },
        {
            "number": 3572,
            "image": require("../../images/art/custom/chimp/3572.png"),
        },
        {
            "number": 3911,
            "image": require("../../images/art/custom/chimp/3911.png"),
        },
        {
            "number": 4026,
            "image": require("../../images/art/custom/chimp/4026.png"),
        },
        {
            "number": 4452,
            "image": require("../../images/art/custom/chimp/4452.png"),
        },
        {
            "number": 4691,
            "image": require("../../images/art/custom/chimp/4691.png"),
        },
        {
            "number": 4804,
            "image": require("../../images/art/custom/chimp/4804.png"),
        },
        {
            "number": 4920,
            "image": require("../../images/art/custom/chimp/4920.png"),
        },
        {
            "number": 5367,
            "image": require("../../images/art/custom/chimp/5367.png"),
        },
        {
            "number": 5590,
            "image": require("../../images/art/custom/chimp/5590.png"),
        },
        {
            "number": 5876,
            "image": require("../../images/art/custom/chimp/5876.png"),
        },
        {
            "number": 6246,
            "image": require("../../images/art/custom/chimp/6246.png"),
        },
        {
            "number": 6384,
            "image": require("../../images/art/custom/chimp/6384.png"),
        },
        {
            "number": 6707,
            "image": require("../../images/art/custom/chimp/6707.png"),
        },
        {
            "number": 7004,
            "image": require("../../images/art/custom/chimp/7004.png"),
        },
        {
            "number": 7227,
            "image": require("../../images/art/custom/chimp/7227.png"),
        },
        {
            "number": 7401,
            "image": require("../../images/art/custom/chimp/7401.png"),
        },
        {
            "number": 7585,
            "image": require("../../images/art/custom/chimp/7585.png"),
        },
        {
            "number": 7982,
            "image": require("../../images/art/custom/chimp/7982.png"),
        },
        {
            "number": 8173,
            "image": require("../../images/art/custom/chimp/8173.png"),
        },
        {
            "number": 8375,
            "image": require("../../images/art/custom/chimp/8375.png"),
        },
        {
            "number": 8529,
            "image": require("../../images/art/custom/chimp/8529.png"),
        },
        {
            "number": 8849,
            "image": require("../../images/art/custom/chimp/8849.png"),
        },
        {
            "number": 9158,
            "image": require("../../images/art/custom/chimp/9158.png"),
        },
        {
            "number": 9250,
            "image": require("../../images/art/custom/chimp/9250.png"),
        },
        {
            "number": 9334,
            "image": require("../../images/art/custom/chimp/9334.png"),
        },
        {
            "number": 9375,
            "image": require("../../images/art/custom/chimp/9375.png"),
        },
        {
            "number": 9449,
            "image": require("../../images/art/custom/chimp/9449.png"),
        },
        {
            "number": 9790,
            "image": require("../../images/art/custom/chimp/9790.png"),
        },
    ]

}



export default custom 