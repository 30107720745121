import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// General
import Home from "./components/Home2";
import About from "./components/About/About";
import ArtPreview from "./components/Explorer/ArtPreview";
import Explorer from "./components/Explorer/Explorer";
// import Magic from "./components/Magic";
import Stages from "./components/Stages";
import Stage1 from "./components/Stage1";
import Stage2 from "./components/Stage2";
import Stage3 from "./components/Stage3";
import IP from "./components/IP";
import Future from "./components/Future";
import Library from "./components/Library";
import Provenance from "./components/Provenance";
import Terms from "./components/Terms";
import TradingPolicy from "./components/TradingPolicy";
import Poster from "./components/Poster";
import Error from "./components/Error";

function RouteApp() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/artpreview" element={<ArtPreview />} />
          <Route path="/explorer" element={<Explorer />} />
          <Route path="/magic-machine" element={<Stage1 />} />
          <Route path="/stages" element={<Stages />} />
          <Route path="/s1" element={<Stage1 />} />
          <Route path="/s2" element={<Stage2 />} />
          <Route path="/s3" element={<Stage3 />} />
          <Route path="/animaglia" element={<Stage3 />} />
          <Route path="/ip" element={<IP />} />
          <Route path="/future" element={<Future />} />
          <Route path="/library" element={<Library />} />
          <Route path="/provenance" element={<Provenance />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/trading-policy" element={<TradingPolicy />} />
          <Route path="/10k" element={<Poster />} />

          <Route path="*" element={<Error />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default RouteApp;
