import { useState, useEffect } from "react";
// import Lottie from "react-lottie";

// Components
import Header from "./Header";
import Footer from "./FooterSimple";

// Images & Icons
import TwitterLogo from "../images/twitter/twitter-logo.svg";
import Refresh from "../images/twitter/refresh.svg";
import Info from "../images/twitter/info.svg";
import Download from "../images/twitter/download.svg";

import W1 from "../images/twitter/w1.png";
import W2 from "../images/twitter/w2.png";
import W3 from "../images/twitter/w3.png";

import B1 from "../images/twitter/t-b1.png";
import B3 from "../images/twitter/b3.png";

import LoaderImg from "../images/twitter/image-loader.svg";

// Lottie

function Stage2Page() {
  var tweet_text = [
    { text: `Wft! stage 2 is on 🔥 @yaypegs` },
    { text: `love stage 2... ty @yaypegs 🪄` },
    { text: `If this is stage 2, what could stage 3 be? 👀` },
    { text: `have to buy another @yaypegs` },
    { text: `.@yaypegs is still building 🏗️ in the middle of the bear market` },
    {
      text: `A special GM to all the new @yaypegs who just joined the community!`,
    },
    {
      text: `Drop your @yaypegs below ⬇️ I want to make sure I'm following all of you!`,
    },
    { text: `Woke up today like damn. I love stage 2 by @yaypegs` },
    {
      text: `.@yaypegs is still building despite the bear market, not only that, they has never extracted value from the market. It was a free project and it does not have enforcement royalties, despite that, the team has not stopped working for a single day`,
    },
    { text: `Shout out to the @yaypegs team for launching Stage 2! 💪` },
    { text: `When all NFTs go to 0, I will still love owning a @yaypegs. 🍄` },
    { text: `GM ☀️☕️` },
    {
      text: `How to know if your PFP is well designed? because the concept can be transferred to any format while keeps its identity. 🤯`,
    },
    { text: `This is how my @yaypegs looks in stage 2... show me yours!` },
    { text: `favorite @yaypegs from my collection 🪄` },
    {
      text: `love this little details and how clean the lines look on me @yaypegs`,
    },
    { text: `This is siiiiiiick!!!` },
    {
      text: `If you are a true collector in NFT, you must have at least 1 @yaypegs in your wallet`,
    },
    { text: `👀👀👀👀👀👀👀👀👀👀👀👀👀` },
    { text: `Don’t mess with @yaypegs` },
    { text: `Okay sick` },
    { text: `i will never compare to your master piece 🙏` },
    { text: `Before ➡️ Now` },
    { text: `Pixel ➡️ Vector` },
    { text: `I think it’s perfect 😁` },
    { text: `i love this @yaypegs ✊` },
    { text: `I can't decide which verison I prefer! help me! please` },
    { text: `This is my forever @yaypegs ❤️‍🔥` },
    { text: `Omg this is the perfect @yaypegs!` },
    { text: `This @yaypegs is ready for stage 3 🙌` },
    {
      text: `🐊🦈🐈🦖🐙🐘🐻🐘🐬🐅🐆🦒🦑🐶🐸🐆🐇🐋🐝🐎🐒🐽🦛🦊🦝🐔🐥🦓🦭🦁🐺🦆🦝🦏🦬🐄🐃🐞🦘🐧🦍🐕🦜🐂 What's yours?`,
    },
    { text: `Join the SQUAD!` },
    { text: `There is no better time to be a @yaypegs` },
    { text: `Yup... all look cool to me 👍 @yaypegs` },
    { text: `the whole collection is pure fun.` },
    { text: `let's fkn go 🔥🔥🔥🔥🔥🔥🔥🔥` },
    { text: `don't make me choose between them 🫂` },
    {
      text: `Collection too fire tbh. Obsessed with the palettes and trait layers 😍🤓`,
    },
    {
      text: `I’ve had so much fun looking through all the traits! 🍄🦜💫 !yay`,
    },
    { text: `Let's go @yaypegs! 🔥` },
    { text: `I love how my @yaypegs looks facing forward` },
    {
      text: `The @yaypegs team redesigned more than 500 traits to transform the entire collection, but also reinterpreted the shape of the PFP`,
    },
    { text: `.@yaypegs is truly underrated` },
    { text: `I expect nothing less from the team behind @yaypegs 🏆` },
    { text: `We gonna rock it fr` },
    { text: `.@yaypegs stage 2 is 🔥` },
    { text: `So excited for stage 2... stage 3 it's gonna be 🤯🪄🖤🔥` },
    { text: `art looks dope af @ccmoret 🔥` },
    { text: `Whoah, thats so fkn dope!!!!!!!!! @yaypegs` },
    { text: `Wow those lines look clean! Let's go @yaypegs 🖤` },
    { text: `so f*$#! cool @yaypegs 🛸` },
    { text: `hype hype hype! 🧠` },
    { text: `Bullish AF @yaypegs` },
    { text: `I’m definitely bullish... ty @yaypegs ☄️` },
    { text: `loving @yaypegs!` },
    { text: `.@yaypegs keep building! 🏗️` },
    { text: `Stoked 💣` },
    { text: `Look what I've got! I'm super stoked 😎 NFT by @yaypegs` },
    { text: `.@beeple ...@yaypegs are coming! ⚡️` },
    {
      text: `.@yaypegs still building and I’m happy to see this mfer get transform!`,
    },
    {
      text: `Loved @yaypegs, they keep building and the new version is amazing. I think I should buy another`,
    },
    {
      text: `Have you seen @yaypegs latest PFP update? Pretty rad to seem building in the bear. 🫡`,
    },
    { text: `GM... @yaypegs keep building 🫡` },
    { text: `That @yaypegs update is 🔥🔥🔥` },
    { text: `Bullish on @yaypegs` },
    { text: `Woah when did this happen?! @yaypegs 🔥` },
    { text: `big shotout to @yaypegs who still building everyday!` },
    {
      text: `Just picked up my first @yaypegs. The magic machine is an awesome feature :D`,
    },
    { text: `Stage 3 is rumored to be huge.` },
    { text: `.@yaypegs comeback? 🤔` },
    { text: `.@yaypegs are making a comeback? 🚀🔥 and stage 3 is cooking!` },
    { text: `That @yaypegs STAGE 2 is insane ❤️` },
    { text: `So happy to be part of @yaypegs family 💙` },
    { text: `🌋💣🧯🚒🔥` },
  ];

  var nft_number = [
    4633, 4875, 4903, 5872, 3218, 3618, 6651, 4789, 9264, 4575, 6202, 8365, 903,
    6513, 6161, 1596, 5574, 509, 5090, 2299, 9025, 2142, 8588, 1486, 3754, 8436,
    3417, 7186, 6460, 1369, 1695, 1391, 5343, 7075, 9668, 2338, 1245, 1683,
    1643, 4575, 3844,
  ];

  const [random, setRandom] = useState(
    Math.floor(Math.random() * tweet_text.length)
  );

  const min = 1;
  const max = 10000;
  const bucket = `pixel`;
  const bucket_II = `illustration`;

  const [NFTnumber, setNFTnumber] = useState(
    nft_number[Math.floor(Math.random() * nft_number.length)]
  );
  const [firebase, setFirebase] = useState("");
  const [firebaseII, setFirebaseII] = useState("");
  const [isOnWTF, setIsOnWTF] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);

  const onChangeHandler = (event) => {
    setIsLoaded(false);
    setTimeout(() => {
      setIsLoaded(true);
    }, 3000);
    const limit = 5;
    const value = Math.max(
      min,
      Math.min(max, Number(event.target.value.slice(0, limit)))
    );
    setNFTnumber(value);
  };

  const handleFocus = (event) => {
    event.target.select();
  };

  useEffect(() => {
    window.scrollTo(0, 0, "instant");
  }, []);

  useEffect(() => {
    async function getImagefromFirebase() {
      if (NFTnumber !== 0) {
        try {
          const response = await fetch(
            `https://firebasestorage.googleapis.com/v0/b/yaypegsnft.appspot.com/o/${bucket}%2F${NFTnumber}.png`
          );
          const responseJson = await response.json();
          setFirebase(responseJson);
        } catch (error) {
          console.error(error);
        }
      }
    }
    getImagefromFirebase();
    return () => {
      getImagefromFirebase = null;
    };
  }, [NFTnumber, bucket]);

  useEffect(() => {
    async function getImagefromFirebaseII() {
      if (NFTnumber !== 0) {
        try {
          const response = await fetch(
            `https://firebasestorage.googleapis.com/v0/b/yaypegsnft.appspot.com/o/${bucket_II}%2F${NFTnumber}.png`
          );
          const responseJson = await response.json();
          setFirebaseII(responseJson);
        } catch (error) {
          console.error(error);
        }
      }
    }
    getImagefromFirebaseII();
    return () => {
      getImagefromFirebaseII = null;
    };
  }, [NFTnumber, bucket_II]);

  function downloadImages() {
    if (NFTnumber !== 0) {
      const xhr = new XMLHttpRequest();
      xhr.open(
        "GET",
        `https://firebasestorage.googleapis.com/v0/b/yaypegsnft.appspot.com/o/${bucket}%2F${NFTnumber}.png?alt=media&firebase=${firebase.downloadTokens}`,
        true
      );
      xhr.responseType = "blob";

      xhr.onload = () => {
        if (xhr.status === 200) {
          const blob = new Blob([xhr.response], { type: "image/png" });
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${NFTnumber}.png`);
          document.body.appendChild(link);
          link.click();
        } else {
          console.error("Error downloading PNG:", xhr.statusText);
        }
      };
      xhr.onerror = () => {
        console.error("Error downloading PNG:", xhr.statusText);
      };
      xhr.send();

      const xhrII = new XMLHttpRequest();
      xhrII.open(
        "GET",
        `https://firebasestorage.googleapis.com/v0/b/yaypegsnft.appspot.com/o/${bucket_II}%2F${NFTnumber}.png?alt=media&firebase=${firebase.downloadTokens}`,
        true
      );
      xhrII.responseType = "blob";

      xhrII.onload = () => {
        if (xhrII.status === 200) {
          const blob = new Blob([xhrII.response], { type: "image/png" });
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${NFTnumber}(II).png`);
          document.body.appendChild(link);
          link.click();
        } else {
          console.error("Error downloading PNG sII:", xhrII.statusText);
        }
      };
      xhrII.onerror = () => {
        console.error("Error downloading PNG sII:", xhrII.statusText);
      };
      xhrII.send();
    }
  }

  return (
    <div className="container-fluid pb-tabbar bg-white">
      <Header />
      <div className="row pt-0 m-0">
        <div className="col-12 p-0">
          <div className="loader-conteiner">
            <div className="loader"></div>
          </div>

          <div>
            <div className="d-lg-none stage2-title px-3 pt-4">
              <img
                src={B1}
                style={{ maxWidth: "200px" }}
                alt="growth hacking machine"
              />
              <hr />
            </div>
            <div className="parent-center mobile-parent-center">
              <div className="child-center-tweet">
                {isOnWTF ? (
                  <div className="d-none d-lg-block">
                    <div className="info-s1">
                      <img
                        src={W1}
                        className="info-s1-img"
                        alt="growth hacking machine"
                      />
                      <img src={W2} className="info-s1-img" alt="steps" />
                    </div>
                    <img src={W3} className="info-s2" alt="step 3" />
                  </div>
                ) : (
                  <div></div>
                )}
                <div className="twitter-box">
                  <div className="d-flex justify-content-between">
                    <div className="">
                      <img
                        src={TwitterLogo}
                        className="twitter-logo pb-1"
                        alt="twitter-logo"
                      />

                      <div className="tweet inline-block p-2 px-3">
                        <div className="tweet-number">#</div>
                        <input
                          type="number"
                          name="number"
                          placeholder="0000"
                          maxLength={5}
                          onChange={onChangeHandler}
                          onFocus={handleFocus}
                          value={NFTnumber}
                          min="1"
                          max="10000"
                        />
                      </div>
                    </div>
                    <div className="d-none d-lg-block">
                      <img
                        src={Info}
                        onClick={() => setIsOnWTF(!isOnWTF)}
                        className="tweet-icon pt-1"
                        alt="info"
                      />
                    </div>
                  </div>
                  <div>
                    <img
                      src={Refresh}
                      onClick={() =>
                        setRandom(Math.floor(Math.random() * tweet_text.length))
                      }
                      className="tweet-icon pb-1"
                      alt="refresh"
                    />
                    <div className="p-2 px-3 tweet-text break-word ">
                      {tweet_text[random].text}
                    </div>
                  </div>

                  <div className="row pt-2 pb-1">
                    <div className="col m-1 p-0">
                      {!isLoaded && (
                        <div className="tweet-img-loading">
                          <img
                            src={LoaderImg}
                            className="loader-img"
                            alt="step 1"
                          />
                        </div>
                      )}
                      <img
                        src={`https://firebasestorage.googleapis.com/v0/b/yaypegsnft.appspot.com/o/${bucket}%2F${NFTnumber}.png?alt=media&firebase=${firebase.downloadTokens}`}
                        className="tweet-img"
                        onLoad={() => setIsLoaded(true)}
                        style={{ display: isLoaded ? "block" : "none" }}
                        alt="img"
                      />
                    </div>
                    <div className="col m-1 p-0">
                      {!isLoaded && (
                        <div className="tweet-img-loading">
                          <img
                            src={LoaderImg}
                            className="loader-img"
                            alt="step 1"
                          />
                        </div>
                      )}
                      <img
                        src={`https://firebasestorage.googleapis.com/v0/b/yaypegsnft.appspot.com/o/${bucket_II}%2F${NFTnumber}.png?alt=media&firebase=${firebaseII.downloadTokens}`}
                        className="tweet-img"
                        style={{ display: isLoaded ? "block" : "none" }}
                        onLoad={() => setIsLoaded(true)}
                        alt="img"
                      />
                      {!isLoaded && <div />}
                    </div>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between pt-1">
                    <img
                      src={Download}
                      onClick={() => downloadImages()}
                      className="tweet-icon pb-2"
                      alt="download"
                    />
                    <button className="twitter-button">
                      <a
                        className=""
                        href={`https://twitter.com/intent/tweet?text=${tweet_text[random].text}`}
                        data-size="large"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="tweet"
                      >
                        Tweet
                      </a>
                    </button>
                  </div>
                </div>
              </div>
              <div className="d-lg-none pb-5 px-3">
                <hr className="m-0" />

                <img
                  src={B3}
                  className="pt-3"
                  style={{ maxWidth: "300px" }}
                  alt="steps"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Stage2Page;
